export const AllRegions = [
  { name: '美国', code: 'US', name_TW: '美國', name_US: 'USA' },
  {
    name: '中国-香港',
    code: 'HK',
    name_TW: '中國-香港',
    name_US: 'China-Hongkong'
  },
  { name: '安道尔', code: 'AD', name_TW: '安道爾', name_US: 'Andorra' },
  {
    name: '阿联酋',
    code: 'AE',
    name_TW: '阿聯酋',
    name_US: 'United Arab Emirates'
  },
  { name: '阿富汗', code: 'AF', name_TW: '阿富汗', name_US: 'Afghanistan' },
  {
    name: '安提瓜和巴布达',
    code: 'AG',
    name_TW: '安提瓜和巴布達',
    name_US: 'Antigua and Barbuda'
  },
  { name: '阿尔巴尼亚', code: 'AL', name_TW: '阿爾巴尼亞', name_US: 'Albania' },
  { name: '亚美尼亚', code: 'AM', name_TW: '亞美尼亞', name_US: 'Armenia' },
  { name: '安哥拉', code: 'AO', name_TW: '安哥拉', name_US: 'Angola' },
  { name: '阿根廷', code: 'AR', name_TW: '阿根廷', name_US: 'Argentina' },
  { name: '奥地利', code: 'AT', name_TW: '奧地利', name_US: 'Austria' },
  { name: '澳大利亚', code: 'AU', name_TW: '澳大利亞', name_US: 'Australia' },
  { name: '阿鲁巴', code: 'AW', name_TW: '阿魯巴', name_US: 'Aruba' },
  { name: '阿塞拜疆', code: 'AZ', name_TW: '阿塞拜疆', name_US: 'Azerbaijan' },
  {
    name: '波黑',
    code: 'BA',
    name_TW: '波黑',
    name_US: 'Bosnia and Herzegovina'
  },
  { name: '巴巴多斯', code: 'BB', name_TW: '巴巴多斯', name_US: 'Barbados' },
  { name: '孟加拉', code: 'BD', name_TW: '孟加拉', name_US: 'Bangal' },
  { name: '比利时', code: 'BE', name_TW: '比利時', name_US: 'Belgium' },
  {
    name: '布基纳法索',
    code: 'BF',
    name_TW: '布基納法索',
    name_US: 'Burkina Faso'
  },
  { name: '保加利亚', code: 'BG', name_TW: '保加利亞', name_US: 'Bulgaria' },
  { name: '巴林', code: 'BH', name_TW: '巴林', name_US: 'Bahrain' },
  { name: '布隆迪', code: 'BI', name_TW: '布隆迪', name_US: 'Burundi' },
  { name: '贝宁', code: 'BJ', name_TW: '貝甯', name_US: 'Benin' },
  { name: '文莱', code: 'BN', name_TW: '文萊', name_US: 'Brunei' },
  { name: '玻利维亚', code: 'BO', name_TW: '玻利維亞', name_US: 'Bolivia' },
  { name: '巴西', code: 'BR', name_TW: '巴西', name_US: 'Brazil' },
  { name: '巴哈马', code: 'BS', name_TW: '巴哈馬', name_US: 'Bahamas' },
  { name: '不丹', code: 'BT', name_TW: '不丹', name_US: 'Bhutan' },
  { name: '布韦岛', code: 'BV', name_TW: '布韋島', name_US: 'Bouvet Island' },
  { name: '博茨瓦纳', code: 'BW', name_TW: '博茨瓦納', name_US: 'Botswana' },
  { name: '白俄罗斯', code: 'BY', name_TW: '白俄羅斯', name_US: 'Belarus' },
  { name: '伯利兹', code: 'BZ', name_TW: '伯利茲', name_US: 'Belize' },
  { name: '加拿大', code: 'CA', name_TW: '加拿大', name_US: 'Canada' },
  { name: '中非', code: 'CF', name_TW: '中非', name_US: 'Central Africa' },
  { name: '瑞士', code: 'CH', name_TW: '瑞士', name_US: 'Switzerland' },
  { name: '智利', code: 'CL', name_TW: '智利', name_US: 'Chile' },
  { name: '喀麦隆', code: 'CM', name_TW: '喀麥隆', name_US: 'Cameroon' },
  { name: '哥伦比亚', code: 'CO', name_TW: '哥倫比亞', name_US: 'Colombia' },
  {
    name: '哥斯达黎加',
    code: 'CR',
    name_TW: '哥斯達黎加',
    name_US: 'Costa Rica'
  },
  { name: '古巴', code: 'CU', name_TW: '古巴', name_US: 'Cuba' },
  { name: '佛得角', code: 'CV', name_TW: '佛得角', name_US: 'Cape Verde' },
  { name: '塞浦路斯', code: 'CY', name_TW: '塞浦路斯', name_US: 'Cyprus' },
  { name: '捷克', code: 'CZ', name_TW: '捷克', name_US: 'CZECH REPUBLIC' },
  { name: '德国', code: 'DE', name_TW: '德國', name_US: 'Germany' },
  { name: '吉布提', code: 'DJ', name_TW: '吉布提', name_US: 'Djibouti' },
  { name: '丹麦', code: 'DK', name_TW: '丹麥', name_US: 'Denmark' },
  { name: '多米尼克', code: 'DM', name_TW: '多米尼克', name_US: 'Dominica' },
  { name: '多米尼加', code: 'DO', name_TW: '多米尼加', name_US: 'Dominican' },
  { name: '阿尔及利亚', code: 'DZ', name_TW: '阿爾及利亞', name_US: 'Algeria' },
  { name: '厄瓜多尔', code: 'EC', name_TW: '厄瓜多爾', name_US: 'Ecuador' },
  { name: '爱沙尼亚', code: 'EE', name_TW: '愛沙尼亞', name_US: 'Estonia' },
  { name: '埃及', code: 'EG', name_TW: '埃及', name_US: 'Egypt' },
  { name: '厄立特里亚', code: 'ER', name_TW: '厄立特裏亞', name_US: 'Eritrea' },
  { name: '西班牙', code: 'ES', name_TW: '西班牙', name_US: 'Spain' },
  { name: '芬兰', code: 'FI', name_TW: '芬蘭', name_US: 'Finland' },
  {
    name: '斐济群岛',
    code: 'FJ',
    name_TW: '斐濟群島',
    name_US: 'Fiji Islands'
  },
  {
    name: '密克罗尼西亚联邦',
    code: 'FM',
    name_TW: '密克羅尼西亞聯邦',
    name_US: 'Micronesia, Federated States of'
  },
  { name: '法国', code: 'FR', name_TW: '法國', name_US: 'France' },
  { name: '加蓬', code: 'GA', name_TW: '加蓬', name_US: 'Gabon' },
  { name: '格林纳达', code: 'GD', name_TW: '格林納達', name_US: 'Grenada' },
  { name: '格鲁吉亚', code: 'GE', name_TW: '格魯吉亞', name_US: 'Georgia' },
  { name: '加纳', code: 'GH', name_TW: '加納', name_US: 'Ghana' },
  { name: '几内亚', code: 'GN', name_TW: '幾內亞', name_US: 'Guinea' },
  {
    name: '赤道几内亚',
    code: 'GQ',
    name_TW: '赤道幾內亞',
    name_US: 'Equatorial Guinea'
  },
  { name: '希腊', code: 'GR', name_TW: '希臘', name_US: 'Greece' },
  { name: '危地马拉', code: 'GT', name_TW: '危地馬拉', name_US: 'Guatemala' },
  {
    name: '几内亚比绍',
    code: 'GW',
    name_TW: '幾內亞比紹',
    name_US: 'Guinea-Bissau'
  },
  { name: '圭亚那', code: 'GY', name_TW: '圭亞那', name_US: 'Guyana' },
  { name: '洪都拉斯', code: 'HN', name_TW: '洪都拉斯', name_US: 'Honduras' },
  { name: '克罗地亚', code: 'HR', name_TW: '克羅地亞', name_US: 'Croatia' },
  { name: '海地', code: 'HT', name_TW: '海地', name_US: 'Haiti' },
  { name: '匈牙利', code: 'HU', name_TW: '匈牙利', name_US: 'Hungary' },
  { name: '印尼', code: 'ID', name_TW: '印尼', name_US: 'Indonesia' },
  { name: '爱尔兰', code: 'IE', name_TW: '愛爾蘭', name_US: 'Ireland' },
  { name: '以色列', code: 'IL', name_TW: '以色列', name_US: 'Israel' },
  { name: '印度', code: 'IN', name_TW: '印度', name_US: 'India' },
  { name: '伊拉克', code: 'IQ', name_TW: '伊拉克', name_US: 'Iraq' },
  { name: '伊朗', code: 'IR', name_TW: '伊朗', name_US: 'Iran' },
  { name: '冰岛', code: 'IS', name_TW: '冰島', name_US: 'Iceland' },
  { name: '意大利', code: 'IT', name_TW: '意大利', name_US: 'Italy' },
  { name: '牙买加', code: 'JM', name_TW: '牙買加', name_US: 'Jamaica' },
  { name: '约旦', code: 'JO', name_TW: '約旦', name_US: 'Jordan' },
  { name: '日本', code: 'JP', name_TW: '日本', name_US: 'Japan' },
  { name: '柬埔寨', code: 'KH', name_TW: '柬埔寨', name_US: 'Cambodia' },
  { name: '基里巴斯', code: 'KI', name_TW: '基裏巴斯', name_US: 'Kiribati' },
  { name: '科摩罗', code: 'KM', name_TW: '科摩羅', name_US: 'Comoros' },
  { name: '科威特', code: 'KW', name_TW: '科威特', name_US: 'Kuwait' },
  { name: '黎巴嫩', code: 'LB', name_TW: '黎巴嫩', name_US: 'Lebanon' },
  {
    name: '列支敦士登',
    code: 'LI',
    name_TW: '列支敦士登',
    name_US: 'Liechtenstein'
  },
  { name: '斯里兰卡', code: 'LK', name_TW: '斯里蘭卡', name_US: 'Sri Lanka' },
  { name: '利比里亚', code: 'LR', name_TW: '利比裏亞', name_US: 'Liberia' },
  { name: '莱索托', code: 'LS', name_TW: '萊索托', name_US: 'Lesotho' },
  { name: '立陶宛', code: 'LT', name_TW: '立陶宛', name_US: 'Lithuania' },
  { name: '卢森堡', code: 'LU', name_TW: '盧森堡', name_US: 'Luxembourg' },
  { name: '拉脱维亚', code: 'LV', name_TW: '拉脫維亞', name_US: 'Latvia' },
  { name: '利比亚', code: 'LY', name_TW: '利比亞', name_US: 'Libya' },
  { name: '摩洛哥', code: 'MA', name_TW: '摩洛哥', name_US: 'Morocco' },
  { name: '摩纳哥', code: 'MC', name_TW: '摩納哥', name_US: 'Monaco' },
  { name: '摩尔多瓦', code: 'MD', name_TW: '摩爾多瓦', name_US: 'Moldova' },
  { name: '黑山', code: 'ME', name_TW: '黑山', name_US: 'Montenegro' },
  {
    name: '马达加斯加',
    code: 'MG',
    name_TW: '馬達加斯加',
    name_US: 'Madagascar'
  },
  {
    name: '马绍尔群岛',
    code: 'MH',
    name_TW: '馬紹爾群島',
    name_US: 'Marshall Islands'
  },
  { name: '马其顿', code: 'MK', name_TW: '馬其頓', name_US: 'Macedonia' },
  { name: '马里', code: 'ML', name_TW: '馬利', name_US: 'Mali' },
  { name: '缅甸', code: 'MM', name_TW: '緬甸', name_US: 'Myanmar' },
  {
    name: '毛里塔尼亚',
    code: 'MR',
    name_TW: '毛裏塔尼亞',
    name_US: 'Mauritania'
  },
  { name: '马耳他', code: 'MT', name_TW: '馬耳他', name_US: 'Malta' },
  { name: '马尔代夫', code: 'MV', name_TW: '馬爾代夫', name_US: 'Maldives' },
  { name: '马拉维', code: 'MW', name_TW: '馬拉維', name_US: 'Malawi' },
  { name: '墨西哥', code: 'MX', name_TW: '墨西哥', name_US: 'Mexico' },
  { name: '马来西亚', code: 'MY', name_TW: '馬來西亞', name_US: 'Malaysia' },
  { name: '纳米比亚', code: 'NA', name_TW: '納米比亞', name_US: 'Namibia' },
  { name: '尼日尔', code: 'NE', name_TW: '尼日爾', name_US: 'Niger' },
  { name: '尼日利亚', code: 'NG', name_TW: '尼日利亞', name_US: 'Nigeria' },
  { name: '尼加拉瓜', code: 'NI', name_TW: '尼加拉瓜', name_US: 'Nicaragua' },
  { name: '荷兰', code: 'NL', name_TW: '荷蘭', name_US: 'Netherlands' },
  { name: '挪威', code: 'NO', name_TW: '挪威', name_US: 'Norway' },
  { name: '尼泊尔', code: 'NP', name_TW: '尼泊爾', name_US: 'Nepal' },
  { name: '瑙鲁', code: 'NR', name_TW: '瑙魯', name_US: 'Nauru' },
  { name: '阿曼', code: 'OM', name_TW: '阿曼', name_US: 'Oman' },
  { name: '巴拿马', code: 'PA', name_TW: '巴拿馬', name_US: 'Panama' },
  { name: '秘鲁', code: 'PE', name_TW: '祕魯', name_US: 'Peru' },
  {
    name: '巴布亚新几内亚',
    code: 'PG',
    name_TW: '巴布亞新幾內亞',
    name_US: 'Papua New Guinea'
  },
  { name: '菲律宾', code: 'PH', name_TW: '菲律賓', name_US: 'Philippines' },
  { name: '巴基斯坦', code: 'PK', name_TW: '巴基斯坦', name_US: 'Pakistan' },
  { name: '波兰', code: 'PL', name_TW: '波蘭', name_US: 'Poland' },
  { name: '巴勒斯坦', code: 'PS', name_TW: '巴勒斯坦', name_US: 'Palestine' },
  { name: '帕劳', code: 'PW', name_TW: '帕勞', name_US: 'Palau' },
  { name: '巴拉圭', code: 'PY', name_TW: '巴拉圭', name_US: 'Paraguay' },
  { name: '卡塔尔', code: 'QA', name_TW: '卡塔爾', name_US: 'Qatar' },
  { name: '罗马尼亚', code: 'RO', name_TW: '羅馬尼亞', name_US: 'Romania' },
  { name: '塞尔维亚', code: 'RS', name_TW: '塞爾維亞', name_US: 'Serbia' },
  { name: '俄罗斯', code: 'RU', name_TW: '俄羅斯', name_US: 'Russia' },
  { name: '卢旺达', code: 'RW', name_TW: '盧旺達', name_US: 'Rwanda' },
  {
    name: '所罗门群岛',
    code: 'SB',
    name_TW: '所羅門群島',
    name_US: 'Solomon Islands'
  },
  { name: '塞舌尔', code: 'SC', name_TW: '塞舌爾', name_US: 'Seychelles' },
  { name: '苏丹', code: 'SD', name_TW: '蘇丹', name_US: 'Sudan' },
  { name: '瑞典', code: 'SE', name_TW: '瑞典', name_US: 'Sweden' },
  { name: '新加坡', code: 'SG', name_TW: '新加坡', name_US: 'Singapore' },
  {
    name: '斯洛文尼亚',
    code: 'SI',
    name_TW: '斯洛文尼亞',
    name_US: 'Slovenia'
  },
  { name: '斯洛伐克', code: 'SK', name_TW: '斯洛伐克', name_US: 'Slovakia' },
  {
    name: '塞拉利昂',
    code: 'SL',
    name_TW: '塞拉利昂',
    name_US: 'Sierra Leone'
  },
  { name: '圣马力诺', code: 'SM', name_TW: '聖馬力諾', name_US: 'San Marino' },
  { name: '塞内加尔', code: 'SN', name_TW: '塞內加爾', name_US: 'Senegal' },
  { name: '索马里', code: 'SO', name_TW: '索馬裏', name_US: 'Somalia' },
  { name: '苏里南', code: 'SR', name_TW: '蘇裏南', name_US: 'Suriname' },
  { name: '南苏丹', code: 'SS', name_TW: '南蘇丹', name_US: 'South Sudan' },
  {
    name: '圣多美和普林西比',
    code: 'ST',
    name_TW: '聖多美和普林西比',
    name_US: 'Sao Tome and Principe'
  },
  { name: '萨尔瓦多', code: 'SV', name_TW: '薩爾瓦多', name_US: 'El Salvador' },
  { name: '叙利亚', code: 'SY', name_TW: '敘利亞', name_US: 'Syria' },
  { name: '斯威士兰', code: 'SZ', name_TW: '斯威士蘭', name_US: 'Swaziland' },
  { name: '乍得', code: 'TD', name_TW: '乍得', name_US: 'Chad' },
  { name: '多哥', code: 'TG', name_TW: '多哥', name_US: 'Togo' },
  { name: '泰国', code: 'TH', name_TW: '泰國', name_US: 'Thailand' },
  { name: '东帝汶', code: 'TL', name_TW: '東帝汶', name_US: 'Timor-Leste' },
  { name: '突尼斯', code: 'TN', name_TW: '突尼斯', name_US: 'Tunisia' },
  { name: '汤加', code: 'TO', name_TW: '湯加', name_US: 'Tonga' },
  { name: '土耳其', code: 'TR', name_TW: '土耳其', name_US: 'Turkey' },
  { name: '图瓦卢', code: 'TV', name_TW: '圖瓦盧', name_US: 'Tuvalu' },
  { name: '坦桑尼亚', code: 'TZ', name_TW: '坦桑尼亞', name_US: 'Tanzania' },
  { name: '乌克兰', code: 'UA', name_TW: '烏克蘭', name_US: 'Ukraine' },
  { name: '乌干达', code: 'UG', name_TW: '烏幹達', name_US: 'Uganda' },
  { name: '乌拉圭', code: 'UY', name_TW: '烏拉圭', name_US: 'Uruguay' },
  { name: '梵蒂冈', code: 'VA', name_TW: '梵蒂岡', name_US: 'Vatican' },
  {
    name: '委内瑞拉',
    code: 'VE',
    name_TW: '委內瑞拉',
    name_US: 'Venezuela (Bolivarian Republic of)'
  },
  {
    name: '英属维尔京群岛',
    code: 'VG',
    name_TW: '英屬維爾京群島',
    name_US: 'Virgin Islands (British)'
  },
  { name: '越南', code: 'VN', name_TW: '越南', name_US: 'Vietnam' },
  { name: '萨摩亚', code: 'WS', name_TW: '薩摩亞', name_US: 'Samoa' },
  { name: '也门', code: 'YE', name_TW: '也門', name_US: 'Yemen' },
  { name: '南非', code: 'ZA', name_TW: '南非', name_US: 'South Africa' },
  { name: '赞比亚', code: 'ZM', name_TW: '贊比亞', name_US: 'Zambia' },
  { name: '津巴布韦', code: 'ZW', name_TW: '津巴布韋', name_US: 'Zimbabwe' },
  {
    name: '中国',
    code: 'CN',
    name_TW: '中國',
    name_US: 'China'
  },
  {
    name: '刚果（布）',
    code: 'CG',
    name_TW: '剛果（布）',
    name_US: 'Congo (Brazzaville)'
  },
  {
    name: '刚果（金）',
    code: 'CD',
    name_TW: '剛果（金）',
    name_US: 'Congo (Kinshasa)'
  },
  { name: '莫桑比克', code: 'MZ', name_TW: '莫桑比克', name_US: 'Mozambique' },
  { name: '冈比亚', code: 'GM', name_TW: '岡比亞', name_US: 'Gambia' },
  {
    name: '埃塞俄比亚',
    code: 'ET',
    name_TW: '埃塞俄比亞',
    name_US: 'Ethiopia'
  },
  { name: '瓦努阿图', code: 'VU', name_TW: '瓦努阿圖', name_US: 'Vanuatu' },
  { name: '英国', code: 'GB', name_TW: '英國', name_US: 'England' },
  {
    name: '特立尼达和多巴哥',
    code: 'TT',
    name_TW: '特立尼達和多巴哥',
    name_US: 'Trinidad and Tobago'
  },
  {
    name: '圣文森特和格林纳丁斯',
    code: 'VC',
    name_TW: '聖文森特和格林納丁斯',
    name_US: 'St. Vincent and the Grenadines'
  },
  {
    name: '中国-台湾',
    code: 'TW',
    name_TW: '中國-臺灣',
    name_US: 'China-Taiwan'
  },
  { name: '新西兰', code: 'NZ', name_TW: '新西蘭', name_US: 'New Zealand' },
  {
    name: '沙特阿拉伯',
    code: 'SA',
    name_TW: '沙特阿拉伯',
    name_US: 'Saudi Arabia'
  },
  { name: '老挝', code: 'LA', name_TW: '老撾', name_US: 'Laos' },
  { name: '朝鲜', code: 'KP', name_TW: '朝鮮', name_US: 'North Korea' },
  { name: '韩国', code: 'KR', name_TW: '韓國', name_US: 'Korea' },
  { name: '葡萄牙', code: 'PT', name_TW: '葡萄牙', name_US: 'Portugal' },
  {
    name: '吉尔吉斯斯坦',
    code: 'KG',
    name_TW: '吉爾吉斯斯坦',
    name_US: 'Kyrgyzstan'
  },
  {
    name: '哈萨克斯坦',
    code: 'KZ',
    name_TW: '哈薩克斯坦',
    name_US: 'Kazakhstan'
  },
  {
    name: '塔吉克斯坦',
    code: 'TJ',
    name_TW: '塔吉克斯坦',
    name_US: 'Tajikistan'
  },
  {
    name: '土库曼斯坦',
    code: 'TM',
    name_TW: '土庫曼斯坦',
    name_US: 'Turkmenistan'
  },
  {
    name: '乌兹别克斯坦',
    code: 'UZ',
    name_TW: '烏茲別克斯坦',
    name_US: 'Uzbekistan'
  },
  { name: '圣卢西亚', code: 'LC', name_TW: '聖盧西亞', name_US: 'St. Lucia' },
  { name: '毛里求斯', code: 'MU', name_TW: '毛裏求斯', name_US: 'Mauritius' },
  {
    name: '科特迪瓦',
    code: 'CI',
    name_TW: '科特迪瓦',
    name_US: `Cote d'Ivoire`
  },
  { name: '肯尼亚', code: 'KE', name_TW: '肯尼亞', name_US: 'Kenya' },
  { name: '蒙古', code: 'MN', name_TW: '蒙古', name_US: 'Mongolia' },
  { name: '中国-澳门', code: 'MO', name_TW: '中國-澳門', name_US: 'China-Macao' }
]
