import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, Input, Statistic, Checkbox, message, Spin } from "antd";
import { Container, MainWrap, FormWrap } from "./style";
import { connect } from "react-redux";
import { sendVerifyCode } from "@/api/request";
import { userRegister, resetToken } from "@/store/modules/user/actionCreators";
import bgPic from "@/assets/png/login/bg.png";
import logoPic from "@/assets/png/login/logo.png";
import bgMPic from "@/assets/png/login/bg_m.png";
import { ReactComponent as UserIcon } from "@/assets/svg/login/user.svg";
import { ReactComponent as CodeIcon } from "@/assets/svg/login/code.svg";
import { ReactComponent as PasswordIcon } from "@/assets/svg/login/password.svg";
import { ReactComponent as InviteIcon } from "@/assets/svg/login/invite.svg";
import ReactSimpleVerify from "@/components/ReactSimpleVerify";
import { gotoNewWindowPath } from "@/utils/index";
import { useParams } from 'react-router-dom';

const { Countdown } = Statistic;
const countTime = 60 * 1000; // 倒计时60s

function Register(props) {
    const { history } = props;
    const { invitationCode } = useParams();

    const { device, userRegisterDispatch, resetTokenDispatch } = props;

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        resetTokenDispatch();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (form) {
            form.setFieldsValue({ invitationCode: invitationCode });
        }
    }, [invitationCode, form])

    /* 安全验证 -- start */
    const verifyRef = useRef(null);
    const [successVerify, setSuccessVerify] = useState(false);
    const handleVerifySuccess = useCallback(() => {
        setSuccessVerify(true);
    }, [])
    /* 安全验证 -- end */

    /* 短信验证码 -- start */
    const [phoneCodePending, setPhoneCodePending] = useState(true); // true--可获取验证码 false--倒计时中
    const [phoneCodeLoading, setPhoneCodeLoading] = useState(false);
    const refreshPhoneCode = () => {
        const phone = form.getFieldValue("phone");
        if (!phone) {
            message.destroy();
            message.warning("请输入您的手机号码");
            return;
        }
        if (device !== "mobile" && !successVerify) {
            message.destroy();
            message.warning("请完成安全验证");
            return;
        }
        setPhoneCodeLoading(true);
        const formData = new FormData();
        formData.append("phone", phone);
        sendVerifyCode(formData).then(response => {
            setPhoneCodeLoading(false);
            if (response.code === 200) {
                message.success("发送成功");
                setPhoneCodePending(false);
            }
        }).catch(() => {
            setPhoneCodeLoading(false);
        })
    }
    const onFinishCountdown = () => {
        setPhoneCodePending(true);
    }
    /* 短信验证码 -- end */

    const onFinish = (values) => {
        setLoading(true);
        const { phone, verifyCode, password, invitationCode } = values;
        userRegisterDispatch({ phone, verifyCode, password, invitationCode }).then(response => {
            setLoading(false);
            message.success("注册成功");
            history.push("/login");
        }).catch(() => {
            setLoading(false);
            reset();
        })
    }

    const reset = () => {
        setPhoneCodePending(true);
        setPhoneCodeLoading(false);
        // // 重置验证
        // if (verifyRef) {
        //     verifyRef.current.reset();
        //     setSuccessVerify(false);
        // }
    }

    return (
        <Container bg={device !== "mobile" ? bgPic : bgMPic}>
            { device !== "mobile" ? <img className="logo" src={logoPic} alt="logo" /> : null }
            <MainWrap>
                <div className="register-title">欢迎注册星鹿</div>
                <Spin spinning={loading}>
                    <FormWrap>
                        <Form
                            form={form}
                            className="register-form"
                            initialValues={{
                                phone: "",
                                verifyCode: "",
                                password: "",
                                invitationCode: "",
                                agreement: false
                            }}
                            onFinish={onFinish}
                            layout="vertical"
                            size="large"
                        >
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入11位手机号码",
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value || (/^1[3-9]\d{9}$/.test(value))) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("请输入11位手机号码"));
                                        },
                                    }),
                                ]}
                            >
                                <Input prefix={<UserIcon className="site-form-item-icon" />} autoComplete="off" placeholder="请输入您的手机号码" />
                            </Form.Item>

                            {
                                device !== "mobile" ? <div className="form-verify-box">
                                    <ReactSimpleVerify ref={verifyRef} success={handleVerifySuccess} />
                                </div> : null
                            }

                            <Form.Item className="form-captcha-item">
                                <div className="form-captcha-wrap">
                                    <Form.Item
                                        name="verifyCode"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入短信验证码",
                                            },
                                            () => ({
                                                validator(_, value) {
                                                    if (!value || (/^\d{6}$/.test(value))) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error("请输入短信验证码"));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input prefix={<CodeIcon className="site-form-item-icon" />} autoComplete="off" placeholder="请输入短信验证码" />
                                    </Form.Item>
                                    {
                                        phoneCodePending ?
                                        <Button type="link" className="phone-code-btn" loading={phoneCodeLoading} onClick={refreshPhoneCode}>获取验证码</Button>
                                        : <div className="phone-count-down">
                                            <Countdown onFinish={onFinishCountdown} value={Date.now() + countTime} format="ss" />
                                            s
                                        </div>
                                    }
                                </div>
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入密码",
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value || (/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{6,16}$/.test(value))) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("长度需要6-16之间，仅由大小写英文字母及数字组合"));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password prefix={<PasswordIcon className="site-form-item-icon" />} autoComplete="new-password" placeholder="请输入密码" />
                            </Form.Item>

                            <Form.Item name="invitationCode">
                                <Input
                                    prefix={<InviteIcon className="site-form-item-icon" />}
                                    autoComplete="off"
                                    placeholder="请输入您的邀请码（非必填）"
                                    disabled={!!invitationCode}
                                />
                            </Form.Item>

                            <Form.Item
                                name="agreement"
                                valuePropName="checked"
                                className="register-agreement"
                                rules={[
                                    {
                                        validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error("请先阅读并同意相关服务条款和隐私政策")),
                                    },
                                ]}
                            >
                                <Checkbox>
                                    我已阅读并同意相关
                                    <Button type="link" onClick={() => gotoNewWindowPath("/termsOfService")}>服务条款</Button>
                                    和
                                    <Button type="link" onClick={() => gotoNewWindowPath("/privacy")}>隐私政策</Button>
                                </Checkbox>
                            </Form.Item>

                            <Form.Item className="form-submit-button">
                                <Button type="primary" htmlType="submit">注册</Button>
                            </Form.Item>
                        </Form>
                    </FormWrap>
                </Spin>
                <div className="register-bottom">
                    <span>已有账号？</span>
                    <Button type="link" onClick={() => history.push("/login")}>立即登录</Button>
                </div>
            </MainWrap>
        </Container>
    )
};
// 映射 Redux 全局的 state 到组件的 props 上
const mapStateToProps = (state) => ({
    device: state.getIn(["app", "device"]),
});

// 映射 dispatch 到 props 上
const mapDispatchToProps = (dispatch) => {
    return {
        userRegisterDispatch: (data) => dispatch(userRegister(data)),
        resetTokenDispatch: (data) => dispatch(resetToken(data)),
    }
};

// 将 ui 组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Register));
