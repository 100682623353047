import React from "react";
import { Result, Button } from "antd";
import styled from "styled-components";

const Container = styled.div`
    margin: 0 auto;
`

function App(props) {
    const { history } = props;
    return <Container>
        <Result
            status="404"
            title="404"
            subTitle="抱歉，页面没找到！"
            extra={<Button type="primary" onClick={() => (history.push("/index"))}>返回首页</Button>}
        />
    </Container>
}

export default React.memo(App);