import request from "./config";

// 发送手机验证码
export function sendVerifyCode(data) {
    return request({
        url: "/front/enterprise/sendVerifyCode",
        method: "post",
        data
    })
}
// 注册
export function register(data) {
    return request({
        url: "/front/enterprise/register",
        method: "post",
        data
    })
}
// 登录
export function login(data) {
    return request({
        url: "/front/enterprise/login",
        method: "post",
        data
    })
}
// 重置密码
export function resetPassword(data) {
    return request({
        url: "/front/enterprise/resetPassword",
        method: "post",
        data
    })
}
// 获取用户信息
export function getUserInfo(params) {
    return request({
        url: "/front/enterprise/userInfo",
        method: "get",
        params
    })
}
// 更新用户信息:用户名、密码、邮箱
export function updateEnterpriseInfo(data) {
    return request({
        url: "/front/enterprise/saveEnterpriseInfo",
        method: "post",
        data
    })
}
// 登出
export function logout(data) {
    return request({
        url: "/front/enterprise/logout",
        method: "post",
        data
    })
}
// 获取用户收货地址
export function getUserAddress(params) {
    return request({
        url: "/front/enterprise/userAddress",
        method: "get",
        params
    })
}
// 添加用户收货地址
export function createUserAddress(data) {
    return request({
        url: "/front/enterprise/createEnterpriseAddress",
        method: "post",
        data
    })
}
// 修改用户收货地址
export function updateUserAddress(data) {
    return request({
        url: "/front/enterprise/updateEnterpriseAddress",
        method: "post",
        data
    })
}
// 删除用户收货地址
export function deleteUserAddress(params) {
    return request({
        url: "/front/enterprise/deleteEnterpriseAddress",
        method: "get",
        params
    })
}
// 余额充值
export function rechargeBalance(data) {
    return request({
        url: "/front/balance/recharge",
        method: "post",
        data
    })
}
// 获取用户余额
export function getBalance(params) {
    return request({
        url: "/front/balance/getBalance",
        method: "get",
        params
    })
}
// 查询用户余额明细
export function getBalanceDetail(params) {
    return request({
        url: "/front/balance/getBalanceDetail",
        method: "get",
        params
    })
}
// 获取支付列表
export function getPaymentList(params) {
    return request({
        url: "/front/payment/method_list",
        method: "get",
        params
    })
}
// 检查支付订单状态
// export function checkPayStatus(params) {
//     return request({
//         url: "/front/sdwan/queryOrder",
//         method: "get",
//         params
//     })
// }
export function checkPayStatus(orderNo) {
    return request({
        url: `/front/payment/check/${orderNo}`,
        method: "get"
    })
}
// 新闻公告
export function getNewsList(params) {
    return request({
        url: "/front/news/newsList",
        method: "get",
        params
    })
}
