import useCreation from "./useCreation";
import useLatest from "./useLatest";
import useUnmount from './useUnmount';
import debounce from "lodash/debounce";

/**
 * options:
 * leading 是否延迟开始前调用的函数
 * trailing 是否在延迟开始后调用函数
 * maxWait 最大等待时间
 */

const useDebounceFn = (fn, wait, options) => {
    const fnRef = useLatest(fn);

    const debounced = useCreation(() => {
        return debounce(
            (...args) => fnRef.current(...args),
            wait,
            options
        )
    }, [])

    useUnmount(() => {
        debounced.cancel();
    })

    return debounced;
}

export default useDebounceFn;