import { useRef } from "react";

function depsAreSame(oldDeps, deps) {
    if (oldDeps === deps) return true;
    for (let i = 0; i < oldDeps.length; i++) {
        if (!Object.is(oldDeps[i], deps[i])) {
            return false;
        }
    }
    return true;
}

const useCreation = (fn, deps) => {
    const { current } = useRef({
        deps,
        obj: undefined,
        initialized: false
    })
    if (current.initialized === false || !depsAreSame(current.deps, deps)) {
        current.deps = deps;
        current.obj = fn(); // 获取最新的值
        current.initialized = true;
    }
    return current.obj;
}

export default useCreation;