import request from "./config";

// 时间段
export function getDurationList(params) {
    return request({
        url: "/front/static_ip/meta/duration",
        method: "get",
        params
    })
}
// 地区/价格
export function getRegionAndPriceList(params) {
    return request({
        url: "/front/static_ip/meta/region",
        method: "get",
        params
    })
}
// 地区/价格 - all
export function getAllRegionAndPriceList(params) {
    return request({
        url: "/front/static_ip/meta/allRegion",
        method: "get",
        params
    })
}
// 地区/价格 - 购买线路
export function getUnionRegionAndPriceList(params) {
    return request({
        url: "/front/static_ip/meta/unionRegion",
        method: "get",
        params
    })
}
// 购买明细
export function payDetail(data) {
    return request({
        url: "/front/static_ip/pay/buy_detail",
        method: "post",
        data
    })
}
// 购买
export function payBuy(data) {
    return request({
        url: "/front/static_ip/pay/buy",
        method: "post",
        data
    })
}
// IP列表
export function getStaticIPList(data) {
    return request({
        url: "/front/static_ip/list",
        method: "post",
        data
    })
}
// 绑定线路
export function staticIPBindSdwan(data) {
    return request({
        url: "/front/static_ip/bind",
        method: "post",
        data
    })
}
// 修改IP备注
export function updateStaticIPRemark(data) {
    return request({
        url: "/front/static_ip/remark",
        method: "post",
        data
    })
}
// 批量开启/关闭自动续费
export function updateStaticIPAutoRenewal(data) {
    return request({
        url: "/front/static_ip/auto_renew",
        method: "post",
        data
    })
}
// 续费明细
export function payRenewDetail(data) {
    return request({
        url: "/front/static_ip/pay/renew_detail",
        method: "post",
        data
    })
}
// 续费
export function payRenew(data) {
    return request({
        url: "/front/static_ip/pay/renewal",
        method: "post",
        data
    })
}
// 导出静态IP列表
export function exportStaticIPList(data) {
    return request({
        url: "/front/static_ip/export",
        method: "post",
        responseType: "blob",
        data
    })
}
// 导出静态IP列表-json
export function exportStaticIPList_json(data) {
    return request({
        url: "/front/static_ip/export_json",
        method: "post",
        responseType: "blob",
        data
    })
}
