import React, { useEffect, useRef, useState, useCallback } from "react";
import { Spin, Tabs, Radio, Input, InputNumber, Button, Drawer, Select, Tooltip, message } from "antd";
import { PlusOutlined, MinusOutlined, DoubleLeftOutlined, ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { Container, LeftWrap, RightWrap, RegionWrap, RegionSummaryWrap } from "./style";
import { DefaultPrice, BalancePayID, AliPayID, WechatPayID, CompanyPayID, StaticIPTypeList, NormalStaticIPProxies } from "@/utils/options";
import { PcAccessType, MobileAccessType } from "@/utils/options";
import { connect } from "react-redux";
import { getDurationList, getRegionAndPriceList,/* payDetail,*/ payBuy } from "@/api/staticIP";
import useDebounceFn from "@/hooks/useDebounceFn";
import PayTypes from "@/components/PayTypes";
import QrCodeModal from "@/components/QrCodeModal";
import CancelPayModal from "@/components/CancelPayModal";
import CheckPayModal from "@/components/CheckPayModal";
import { isWechatH5Pay, keep2decimals } from "@/utils/index";
import { getFlagImageSrc } from "@/flags";
import { ReactComponent as HotIcon } from "@/assets/svg/hot.svg";
import SimpleTipModal from "@/components/SimpleTipModal";
import axios from "axios";

const MaxNum = 50;
const AllCountry = "全部";

const NoAutoRenewItemID = 9999;

function PurchaseIP(props) {
    const { history } = props;

    const [items, setItems] = useState([]);
    const [current, setCurrent] = useState(null);
    const [staticIPType, setStaticIPType] = useState(NormalStaticIPProxies);
    const [regionCache, setRegionCache] = useState({});
    const [regions, setRegions] = useState([]);
    const [regionTabs, setRegionTabs] = useState([]);
    const [activeKey, setActiveKey] = useState(null);
    const [renewItemId, setRenewItemId] = useState(NoAutoRenewItemID);

    const [totalNum, setTotalNum] = useState(0);

    const [totalPrice, setTotalPrice] = useState(DefaultPrice);
    
    const [payType, setPayType] = useState(null);
    const payRef = useRef(null);
    const [qrCodeVisible, setQrCodeVisible] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [cancelPayVisible, setCancelPayVisible] = useState(false);
    const [cancelData, setCancelData] = useState(null);
    const [companyPayOpen, setCompanyPayOpen] = useState(false);
    const [warningTip, setWarningTip] = useState(null);
    const checkPayRef = useRef(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getItemList();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getRegionList();
        // eslint-disable-next-line
    }, [staticIPType])

    useEffect(() => {
        doOpenOrderDrawer();
        // eslint-disable-next-line
    }, [current])

    useEffect(() => {
        const targetElem = document.querySelector("#staticRegionsWrap");
        if (targetElem) {
            targetElem.scrollTo({
                top: 0,
                behavior: "auto"
            })
        }
    }, [activeKey])

    useEffect(() => {
        let total = regions.reduce((accumulator, currentValue) => {
            if (currentValue.quantity) {
                return accumulator + currentValue.quantity;
            } else {
                return accumulator;
            }
        }, 0);
        setTotalNum(total);
    }, [regions, activeKey])

    useEffect(() => {
        if (current && payType) {
            getOrderDetail();
        }
        // eslint-disable-next-line
    }, [regions, current, payType])

    useEffect(() => {
        if (items.length <= 0 || regions.length <= 0) {
            setWarningTip("缺少参数");
        } else {
            if (totalNum <= 0) {
                const sItems = getPayItems();
                if (sItems.length <= 0) {
                    setWarningTip("请至少选择一个IP");
                } else {
                    setWarningTip("");
                }
            } else {
                setWarningTip("");
            }
        }
        // eslint-disable-next-line
    }, [items, regions])

    const getItemList = () => {
        setLoading(true);
        getDurationList().then(response => {
            setLoading(false);
            if (response.code === 200) {
                const list = response.data || [];
                setItems(list);
                if (list.length > 0) {
                    setCurrent(list[0]);
                }
            }
        }).catch(() => {
            setLoading(false);
        })
    }

    const checkRegionList = (list) => {
        const newList = list.map(item => {
            return {
                ...item,
                quantity: 0
            }
        })
        setRegions(newList);
        const newObj = newList.reduce((obj, item) => {
            if (item.continent) {
                if (obj[item.continent]) {
                    obj[item.continent].push(item);
                } else {
                    obj[item.continent] = [item];
                }
            }
            return obj;
        }, {})
        const keys = Object.keys(newObj);
        const tabItems = keys.map(item => {
            return {
                label: item,
                key: item
            }
        })
        tabItems.unshift({
            label: AllCountry,
            key: AllCountry
        })
        setRegionTabs(tabItems);
        if (tabItems.length > 0) {
            setActiveKey(tabItems[0].key);
        }
    }

    const getRegionList = () => {
        setRegions([]);
        setRegionTabs([]);
        setActiveKey(null);
        if (!regionCache[staticIPType]) {
            getRegionAndPriceList({ nativeType: staticIPType }).then(response => {
                if (response.code === 200) {
                    const list = response.data || [];
                    checkRegionList(list);
                    setRegionCache(c => {
                        c[staticIPType] = [...list];
                        return {...c};
                    })
                }
            }).catch(() => {})
        } else {
            const listC = regionCache[staticIPType];
            checkRegionList(listC);
        }
    }

    const getRegionsPrice = (item) => {
        if (!current) {
            return "-";
        }
        return item.price[current.id] || "-";
    }

    const changeQty = (val, index) => {
        setRegions(regions => {
            if (regions[index]) {
                if (totalNum - regions[index].quantity + val > MaxNum) {
                    regions[index].quantity = MaxNum - (totalNum - regions[index].quantity);
                    message.destroy();
                    message.warning(`单次购买IP总数不能超过${MaxNum}`);
                } else {
                    regions[index].quantity = val;
                }
            }
            return [ ...regions ];
        })
    }

    const subQty = (index) => {
        if (regions[index] && !regions[index].quantity) {
            return;
        }
        setRegions(regions => {
            if (regions[index]) {
                regions[index].quantity--;
                if (regions[index].quantity < 0) {
                    regions[index].quantity = 0;
                }
            }
            return [ ...regions ];
        })
    }

    const addQty = (index) => {
        if (regions[index] && totalNum >= MaxNum) {
            message.destroy();
            message.warning(`单次购买IP总数不能超过${MaxNum}`);
            return;
        }
        setRegions(regions => {
            if (regions[index]) {
                if (totalNum < MaxNum) {
                    regions[index].quantity++;
                    if (regions[index].quantity > MaxNum) {
                        regions[index].quantity = MaxNum;
                    }
                } else {

                }
            }
            return [ ...regions ];
        })
    }

    const getPayItems = () => {
        const payItems = [];
        for (let i = 0; i < regions.length; i++) {
            if (regions[i].quantity > 0) {
                payItems.push({
                    regionId: regions[i].regionId,
                    ipNum: regions[i].quantity
                })
            }
        }
        return payItems;
    }

    const getPayPrice = () => {
        let tPrice = 0;
        for (let i = 0; i < regions.length; i++) {
            if (regions[i].quantity > 0 && regions[i].price[current.id]) {
                tPrice = keep2decimals(tPrice + regions[i].quantity * regions[i].price[current.id]);
            }
        }
        return tPrice;
    }

    const getOrderDetail = useDebounceFn(() => {
        setTotalPrice(getPayPrice());
        // const pItems = getPayItems()
        // if (pItems.length <= 0) {
        //     return;
        // }
        // const params = {
        //     paymentId: payType,
        //     durationId: current.id,
        //     renewDurationId: renewItemId !== NoAutoRenewItemID ? renewItemId : 0,
        //     items: pItems
        // }
        // payDetail(params).then(response => {
        //     if (response.code === 200 && response.data) {
        //         setTotalPrice(response.data.realAmount || 0);
        //     }
        // }).catch(() => {})
    }, 100)

    const gotoPay = async () => {
        const pItems = getPayItems()
        if (pItems.length <= 0) {
            message.destroy();
            message.warning("请至少选择一个IP");
            return;
        }
        setLoading(true);
        const accessType = isWechatH5Pay() ? MobileAccessType : PcAccessType;
        const params = {
            paymentId: payType,
            durationId: current.id,
            renewDurationId: renewItemId !== NoAutoRenewItemID ? renewItemId : 0,
            items: pItems,
            accessType: accessType
        }
        if (accessType === MobileAccessType && payType === WechatPayID) {
            let ipRes = await axios.get("https://ipinfo.io/ip");
            if (ipRes && ipRes.data) {
                params.wechatPayerClientIp = ipRes.data;
            } else {
                ipRes = await axios.get("https://api.ipify.org");
                params.wechatPayerClientIp = ipRes.data;
            }
        }
        payBuy(params).then(response => {
            setLoading(false);
            if (response.code === 200) {
                if (response.data.paymentId === BalancePayID) {
                    if (payRef) {
                        payRef.current.updateBalance();
                    }
                    handlePaySuccess();
                } else if (response.data.paymentId === AliPayID) {
                    window.open(response.data.url, "_blank");
                    if (checkPayRef) {
                        checkPayRef.current.show({ orderNo: response.data.orderNo, expire: keep2decimals(response.data.timeoutExpress * 60 * 1000) });
                    }
                } else if (response.data.paymentId === WechatPayID) {
                    if (response.data.h5Url) {
                        const rUrl = window.location.origin + "/order";
                        window.open(response.data.h5Url + `&redirect_url=${encodeURIComponent(rUrl)}`, '_blank');
                        if (checkPayRef) {
                            checkPayRef.current.show({ orderNo: response.data.orderNo, expire: keep2decimals(response.data.timeoutExpress * 60 * 1000) });
                        }
                    } else {
                        showQrCode(response.data);
                    }
                } else if (response.data.paymentId === CompanyPayID) {
                    setCompanyPayOpen(true);
                }
            }
        }).catch(() => {
            setLoading(false);
        })
    }

    const handlePaySuccess = () => {
        history.push("/order");
    }

    const showQrCode = (data) => {
        setQrCode({
            title: `购买静态IP - ${totalPrice}元`,
            orderNo: data.orderNo || "",
            codeUrl: data.codeUrl || "",
            expire: keep2decimals(data.timeoutExpress * 60 * 1000)
        });
        setQrCodeVisible(true);
    }

    const handleChangeQrCodeVisible = useCallback((val, success) => {
        setQrCodeVisible(val);
        if (success) {
            handlePaySuccess();
        }
        // eslint-disable-next-line
    }, [])

    const handleShowCancelModal = (data) => {
        setCancelData({
            orderNo: data.orderNo || "",
            title: data.title || "",
        });
        setCancelPayVisible(true);
    }

    const handleChangeCancelVisible = useCallback((val) => {
        setCancelPayVisible(val);
    }, [])

    const handleChangeCompanyPayOpen = (open, isOk) => {
        setCompanyPayOpen(open);
        if (isOk) {
            history.push("/order");
        }
    }

    const { device } = props;
    const [openOrderDrawer, setOpenOrderDrawer] = useState(true);
    const [showOpenBtn, setShowOpenBtn] = useState(true);
    const [showCloseBtn, setCloseOpenBtn] = useState(true);
    useEffect(() => {
        if (device === "fullhd") {
            setOpenOrderDrawer(true);
            setShowOpenBtn(false);
            setCloseOpenBtn(false);
        } else if (device === "mobile") {
            setOpenOrderDrawer(false);
            setShowOpenBtn(false);
            setCloseOpenBtn(false);
        } else {
            setShowOpenBtn(true);
            setCloseOpenBtn(true);
        }
    }, [device])
    useEffect(() => {
        if (device === "mobile") {
            const parentElem = document.querySelector(".antd-content-wrap");
            if (parentElem) {
                parentElem.scrollTo({ top: 0 });
            }
        }
        // eslint-disable-next-line
    }, [openOrderDrawer])
    const doOpenOrderDrawer = () => {
        if (device !== "fullhd" && device !== "mobile" && !openOrderDrawer) {
            setOpenOrderDrawer(true);
        }
    }

    return <>
        <Container>
            <Spin spinning={loading}>
                <LeftWrap>
                    <div className="page-title">购买IP</div>
                    <div className="static-header" style={{ justifyContent: openOrderDrawer && showCloseBtn ? "flex-start" : "space-between" }}>
                        <div className="static-header-left">
                            <Radio.Group value={staticIPType} onChange={(e) => setStaticIPType(e.target.value)} optionType="button" buttonStyle="solid" size="large">
                                {
                                    StaticIPTypeList.map(item => {
                                        return <Radio.Button key={item.value} value={item.value}>{item.label}</Radio.Button>
                                    })
                                }
                            </Radio.Group>
                        </div>
                        <div className="static-header-right">
                            <Radio.Group value={current} onChange={(e) => setCurrent(e.target.value)} optionType="button" buttonStyle="solid" size="large">
                                {
                                    items.map(item => {
                                        return <Radio.Button key={item.id} value={item} className="static-duration-item">
                                            {item.duration}天
                                            { item.duration === 90 ? <HotIcon className="static-duration-hot" /> : null }
                                        </Radio.Button>
                                    })
                                }
                            </Radio.Group>
                        </div>
                    </div>
                    <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)} items={regionTabs} />
                    <div id="staticRegionsWrap" className="static-regions-wrap">
                        <RegionWrap>
                            {
                                regions.map((item, index) => {
                                    if (activeKey === AllCountry || item.continent === activeKey) {
                                        const flagSrc = getFlagImageSrc(item.countryCode || item.region);
                                        return <div
                                            className={`region-item${item.quantity>0?" active":""}`}
                                            key={item.regionId}
                                        >
                                            <div className="region-item-top">
                                                <div className="region-item-flag">
                                                    {
                                                        flagSrc ? <img src={flagSrc} alt="pic" width="100%" height="100%" /> : null
                                                    }
                                                </div>
                                                <div className="region-item-name">{item.region}</div>
                                                <div className="region-item-price">¥{getRegionsPrice(item)}/IP</div>
                                            </div>
                                            <div className="region-item-input">
                                                <Button type="primary" className="region-item-input-btn" onClick={() => subQty(index)}><MinusOutlined /></Button>
                                                <InputNumber
                                                    value={item.quantity}
                                                    onChange={(val) => changeQty(val, index)}
                                                    min={0}
                                                    max={MaxNum}
                                                    parser={text => /^\d+$/.test(text) ? text : 1}
                                                    controls={false}
                                                    bordered={false}
                                                />
                                                <Button type="primary" className="region-item-input-btn" onClick={() => addQty(index)}><PlusOutlined /></Button>
                                            </div>
                                        </div>
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </RegionWrap>
                    </div>
                    {
                        showOpenBtn && !openOrderDrawer ? <div className="order-drawer-open-btn" onClick={() => setOpenOrderDrawer(true)}>
                            <DoubleLeftOutlined className="order-drawer-open-icon" />展开
                        </div> : null
                    }
                    {
                        device === "mobile" ? <div className="pay-submit-btn">
                            <Button type="primary" size="large" onClick={() => setOpenOrderDrawer(true)}>提交订单</Button>
                        </div> : null
                    }
                </LeftWrap>
                <RightWrap></RightWrap>
                <Drawer
                    placement="right"
                    closable={false}
                    open={openOrderDrawer}
                    getContainer={false}
                    mask={false}
                    width={ device !== "mobile" ? 420 : "100vw" }
                    className="order-submit-drawer"
                >
                    <div className="order-submit-container">
                        <div className="right-title">
                            { device === "mobile" ? <LeftOutlined className="order-submit-back" onClick={() => setOpenOrderDrawer(false)} /> : null }
                            订单确认
                        </div>
                        <div className="order-list-container">
                            <div className="order-title">购买IP-<span className="order-title-bold">{current ? current.duration : ""}天</span></div>
                            <RegionSummaryWrap>
                                {
                                    regions.map((item, index) => {
                                        if (item.quantity > 0) {
                                            const flagSrc = getFlagImageSrc(item.countryCode || item.region);
                                            return <div className="summary-item" key={item.regionId}>
                                                <div className="summary-left">
                                                    <div className="summary-flag">
                                                        {
                                                            flagSrc ? <img src={flagSrc} alt="pic" width="100%" height="100%" /> : null
                                                        }
                                                    </div>
                                                    <div className="summary-info">
                                                        <div className="summary-region">{item.region}</div>
                                                        <div className="summary-price">¥{getRegionsPrice(item)}/IP</div>
                                                    </div>
                                                </div>
                                                <div className="summary-input">
                                                    <Input.Group compact className="custom-input-number">
                                                        <Button type="text" size="middle" className="in-left-btn" onClick={() => subQty(index)}><MinusOutlined /></Button>
                                                        <InputNumber
                                                            value={item.quantity}
                                                            onChange={(val) => changeQty(val, index)}
                                                            min={0}
                                                            max={MaxNum}
                                                            parser={text => /^\d+$/.test(text) ? text : 1}
                                                            controls={false}
                                                            size="middle"
                                                        />
                                                        <Button type="text" size="middle" className="in-right-btn" onClick={() => addQty(index)}><PlusOutlined /></Button>
                                                    </Input.Group>
                                                </div>

                                            </div>
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </RegionSummaryWrap>
                            <div className="summary-total">
                                <div>IP总数:</div>
                                <div className="summary-total-num">{totalNum}</div>
                            </div>
                        </div>
                        <div className="order-total-wrap">
                            <div className="order-renew-select">
                                <Select
                                    value={renewItemId}
                                    onChange={(val) => setRenewItemId(val)}
                                    size="middle"
                                    bordered={false}
                                >
                                    {
                                        items.map(item => {
                                            return <Select.Option value={item.id} key={item.id}>
                                                <div className="order-renew-select-option">
                                                    <span>自动续费</span>
                                                    <span>{item.duration}天</span>
                                                </div>
                                            </Select.Option>
                                        })
                                    }
                                    <Select.Option value={NoAutoRenewItemID} key={NoAutoRenewItemID}>关闭自动续费</Select.Option>
                                </Select>
                                <Tooltip title="请注意，开启自动续费后，系统将在当前套餐到期前一天自动进行续费，若当时余额不足无法续费，自动续费开关将会自动关闭。因此请确保您的星鹿账户余额充足，如果由于余额不足导致续费失败后造成任何问题，星鹿不承担任何责任。">
                                    <ExclamationCircleOutlined style={{ color: "#F56C6C", cursor: "pointer" }} />
                                </Tooltip>
                            </div>
                            <div className="order-total">
                                总金额
                                <span className="order-total-price">{totalPrice}元</span>
                            </div>
                        </div>
                        <PayTypes onRef={payRef} amount={totalPrice} warningTip={warningTip} onChange={(val) => setPayType(val)} onPay={gotoPay} />
                        {
                            showCloseBtn ? <div className="order-drawer-close-btn" onClick={() => setOpenOrderDrawer(false)}>
                                <DoubleLeftOutlined className="order-drawer-close-icon" />收起
                            </div> : null
                        }
                    </div>
                </Drawer>
            </Spin>
        </Container>
        <QrCodeModal
            device={device}
            open={qrCodeVisible}
            qrCode={qrCode}
            onCancelPay={handleShowCancelModal}
            onChangeOpen={handleChangeQrCodeVisible}
        />
        <CancelPayModal
            open={cancelPayVisible}
            cancelData={cancelData}
            onChangeOpen={handleChangeCancelVisible}
        />
        <CheckPayModal onRef={checkPayRef} onSuccess={handlePaySuccess} />
        <SimpleTipModal
            open={companyPayOpen}
            title="对公支付"
            content="您已经使用对公支付的方式创建了订单，请点击下方按钮前往【订单列表】，点击订单操作的【对公支付】，查看星鹿对公账号支付并上传支付凭证。"
            okText="前往"
            onChangeOpen={handleChangeCompanyPayOpen}
        />
    </>
}

// 映射 Redux 全局的 state 到组件的 props 上
const mapStateToProps = (state) => ({
    device: state.getIn(["app", "device"]),
});

// 映射 dispatch 到 props 上
const mapDispatchToProps = (dispatch) => {
    return {
    }
};

// 将 ui 组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PurchaseIP));
