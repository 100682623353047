export const evil = (fn) => {
  var Fn = Function
  return new Fn('return ' + fn)()
}

export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt
}

export const keep2decimals = (num, decimals) => {
  const d = decimals || 2;
  return parseFloat(num.toFixed(d));
}

// 判断是否过期
export const checkExpireDate = (date) => {
  let noTime = new Date().getTime();
  let expireTime = new Date(new Date(date).setHours(23, 59, 59, 59)).getTime();
  return noTime >= expireTime;
}

// 判断是否是在微信浏览器中打开
export const isWeiXin = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /micromessenger/.test(userAgent);
}

// 判断当前是否是移动端浏览器
export const isMobile = () => {
  const userAgent = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|SymbianOS|Windows Phone/i.test(userAgent);
}

// 判断当前是否支持微信H5支付
export const isWechatH5Pay = () => {
  return isMobile() && !isWeiXin();
}

export const getRemainingDays = (createTime, expireTime) => {
  const oneDay = 24 * 60 * 60 * 1000 // 每天的毫秒数
  const firstDate = new Date(createTime)
  const secondDate = new Date(expireTime)
  // 计算时间差
  const diff = Math.abs(firstDate - secondDate)
  // 返回差值的天数
  return Math.floor(diff / oneDay)
}

export const getClosestNumber = (step, num) => {
  if (num % step === 0) {
    return num; // 如果本身是step的倍数，直接返回
  }
  // 不是step的倍数，则向上或向下舍入到最近的step的倍数
  return Math.round(num / step) * step;
}

export const gotoNewWindowPath = (path) => {
  window.open(`${window.location.origin}${path}`, "_blank");
}

export const getSdwanRemainingDays = (hours) => {
  if (hours <= 0) {
    return "0天";
  }
  const days = Math.floor(hours / 24); // 计算天数
  if (days > 0) {
    return `${days}天`;
  } else {
    return "不足1天";
  }
}

export const getSdwanUpgradeDays = (hours) => {
  if (hours <= 0) {
    return 0;
  }
  const days = Math.floor(hours / 24); // 计算天数
  if (days > 0) {
    return days;
  } else {
    return 1;
  }
}
