import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 0 16px;
    display: flex;
    flex-wrap: wrap;
    .panel-header {
        flex-shrink: 0;
        height: 70px;
        border-bottom: 1px solid #EBEEF5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .panel-header-title {
            display: flex;
            align-items: center;
            .panel-header-icon {
                width: 26px;
                height: 26px;
            }
            .panel-header-name {
                font-size: 22px;
                color: #4073FF;
                margin-left: 14px;
            }
        }
        .panel-header-actions {
            .ant-btn {
                font-size: 14px;
            }
        }
    }
    @media (max-width: 768px) {
        padding: 0;
        .panel-header {
            height: 56px;
            border-bottom: none;
            .panel-header-title {
                .panel-header-icon {
                    width: 22px;
                    height: 22px;
                }
                .panel-header-name {
                    font-family: "msyhbd";
                    font-size: 16px;
                    color: #212529;
                    margin-left: 8px;
                }
            }
        }
    }
`

export const TodoWrap = styled.div`
    width: 35%;
    height: 400px;
    padding: 0 30px;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px 0px rgba(190,206,254,0.35);
    border-radius: 10px;
    margin-top: 16px;
    .todo-list {
        padding: 20px 0;
        .todo-item {
            height: 150px;
            padding: 20px 20px 20px 40px;
            border: 1px solid #C8CDD9;
            border-left: 20px solid #4073FF;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            .todo-item-title {
                font-size: 20px;
                color: #4D5059;
                display: flex;
                align-items: center;
                .todo-item-tag {
                    margin-left: 10px;
                }
            }
            .todo-item-desc {
                font-size: 14px;
                color: #7B808C;
                margin-top: 12px;
            }
            .todo-item-actions {
                position: absolute;
                right: 20px;
                bottom: 20px;
            }
        }
        .todo-list-tip {
            font-size: 14px;
            color: #4D5059;
            display: flex;
            align-items: center;
            .icon-svg {
                width: 20px;
                height: 20px;
                fill: #FF7D2E;
                margin-right: 8px;
            }
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        height: fit-content;
        padding: 0 16px;
        box-shadow: none;
        border-radius: 0;
        margin-top: 0;
        order: 1;
        .todo-list {
            padding: 0;
            .todo-item {
                height: fit-content;
                padding: 16px;
                border: none;
                box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
                border-left: 4px solid #4073FF;
                border-radius: 4px;
                margin-bottom: 16px;
                .todo-item-title {
                    font-family: "msyhbd";
                    font-size: 14px;
                    color: #212529;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .todo-item-tag {
                        margin-left: 0;
                    }
                }
                .todo-item-desc {
                    font-size: 12px;
                    color: #6C757D;
                    margin-top: 8px;
                    padding-right: 70px;
                }
                .todo-item-actions {
                    right: 16px;
                    bottom: 16px;
                    align-self: start;
                    .ant-btn {
                        width: 66px;
                        height: 22px;
                        padding: 0 4px;
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
`

export const NewsWrap = styled.div`
    width: 64%;
    height: 400px;
    padding: 0 30px;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px 0px rgba(190,206,254,0.35);
    border-radius: 10px;
    margin-left: 1%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    .news-list {
        flex: 1;
        margin: 20px 0;
        overflow-y: auto;
        .news-item {
            padding: 8px 12px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .news-item-dot {
                width: 10px;
                height: 10px;
                background: #EBEEF5;
                border-radius: 50%;
            }
            .news-item-tag {
                width: 60px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                font-size: 14px;
                border-radius: 4px;
                margin-left: 24px;
            }
            .news-item-name {
                flex: 1;
                font-size: 16px;
                color: #4D5059;
                margin-left: 24px;
                margin-right: 12px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                .news-item-hot {
                    width: 24px;
                    height: 24px;
                }
            }
            .news-item-bottom {
                display: flex;
                align-items: center;
            }
            .news-item-date {
                font-family: "msyhl";
                font-size: 14px;
                color: #7B808C;
            }
            .news-item-sticky {
                width: 36px;
                height: 20px;
                background-color: #67c23a;
                color: #fff;
                line-height: 20px;
                text-align: center;
                font-size: 12px;
                border-radius: 4px;
                margin-left: 12px;
            }
            .news-item-sticky2 {
                width: 36px;
                margin-left: 12px;
            }
            &:hover {
                .news-item-name, .news-item-date {
                    color: #4073FF;
                    text-decoration-line: underline;
                }
            }
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        height: fit-content;
        padding: 0 16px 90px 16px;
        box-shadow: none;
        border-radius: 0;
        border-top: 1px solid #DFE3EA;
        margin-left: 0;
        margin-top: 0;
        order: 3;
        .news-list {
            flex: auto;
            margin: 0;
            .news-item {
                padding: 16px 0 12px 0;
                flex-wrap: wrap;
                border-bottom: 1px solid #DFE3EA;
                .news-item-dot {
                    display: none;
                }
                .news-item-tag {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 12px;
                    margin-left: 0;
                }
                .news-item-name {
                    font-size: 14px;
                    color: #4B5563;
                    margin-left: 12px;
                    margin-right: 0;
                    .news-item-hot {
                        width: 20px;
                        height: 20px;
                    }
                }
                .news-item-bottom {
                    width: 100%;
                    justify-content: space-between;
                    margin-top: 6px;
                }
                .news-item-date {
                    order: 2;
                    font-size: 12px;
                    color: #4B5563;
                }
                .news-item-sticky {
                    order: 1;
                    width: 40px;
                    background-color: #f0f9eb;
                    color: #67c23a;
                    margin-left: 0;
                }
                .news-item-sticky2 {
                    order: 1;
                }
                &:hover {
                    .news-item-name, .news-item-date {
                        color: #4B5563;
                        text-decoration-line: none;
                    }
                }
            }
        }
    }
`

export const StepsWrap = styled.div`
    width: 100%;
    min-height: calc(100vh - 102px);
    padding: 0 30px;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px 0px rgba(190,206,254,0.35);
    border-radius: 10px;
    margin-top: 16px;
    margin-bottom: 16px;
    .steps-wrap {
        padding: 20px 0;
        display: flex;
        .steps-list {
            width: 34%;
            .steps-item {
                height: 176px;
                border: 1px solid transparent;
                display: flex;
                align-items: center;
                justify-content: space-around;
                cursor: pointer;
                &.steps-item-active{
                    background: #FFFFFF;
                    box-shadow: 0px 5px 5px 1px rgba(190,206,254,0.35);
                    border-radius: 10px;
                    border: 1px solid #EBEEF5;
                }
                .steps-item-icon {
                    width: 100px;
                    height: auto;
                }
                .steps-item-key {
                    font-family: "msyhbd";
                    font-size: 32px;
                    color: #066EFF;
                }
                .steps-item-title {
                    width: 130px;
                    font-size: 22px;
                    color: #000000;
                }
            }
        }
        .steps-video {
            width: 64%;
            max-width: 800px;
            height: 530px;
            padding: 20px;
            background: #EBEEF5;
            border-radius: 10px;
            margin-left: 1%;
            .steps-video-title {
                font-size: 24px;
                color: #000000;
                text-align: center;
                margin-top: 10px;
                margin-bottom: 30px;
            }
            .steps-video-box {
                background-color: #000000;
            }
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        min-height: auto;
        height: fit-content;
        padding: 0 16px 16px 16px;
        box-shadow: none;
        border-radius: 0;
        border-top: 1px solid #DFE3EA;
        margin-top: 0;
        margin-bottom: 0;
        order: 2;
        .steps-wrap {
            padding: 0;
            flex-wrap: wrap;
            .steps-list {
                width: 100%;
                .steps-item {
                    height: 56px;
                    padding: 0 16px;
                    border: none;
                    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
                    border-radius: 4px;
                    justify-content: flex-start;
                    margin-bottom: 16px;
                    &.steps-item-active{
                        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
                        border-radius: 4px;
                        border: none;
                    }
                    .steps-item-icon {
                        display: none;
                    }
                    .steps-item-key {
                        font-size: 24px;
                        color: #4073FF;
                    }
                    .steps-item-title {
                        width: fit-content;
                        font-size: 14px;
                        color: #212529;
                        margin-left: 16px;
                    }
                }
            }
            .steps-video {
                width: 100%;
                height: fit-content;
                padding: 0;
                background: #D9D9D9;
                border-radius: 4px 4px 4px 4px;
                margin-left: 0;
                .steps-video-title {
                    display: none;
                }
                .steps-video-box {
                    background-color: #000000;
                }
            }
        }
    }
`
