import styled from "styled-components";

export const Container = styled.div`
    overflow: hidden;
    .ant-spin-nested-loading > div > .ant-spin {
        max-height: none;
    }
    .ant-spin-container {
        padding: 16px;
        min-height: calc(100vh - 70px);
        display: flex;
        position: relative;
    }
    @media (max-width: 768px) {
        .ant-spin-container {
            padding: 0;
            min-height: calc(100vh - 56px);
        }
    }
`

export const LeftWrap = styled.div`
    flex: 1;
    /* min-width: 894px; */
    min-height: 840px;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px 0px rgba(190,206,254,0.35);
    border-radius: 10px;
    padding: 20px 32px;
    position: relative;
    .page-title {
        font-size: 22px;
        color: #4073FF;
        margin-bottom: 20px;
    }
    .pay-submit-btn {
        width: 100%;
        margin-top: 32px;
    }
    @media (max-width: 768px) {
        width: 100%;
        min-height: auto;
        box-shadow: none;
        border-radius: 0;
        padding: 16px 16px 80px 16px;
        .page-title {
            display: none;
        }
    }
`

export const RightWrap = styled.div`
    width: 420px;
    margin-left: 10px;
    @media (max-width: 1408px) {
        width: 0;
        margin-left: 0;
    }
`

export const LineWrap = styled.div`
    max-width: 1130px;
    display: flex;
    flex-wrap: wrap;
`

export const LineItem = styled.div`
    width: 48.5%;
    height: 350px;
    padding: 20px 30px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #C8CDD9;
    margin-right: 3%;
    margin-bottom: 24px;
    cursor: pointer;
    &:nth-child(even) {
        margin-right: 0;
    }
    .line-main {
        min-height: 190px;
        padding-bottom: 20px;
        border-bottom: 1px solid #EBEEF5;
        display: flex;
        align-items: center;
        .line-info {
            width: 60%;
            .line-name {
                font-family: "msyhbd";
                font-size: 28px;
            }
            .line-price {
                font-size: 28px;
                margin-top: 18px;
                .line-price-tip {
                    font-size: 18px;
                }
            }
            .line-description {
                font-size: 14px;
                color: #7B808C;
                margin-top: 18px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                overflow: hidden;
                word-wrap: break-word;
                word-break: break-all;
            }
        }
        .line-icon {
            width: 40%;
            height: auto;
        }
    }
    .line-param {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #333333;
        margin-top: 14px;
        .line-param-label {
            color: #202226;
        }
    }
    &.active {
        background: #FAFBFF;
        box-shadow: 0px 1px 15px 1px rgba(115,151,251,0.35);
        border: 2px solid #225AF2;
        .line-main {
            .line-info {
                .line-price {
                    color: #FF7D2E;
                    .line-price-tip {
                        color: #95979E;
                    }
                }
            }
        }
    }
`

export const LineMWrap = styled.div`
    margin-bottom: 16px;
    .line-tab-list {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .line-tab-item {
            font-family: "msyhbd";
            font-size: 16px;
            color: #B1B1B1;
            display: flex;
            flex-direction: column;
            align-items: center;
            .line-tab-line {
                width: 32px;
                height: 4px;
                background: #4073FF;
                border-radius: 2px 2px 2px 2px;
                margin-top: 4px;
                visibility: hidden;
            }
            &.active {
                color: #212529;
                .line-tab-line {
                    visibility: visible;
                }
            }
        }
    }
    .line-curr-container {
        width: 100%;
        padding: 16px 12px;
        background: #FFFFFF;
        box-shadow: 1px 1px 8px 0px rgba(0,0,0,0.2);
        border-radius: 8px 8px 8px 8px;
        margin-top: 16px;
        .line-curr-main {
            height: 78px;
            display: flex;
            align-items: center;
            .line-curr-icon {
                flex-shrink: 0;
                width: auto;
                height: 100%;
            }
            .line-curr-info {
                flex: 1;
                margin-left: 16px;
                .line-curr-name {
                    font-family: "msyhbd";
                    font-size: 18px;
                    color: #334155;
                }
                .line-curr-price {
                    font-size: 22px;
                    color: #FF7203;
                    margin-top: 4px;
                    .line-curr-price-tip {
                        font-size: 14px;
                        color: #838594;
                    }
                }
            }
        }
        .line-curr-description {
            font-size: 12px;
            color: #B1B1B1;
            padding-bottom: 6px;
            margin-top: 6px;
            border-bottom: 1px solid #DFE3EA;
        }
        .line-curr-param {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: "msyhbd";
            font-size: 14px;
            color: #334155;
            margin-top: 8px;
            .line-curr-param-label {
                font-family: "msyh";
            }
        }
    }
`
