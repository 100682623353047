import axios from "axios"
import { message } from "antd"
import { gotoLogin } from "@/utils/auth"

const CancelToken = axios.CancelToken
const source = CancelToken.source()

// create an axios instance
const service = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "/uapi" : "/", // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 180000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        // 判断是否有网络，若无网络则取消请求
        if (config.checkOnline && !navigator.onLine) {
            const errorMsg = "无网络"
            config.cancelToken = source.token
            source.token.reason = errorMsg
            source.cancel(errorMsg)
        }

        config.headers["Content-type"] = "application/json; charset=utf-8"

        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        let res = response.data

        // if the custom code is not 200 , it is judged as an error.
        if (res.hasOwnProperty("code")) {

            // 401: Unauthorized
            if (res.code === 401 || res.code === 10301) {
                // to re-login
                // 跳转登录页面
                gotoLogin()
                return Promise.reject(res.msg || "Error")
            } else {
                if (res.code !== 200) {
                    const NoMessageErrorApi = [
                        '/front/static_ip/external/import',
                        '/front/static_ip/external/add',
                        '/front/terminal/import',
                        '/front/terminal/addTerminal'
                    ];
                    for (let i = 0; i < NoMessageErrorApi.length; i++) {
                        if (response.config.url.includes(NoMessageErrorApi[i])) {
                            return res
                        }
                    }
                    message.destroy()
                    message.error(res.msg || "Error")
                    return Promise.reject(res.msg || "Error")
                } else {
                    return res
                }
            }
        } else {
            return res
        }
    },
    error => {
        const response = error.response
        if (response && response.status) {
            if (response.status === 401) { // login failed status
                // message.error(response.statusText || "Unauthorized Error")
                // 跳转登录页面
                gotoLogin()
            } else if (response.status >= 500) { // judge server error
                message.destroy()
                message.error(response.statusText || "Server Error")
            } else {
                message.destroy()
                message.error(response.statusText || "Error")
            }
        } else {
            message.destroy()
            message.error("Error")
        }
        return Promise.reject(error)
    }
)

export default service
