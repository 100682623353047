import styled from 'styled-components';

export const Container = styled.div`
`

export const HeaderWrap = styled.div`
    width: 100%;
    min-height: 114px;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .icon-svg {
        flex-shrink: 0;
        width: 66px;
        height: auto;
        margin-right: 20px;
    }
    .title {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .order-title {
            width: 100%;
            font-size: 22px;
            font-family: "msyhbd";
            word-wrap: break-word;
            word-break: break-word;
        }
        .order-no {
            width: 100%;
            font-size: 14px;
            word-wrap: break-word;
            word-break: break-word;
            margin-top: 2px;
        }
    }
    @media (max-width: 768px) {
        min-height: 50px;
        padding: 0;
        .icon-svg {
            width: 48px;
            margin-right: 8px;
        }
        .title {
            .order-title {
                font-size: 16px;
            }
            .order-no {
                font-size: 12px;
                margin-top: 2px;
            }
        }
    }
`

export const CountWrap = styled.div`
    width: 100%;
    min-height: 80px;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: #F9FAFE;
    border-radius: 8px;
    .title {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .pay-title {
            width: 100%;
            font-size: 14px;
            word-wrap: break-word;
            word-break: break-word;
        }
        .pay-tip {
            width: 100%;
            font-size: 14px;
            word-wrap: break-word;
            word-break: break-word;
            margin-top: 4px;
        }
    }
    .count-wrap {
        width: 60px;
        height: 60px;
        background-color: #E3F5E5;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        .ant-statistic-content {
            color: #24B340;
            font-size: 18px;
        }
    }
    @media (max-width: 768px) {
        padding: 8px;
        margin-top: 12px;
        border-radius: 4px;
        .title {
            .pay-title {
                font-size: 12px;
            }
            .pay-tip {
                font-size: 12px;
            }
        }
        .count-wrap {
            width: 50px;
            height: 50px;
            margin-left: 8px;
            .ant-statistic-content {
                font-size: 14px;
            }
        }
    }
`

export const CodeWrap = styled.div`
    width: 210px;
    height: 210px;
    margin: 20px auto;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        width: 140px;
        height: 140px;
        margin: 12px auto;
    }
`

export const WarningWrap = styled.div`
    width: 100%;
    padding: 10px 20px;
    margin: 0 auto;
    background-color: #F9FAFE;
    border-radius: 8px;
    text-align: center;
    @media (max-width: 768px) {
        padding: 8px;
        border-radius: 4px;
        font-size: 12px;
    }
`

export const CloseBtn = styled.div`
    width: 100%;
    text-align: center;
    margin: 60px 0;
    .ant-btn {
        min-width: 150px;
        margin-left: 20px;
        &:first-child {
            margin-left: 0;
        }
    }
    .ant-btn:hover, .ant-btn:focus {
        color: #24B340;
        border-color: #24B340;
    }
    .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus {
        background: #24B340;
        border-color: #24B340;
        color: #FFFFFF;
    }
    @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        .ant-btn {
            min-width: auto;
            width: 100%;
            margin-left: 16px;
            height: 36px;
            font-size: 14px;
        }
    }
`
