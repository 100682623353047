import React from "react";
import "./style.less";
import { ReactComponent as ArrowIcon } from "./images/arrow.svg";
import { ReactComponent as SuccessIcon } from "./images/success.svg";

export default class ReactSimpleVerify extends React.Component {
    /**
     * 默认参数
     */
    static defaultProps = {
        width: 422,
        tips: "请按住滑块，拖到最右边",
        movedColor: "linear-gradient(313deg, rgba(65, 209, 102, 1) 0%, rgba(90, 232, 118, 1) 100%)",
        successTips: "完成验证"
    }

    /**
     * 初始数据
     */
    /** x */
    x1 = 0;
    x2 = 0;
    /** 鼠标是否按下 */
    isMousedown = false;
    /** 是否已经成功 */
    isSuccess = false;
    /** 最大滑动距离 */
    max = this.props.width - 58;
    /** 盒子样式 */
    style = {
        width: this.props.width
    }

    /**
     * 重置
     */
    reset = () => {
        this.isSuccess = false;
        this.setState({
            diff: 0
        })
        setTimeout(() => {
            this.isMousedown = false;
            this.setState({
                isMouseEnter: false
            })
        }, 0)
    }

    constructor(props) {
        super(props);
        this.state = {
            /** 是否滑入 */
            isMouseEnter: false,
            /** 滑动距离 */
            diff: 0
        }
    }

    /**
     * 绑定事件
     */
    componentDidMount() {
        document.body.addEventListener("mousemove", this.mousemove.bind(this));
        document.body.addEventListener("touchmove", this.mousemove.bind(this));
        document.body.addEventListener("mouseup", this.mouseup.bind(this));
        document.body.addEventListener("touchend", this.mouseup.bind(this));
    }

    /**
     * 移除事件
     */
    componentWillUnmount() {
        document.body.removeEventListener("mousemove", this.mousemove.bind(this));
        document.body.removeEventListener("touchmove", this.mousemove.bind(this));
        document.body.removeEventListener("mouseup", this.mouseup.bind(this));
        document.body.removeEventListener("touchend", this.mouseup.bind(this));
    }

    /**
     * 鼠标移入
     */
    mouseenter() {
        if (this.isSuccess) {
            return;
        }
        this.setState({
            isMouseEnter: true
        })
    }

    /**
     * 鼠标离开
     */
    mouseleave() {
        if (this.isSuccess || this.isMousedown) {
            return;
        }
        this.setState({
            isMouseEnter: false
        })
    }

    /**
     * 鼠标按下
     */
    mousedown(e) {
        if (this.isSuccess || this.isMousedown) {
            return;
        }
        this.x1 = e.nativeEvent.x || e.touches[0].clientX;
        this.isMousedown = true;
    }

    /**
     * 鼠标移动
     */
    mousemove(e) {
        if (!this.isMousedown || this.isSuccess) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        this.x2 = e.x || e.touches[0].clientX;
        let diff = this.x2 - this.x1;
        if (diff < 0) {
            diff = 0;
        }
        if (diff >= this.max) {
            diff = this.max;
            this.isSuccess = true;
            this.props.success && this.props.success();
        }
        this.setState({
            diff
        })
    }

    /**
     * 鼠标松开
     */
    mouseup() {
        if (this.isSuccess) {
            return;
        }
        this.isMousedown = false;
        this.setState({
            isMouseEnter: false,
            diff: 0
        })
    }

    render() {
        /** 滑条样式 */
        const slideStyle = {
            borderRadius: this.props.borderRadius,
            background: this.props.movedColor,
            left: 58 - this.props.width,
            opacity: this.state.isMouseEnter ? 1 : 0,
            transitionDuration: !this.state.isMouseEnter || !this.isMousedown ? ".3s" : "0s",
            transform: `translateX(${this.state.diff}px)`
        }
        /** 滑块样式 */
        const barStyle = {
            transitionDuration: !this.state.isMouseEnter || !this.isMousedown ? ".3s" : "0s",
            transform: `translateX(${this.state.diff}px)`
        }
        /** 成功文本样式 */
        const textStyle = {
            opacity: this.isSuccess ? 1 : 0,
            transitionDuration: !this.state.isMouseEnter || !this.isMousedown ? ".3s" : "0s"
        }
        return (
            <div style={this.style} className="simple-verify">
                <div className="verify-tips">{this.props.tips}</div>
                <div className="verify-box">
                    <div style={slideStyle} className="verify-slide" />
                </div>
                <div
                    className="verify-bar"
                    onMouseEnter={this.mouseenter.bind(this)}
                    onTouchStart={this.mouseenter.bind(this)}
                    onMouseLeave={this.mouseleave.bind(this)}
                    onTouchEnd={this.mouseleave.bind(this)}
                    onMouseDown={this.mousedown.bind(this)}
                    onTouchMove={this.mousedown.bind(this)}
                >
                    <div style={barStyle} className="icon">
                        <ArrowIcon className="icon-svg" />
                    </div>
                </div>
                <div style={textStyle} className="verify-success-tips">
                    <SuccessIcon className="verify-success-icon" />{this.props.successTips}
                </div>
            </div>
        )
    }
}