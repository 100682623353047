export const SET_TOKEN = "user/SET_TOKEN";

export const SET_USER_PHONE = "user/SET_USER_PHONE";
export const SET_USER_NAME = "user/SET_USER_NAME";
export const SET_USER_EMAIL = "user/SET_USER_EMAIL";
export const SET_USER_ID = "user/SET_USER_ID";
export const SET_USER_BALANCE = "user/SET_USER_BALANCE";
export const SET_USER_CHECK_STATE = "user/SET_USER_CHECK_STATE";
export const SET_USER_SDWAN_STATE = "user/SET_USER_SDWAN_STATE";
export const SET_USER_CREATE_TIME = "user/SET_USER_CREATE_TIME";
export const SET_USER_ADDRESS_LIST = "user/SET_USER_ADDRESS_LIST";
export const SET_USER_DEFAULT_ADDRESS = "user/SET_USER_DEFAULT_ADDRESS";
