import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`

function FullPageLoading() {
    return <Container>
        <Spin />
    </Container>
}

export default React.memo(FullPageLoading);
