import React, { lazy, Suspense } from "react";
import { Redirect } from "react-router-dom";
import Home from "@/application/Home";
import Layout from "@/application/Layout";
import Page404 from "@/application/Page404";
import PageLoading from "@/baseUI/PageLoading";
import FullPageLoading from "@/baseUI/FullPageLoading";

import LoginComponent from "@/application/Login";
import RegisterComponent from "@/application/Register";

import { ReactComponent as HomeIcon } from "@/assets/svg/menu/home.svg";
import { ReactComponent as CartIcon } from "@/assets/svg/menu/cart.svg";
import { ReactComponent as DeviceIcon } from "@/assets/svg/menu/device.svg";
import { ReactComponent as LineIcon } from "@/assets/svg/menu/line.svg";
import { ReactComponent as BoxIcon } from "@/assets/svg/menu/box.svg";
import { ReactComponent as StaticIPIcon } from "@/assets/svg/menu/staticIP.svg";

import OverviewComponent from "@/application/Overview";
import PurchaseLineComponent from "@/application/PurchaseLine";
import staticIPPurchaseComponent from "@/application/staticIPPurchase";

const ReloadComponent = lazy(() => import("@/application/Reload"));

// const RegisterComponent = lazy(() => import("@/application/Register"));
const ResetPasswordComponent = lazy(() => import("@/application/ResetPassword"));
const AccountComponent = lazy(() => import("@/application/Account"));
const RechargeComponent = lazy(() => import("@/application/Recharge"));
const OrderListComponent = lazy(() => import("@/application/OrderList"));

// const OverviewComponent = lazy(() => import("@/application/Overview"));
// const PurchaseLineComponent = lazy(() => import("@/application/PurchaseLine"));
const DeviceManagementComponent = lazy(() => import("@/application/DeviceManagement"));
const LineManagementComponent = lazy(() => import("@/application/LineManagement"));
const BandwidthUpgradeComponent = lazy(() => import("@/application/BandwidthUpgrade"));
const LineRenewComponent = lazy(() => import("@/application/LineRenew"));
const LineIPManagementComponent = lazy(() => import("@/application/LineIPManagement"));
const BoxManagementComponent = lazy(() => import("@/application/BoxManagement"));

const PrivacyComponent = lazy(() => import("@/application/Docs/privacy"));
const TermsOfServiceComponent = lazy(() => import("@/application/Docs/termsOfService"));
const RefundAgreementComponent = lazy(() => import("@/application/Docs/refundAgreement"));

// const EnterpriseComponent = lazy(() => import("@/application/Enterprise"));

// const staticIPPurchaseComponent = lazy(() => import("@/application/staticIPPurchase"));
const StaticIPManagementComponent = lazy(() => import("@/application/StaticIPManagement"));
const StaticIPRenewComponent = lazy(() => import("@/application/StaticIPRenew"));

const SuspenseComponent = Component => props => {
    return (
        <Suspense fallback={<PageLoading />}>
            <Component {...props}></Component>
        </Suspense>
    )
}

const FullSuspenseComponent = Component => props => {
    return (
        <Suspense fallback={<FullPageLoading />}>
            <Component {...props}></Component>
        </Suspense>
    )
}

export const mainClientRoutes = [
    {
        path: "/index",
        component: SuspenseComponent(OverviewComponent),
        meta: { title: "首页", icon: <HomeIcon className="icon-svg" /> },
        auth: true,
        exact: true,
        pageTitle: '星鹿跨境电商加速盒-首页',
        pageKeywords: '跨境电商直播加速, 星鹿加速盒, 跨境电商解决方案, tiktok直播海外直播解决方案, 跨境电商直播网络优化',
        pageDescription: '星鹿跨境电商加速盒官网，全球节点覆盖，智能跨境专线+静态住宅IP，稳定高效、超低延迟，一站式解决跨境网络问题，为跨境业务量身定制，无需复杂配置，即插即用。'
    },
    {
        path: "/purchase",
        component: SuspenseComponent(PurchaseLineComponent),
        meta: { title: "购买线路", icon: <CartIcon className="icon-svg" /> },
        auth: true,
        exact: true,
        pageTitle: '购买线路-星鹿跨境电商加速盒',
    },
    {
        path: "/device",
        component: SuspenseComponent(DeviceManagementComponent),
        meta: { title: "设备管理", icon: <DeviceIcon className="icon-svg" /> },
        auth: true,
        exact: true,
        pageTitle: '设备管理-星鹿跨境电商加速盒',
    },
    {
        path: "/sdwan",
        component: SuspenseComponent(LineManagementComponent),
        meta: { title: "线路管理", icon: <LineIcon className="icon-svg" /> },
        auth: true,
        exact: true,
        pageTitle: '线路管理-星鹿跨境电商加速盒',
    },
    {
        path: "/box",
        component: SuspenseComponent(BoxManagementComponent),
        meta: { title: "盒子管理", icon: <BoxIcon className="icon-svg" /> },
        auth: true,
        exact: true,
        pageTitle: '盒子管理-星鹿跨境电商加速盒',
    },
    {
        path: "/staticIP",
        component: Layout,
        meta: { title: "静态住宅IP", icon: <StaticIPIcon className="icon-svg" /> },
        auth: true,
        routes: [{
            path: '/staticIP',
            exact: true,
            render: () => ( <Redirect to={'/staticIP/list'} /> )
        }, {
            path: "/staticIP/purchase",
            component: SuspenseComponent(staticIPPurchaseComponent),
            meta: { title: "购买IP" },
            auth: true,
            exact: true,
            pageTitle: '购买IP-星鹿跨境电商加速盒',
        }, {
            path: "/staticIP/list",
            component: SuspenseComponent(StaticIPManagementComponent),
            meta: { title: "IP管理" },
            auth: true,
            exact: true,
            pageTitle: 'IP管理-星鹿跨境电商加速盒',
        }, {
            path: "/staticIP/renew",
            component: SuspenseComponent(StaticIPRenewComponent),
            meta: { title: "静态IP续费" },
            auth: true,
            exact: true,
            hidden: true,
            pageTitle: '静态IP续费-星鹿跨境电商加速盒',
        }]
    },
]

const otherRoutes = [
    {
        path: "/account",
        component: SuspenseComponent(AccountComponent),
        meta: { title: "账户信息" },
        auth: true,
        exact: true,
        pageTitle: '账户信息-星鹿跨境电商加速盒',
    },
    // {
    //     path: "/enterprise",
    //     component: SuspenseComponent(EnterpriseComponent),
    //     meta: { title: "企业认证" },
    //     auth: true,
    //     exact: true,
    //     pageTitle: "星鹿 - 企业认证"
    // },
    {
        path: "/recharge",
        component: SuspenseComponent(RechargeComponent),
        meta: { title: "账户充值" },
        auth: true,
        exact: true,
        pageTitle: '账户充值-星鹿跨境电商加速盒',
    },
    {
        path: "/order",
        component: SuspenseComponent(OrderListComponent),
        meta: { title: "订单列表" },
        auth: true,
        exact: true,
        pageTitle: '订单列表-星鹿跨境电商加速盒',
    },
    {
        path: "/sdwan/upgrade",
        component: SuspenseComponent(BandwidthUpgradeComponent),
        meta: { title: "带宽升级" },
        auth: true,
        exact: true,
        pageTitle: '带宽升级-星鹿跨境电商加速盒',
    },
    {
        path: "/sdwan/renew",
        component: SuspenseComponent(LineRenewComponent),
        meta: { title: "线路续费" },
        auth: true,
        exact: true,
        pageTitle: '线路续费-星鹿跨境电商加速盒',
    },
    {
        path: "/sdwan/iplist",
        component: SuspenseComponent(LineIPManagementComponent),
        meta: { title: "出口IP详情" },
        auth: true,
        exact: true,
        pageTitle: '出口IP详情-星鹿跨境电商加速盒',
    },
]

const getBreadcrumbNameMap = (routes) => {
    const list = [ ...routes ];
    let breadcrumbNameObj = {};
    const getItems = (list) => {
        list.forEach((item) => {
            if (item.routes && item.routes.length) {
                if (item.meta) {
                    breadcrumbNameObj[item.path] = item.meta.title;
                }
                getItems(item.routes);
            } else {
                if (item.meta) {
                    breadcrumbNameObj[item.path] = item.meta.title;
                }
            }
        })
    }
    getItems(list);
    return breadcrumbNameObj;
}

export const BreadcrumbNameMap = getBreadcrumbNameMap([...mainClientRoutes, ...otherRoutes]);

const routes = [
    {
        path: "/404",
        component: Page404,
        exact: true,
        pageTitle: '404-星鹿跨境电商加速盒',
    },
    {
        path: "/login",
        component: FullSuspenseComponent(LoginComponent),
        exact: true,
        pageTitle: '星鹿跨境电商加速盒-登录',
        pageKeywords: '星鹿跨境电商加速盒, 用户中心登录, 账号登录, 会员登录, 登录入口',
        pageDescription: '星鹿跨境电商加速盒用户中心登录页面，提供安全、便捷的登录通道，用户输入账号密码即可进入专属服务空间，管理设备、查看使用数据、获取技术支持等。'
    },
    {
        path: '/register',
        component: Layout,
        routes: [{
            path: '/register',
            exact: true,
            component: RegisterComponent,
            pageTitle: '星鹿跨境电商加速盒-注册',
            pageKeywords: '星鹿跨境电商加速盒, 用户中心注册, 新用户注册, 账号注册, 注册流程',
            pageDescription: '欢迎来到星鹿跨境电商加速盒用户中心注册页面，按照指引轻松完成注册，解锁产品丰富功能，畅享一站式跨境电商网络加速服务体验。'
        }, {
            path: '/register/:invitationCode',
            component: RegisterComponent,
            pageTitle: '星鹿跨境电商加速盒-注册',
            pageKeywords: '星鹿跨境电商加速盒, 用户中心注册, 新用户注册, 账号注册, 注册流程',
            pageDescription: '欢迎来到星鹿跨境电商加速盒用户中心注册页面，按照指引轻松完成注册，解锁产品丰富功能，畅享一站式跨境电商网络加速服务体验。'
        }]
    },
    {
        path: "/findpassword",
        component: FullSuspenseComponent(ResetPasswordComponent),
        exact: true,
        pageTitle: '重置密码-星鹿跨境电商加速盒',
    },
    {
        path: "/privacy",
        component: FullSuspenseComponent(PrivacyComponent),
        exact: true,
        pageTitle: '隐私政策-星鹿跨境电商加速盒',
    },
    {
        path: "/termsOfService",
        component: FullSuspenseComponent(TermsOfServiceComponent),
        exact: true,
        pageTitle: '服务条款-星鹿跨境电商加速盒',
    },
    {
        path: "/refundAgreement",
        component: FullSuspenseComponent(RefundAgreementComponent),
        exact: true,
        pageTitle: '退款协议-星鹿跨境电商加速盒',
    },
    {
        path: "/reload",
        component: SuspenseComponent(ReloadComponent),
        exact: true,
    },
    {
        path: "/",
        component: Home,
        routes: [
            {
                path: "/",
                exact: true,
                render: () => ( <Redirect to={"/index"} /> )
            },
            ...mainClientRoutes,
            ...otherRoutes,
            {
                path: "*",
                render: () => ( <Redirect to={"/404"} /> ),
            }
        ]
    }
]

export default routes;
