import * as actionTypes from "./constants";
import { fromJS } from "immutable";
import { register, login, getUserInfo, logout, getUserAddress, getBalance } from "@/api/request";
import { getToken } from "@/utils/auth";
import { EnterpriseNotAuthStatus } from "@/utils/options";

const changeData = (type, data) => ({
    type: type,
    data: fromJS(data)
})

export const userRegister = (userInfo) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const { phone, password, verifyCode, invitationCode } = userInfo
            register({ phone, password, verifyCode, invitationCode: invitationCode || null }).then(response => {
                if (response.code === 200) {
                    dispatch(changeData(actionTypes.SET_TOKEN, getToken()));

                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    }
}

export const userLogin = (userInfo) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const { loginType, phone, password, verifyCode } = userInfo
            login({ loginType, phone, password, verifyCode }).then(response => {
                if (response.code === 200) {
                    dispatch(changeData(actionTypes.SET_TOKEN, getToken()));

                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    }
}

export const userInfo = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            getUserInfo().then(response => {
                if (response.code === 200) {
                    dispatch(changeData(actionTypes.SET_USER_PHONE, response.data.phone));
                    dispatch(changeData(actionTypes.SET_USER_NAME, response.data.name));
                    dispatch(changeData(actionTypes.SET_USER_EMAIL, response.data.email));
                    dispatch(changeData(actionTypes.SET_USER_ID, response.data.id));
                    dispatch(changeData(actionTypes.SET_USER_CHECK_STATE, response.data.checkState || EnterpriseNotAuthStatus));
                    dispatch(changeData(actionTypes.SET_USER_SDWAN_STATE, response.data.sdwanState));
                    dispatch(changeData(actionTypes.SET_USER_CREATE_TIME, response.data.createTime));
                    
                    resolve(response.data);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    }
}

export const userLogout = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            logout().then(response => {
                if (response.code === 200) {
                    dispatch(changeData(actionTypes.SET_TOKEN, null));

                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    }
}

export const resetToken = () => {
    return dispatch => {
        dispatch(changeData(actionTypes.SET_TOKEN, null));
    }
}

export const userAddress = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            getUserAddress().then(response => {
                if (response.code === 200) {
                    const list = response.data || [];
                    let defaultAddr = null;
                    for (let i = 0; i < list.length; i++) {
                        if (list[i].isDefault) {
                            defaultAddr = list[i];
                            break;
                        }
                    }
                    dispatch(changeData(actionTypes.SET_USER_ADDRESS_LIST, list));
                    dispatch(changeData(actionTypes.SET_USER_DEFAULT_ADDRESS, {...defaultAddr}));
                    
                    resolve({ list: response.data, defaultAddress: defaultAddr });
                }
            }).catch((error) => {
                reject(error);
            })
        })
    }
}

export const userBalance = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            getBalance().then(response => {
                if (response.code === 200) {
                    dispatch(changeData(actionTypes.SET_USER_BALANCE, response.data.balance || 0));
                    
                    resolve(response.data);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    }
}
