import React, { useEffect, useState } from "react";
import { Tag, Button } from "antd";
import { Container, TodoWrap, NewsWrap, StepsWrap } from "./style";
import todoIcon from "./images/todo.png";
import newsIcon from "./images/news.png";
import stepsIcon from "./images/steps.png";
import step1Icon from "./images/step1.png";
import step2Icon from "./images/step2.png";
import step3Icon from "./images/step3.png";
import { EnterpriseAuthedStatus, NewsTypeOptions } from "@/utils/options";
import { connect } from "react-redux";
import { userInfo } from "@/store/modules/user/actionCreators";
import NewsDetailModal from "@/components/NewsDetailModal";
import { getNewsList } from "@/api/request";
// import { ReactComponent as ArrowIcon } from "@/assets/svg/arrow.svg";
// import { ReactComponent as PraiseIcon } from "@/assets/svg/praise.svg";

const stepList = [{
    key: "01",
    title: "企业认证",
    iconSrc: step1Icon,
    videoSrc: null
}, {
    key: "02",
    title: "购买线路",
    iconSrc: step2Icon,
    videoSrc: null
}, {
    key: "03",
    title: "连接加速盒",
    iconSrc: step3Icon,
    videoSrc: null
}]

function Overview(props) {
    const { history } = props;
    const { checkState, sdwanState, userInfoDispatch } = props;

    useEffect(() => {
        userInfoDispatch();
        getNewsData();
        // eslint-disable-next-line
    }, [])

    /* 待办事项 -- start */
    const [todoList, setTodoList] = useState([]);
    useEffect(() => {
        const list = [];
        if (checkState !== EnterpriseAuthedStatus) {
            list.push({
                name: "企业认证",
                tag: "进行中",
                tagColor: "processing",
                description: "提交相关企业信息进行首次认证",
                btn: null
            })
        } else if (!sdwanState) {
            list.push({
                name: "购买线路",
                tag: "进行中",
                tagColor: "processing",
                description: "根据您的业务需求选购匹配的线路及带宽",
                btn: <Button type="primary" size="mini" onClick={() => history.push("/purchase")}>前往购买</Button>
            }) 
        } else {
            list.push({
                name: "连接加速盒",
                tag: "进行中",
                tagColor: "processing",
                description: "参考下方快速接入步骤使用星鹿加速盒",
                btn: null
            }) 
        }
        setTodoList([...list]);
        // eslint-disable-next-line
    }, [checkState, sdwanState])
    /* 待办事项 -- start */

    /* 新闻公告 -- start */
    const [newsList, setNewsList] = useState([]);
    const [newsOpen, setNewsOpen] = useState(false);
    const [newsData, setNewsData] = useState(null);
    const handleNewsOpen = (open) => {
        setNewsOpen(open);
    }
    const onShowNews = (data) => {
        setNewsData({...data});
        setNewsOpen(true);
    }
    const getNewsData = () => {
        getNewsList().then(response => {
            if (response.code === 200) {
                setNewsList(response.data);
            }
        }).catch(() => {})
    }
    /* 新闻公告 -- end */

    /* 接入步骤 -- start */
    const [current, setCurrent] = useState(null);
    const [currVideoSrc, setCurrVideoSrc] = useState(null);
    useEffect(() => {
        setCurrent(stepList[0]);
        setCurrVideoSrc("https://player.bilibili.com/player.html?bvid=BV1AuYseSEEG");
    }, [])
    const changeStep = (val) => {
        setCurrent(val);
    }
    /* 接入步骤 -- end */
    return <>
        <Container>
            <TodoWrap>
                <div className="panel-header">
                    <div className="panel-header-title">
                        <img className="panel-header-icon" src={todoIcon} alt="pic" />
                        <span className="panel-header-name">待办事项</span>
                    </div>
                    <div className="panel-header-actions"></div>
                </div>
                <div className="todo-list">
                    {
                        todoList.map((item, index) => {
                            return <div className="todo-item" key={index}>
                                <div className="todo-item-info">
                                    <div className="todo-item-title">
                                        <span>{item.name}</span>
                                        <Tag className="todo-item-tag account-state-tag" color={item.tagColor}>{item.tag}</Tag>
                                    </div>
                                    <div className="todo-item-desc">{item.description}</div>
                                </div>
                                {
                                    item.btn ? <div className="todo-item-actions">{item.btn}</div> : null
                                }
                            </div>
                        })
                    }
                    {/* <div className="todo-list-tip"><PraiseIcon className="icon-svg" />目前暂无待办事项......</div> */}
                </div>
            </TodoWrap>
            <NewsWrap>
                <div className="panel-header">
                    <div className="panel-header-title">
                        <img className="panel-header-icon" src={newsIcon} alt="pic" />
                        <span className="panel-header-name">新闻公告</span>
                    </div>
                    <div className="panel-header-actions">
                        {/* <Button type="link">查看更多</Button> */}
                    </div>
                </div>
                <div className="news-list">
                    {
                        newsList.map((item) => {
                            return <div
                                className="news-item"
                                key={item.id}
                                onClick={() => onShowNews(item)}
                            >
                                <div className="news-item-dot"></div>
                                {
                                    NewsTypeOptions[item.type] ? <div
                                        className="news-item-tag"
                                        style={{ background: NewsTypeOptions[item.type].background, color: NewsTypeOptions[item.type].color }}
                                    >{NewsTypeOptions[item.type].label}</div> : null
                                }
                                <div className="news-item-name">
                                    {item.title}
                                </div>
                                <div className="news-item-bottom">
                                    <div className="news-item-date">{item.releaseTime}</div>
                                    { item.sticky ? <div className="news-item-sticky">置顶</div> : <div className="news-item-sticky2"></div> }
                                </div>
                            </div>
                        })
                    }
                </div>
            </NewsWrap>
            <StepsWrap>
                <div className="panel-header">
                    <div className="panel-header-title">
                        <img className="panel-header-icon" src={stepsIcon} alt="pic" />
                        <span className="panel-header-name">快速接入步骤</span>
                    </div>
                    <div className="panel-header-actions"></div>
                </div>
                <div className="steps-wrap">
                    <div className="steps-list">
                        {
                            stepList.map(item => {
                                return <div className={`steps-item${current&&current.key===item.key?" steps-item-active":""}`} key={item.key} onClick={() => changeStep(item)}>
                                    <img className="steps-item-icon" src={item.iconSrc} alt="pic" />
                                    <div className="steps-item-key">{item.key}</div>
                                    <div className="steps-item-title">{item.title}</div>
                                </div>
                            })
                        }
                    </div>
                    <div className="steps-video">
                        <div className="steps-video-title">星鹿跨境电商加速盒连接教程</div>
                        <div className="steps-video-box">
                            {
                                currVideoSrc ? <iframe
                                    width="100%"
                                    height="400"
                                    title="星鹿跨境电商加速盒连接教程"
                                    src={currVideoSrc}
                                    allowFullScreen
                                    frameBorder="0"
                                /> : null
                            }
                        </div>
                    </div>
                </div>
            </StepsWrap>
        </Container>
        <NewsDetailModal open={newsOpen} data={newsData} onChangeOpen={handleNewsOpen} />
    </>
}


// 映射 Redux 全局的 state 到组件的 props 上
const mapStateToProps = (state) => ({
    checkState: state.getIn(["user", "checkState"]),
    sdwanState: state.getIn(["user", "sdwanState"]),
});

// 映射 dispatch 到 props 上
const mapDispatchToProps = (dispatch) => {
    return {
        userInfoDispatch: () => dispatch(userInfo()),
    }
};

// 将 ui 组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Overview));