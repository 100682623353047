import styled from "styled-components";

export const Container = styled.div`
    overflow: hidden;
    .ant-spin-nested-loading > div > .ant-spin {
        max-height: none;
    }
    .ant-spin-container {
        padding: 16px;
        min-height: calc(100vh - 70px);
        display: flex;
        position: relative;
    }
    .order-list-container {
	    min-height: 378px;
        padding: 30px 24px;
        .order-title {
            margin-bottom: 30px;
        }
        .order-item {
            margin-top: 20px;
        }
    }
    &.order-submit-container {
        .order-total-wrap {
            margin: 20px 0;
        }
    }
    .order-pay-item {
        margin-bottom: 20px;
    }
    .summary-total {
        padding-top: 14px;
        border-top: 1px solid #EBEEF5;
        font-size: 16px;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .summary-total-num {
            font-family: "msyhbd";
            margin-left: 6px;
        }
        .summary-total-num-error {
            color: #F56C6C;
        }
    }
    @media (max-width: 768px) {
        .ant-spin-container {
            padding: 0;
            min-height: calc(100vh - 56px);
        }
        .order-list-container {
            min-height: auto;
            padding: 16px 12px;
        }
    }
`

export const LeftWrap = styled.div`
    flex: 1;
    height: calc(100vh - 102px);
    /* min-width: 894px; */
    min-height: 840px;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px 0px rgba(190,206,254,0.35);
    border-radius: 10px;
    padding: 20px 32px;
    display: flex;
    flex-direction: column;
    position: relative;
    .page-title {
        font-size: 22px;
        color: #4073FF;
        margin-bottom: 10px;
    }
    .static-header {
        border-bottom: 1px solid #EBEEF5;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
        .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
            padding: 6px 0 10px 0;
        }
        .ant-radio-button-wrapper:first-child {
            border-radius: 4px 0 0 4px;
        }
        .ant-radio-button-wrapper:last-child {
            border-radius: 0 4px 4px 0;
        }
        .ant-radio-button-wrapper:first-child:last-child {
            border-radius: 4px;
        }
        .ant-radio-button-wrapper {
            min-width: 80px;
            font-size: 14px;
            text-align: center;
        }
        .static-duration-item {
            position: relative;
            .static-duration-hot {
                position: absolute;
                right: 0;
                top: -16px;
                width: 24px;
                height: 24px;
            }
        }
        .ant-tabs-top > .ant-tabs-nav {
            margin: 0;
        }
        .static-header-left {
            margin-bottom: 16px;
            margin-right: 16px;
            .ant-radio-button-wrapper {
                min-width: 100px;
            }
        }
        .static-header-right {
            margin-bottom: 16px;
        }
    }
    .static-regions-wrap {
        flex: 1;
        width: 100%;
        /* height: calc(100vh - 340px); */
        /* margin-top: 20px; */
        padding-top: 10px;
        overflow-y: auto;
    }
    .pay-submit-btn {
        position: fixed;
        width: 100%;
        height: 80px;
        padding: 16px;
        background-color: #fff;
        bottom: 0;
        left: 0;
        .ant-btn {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        height: fit-content;
        min-height: auto;
        box-shadow: none;
        border-radius: 0;
        padding: 16px 16px 80px 16px;
        .page-title {
            font-family: "msyhbd";
            font-size: 16px;
            margin-bottom: 16px;
        }
        .static-header {
            .ant-radio-button-wrapper {
                min-width: 50px;
                height: 36px;
                padding: 0 4px;
                line-height: 34px;
                font-size: 12px;
                text-align: center;
            }
            .static-duration-item {
                position: relative;
                .static-duration-hot {
                    position: absolute;
                    right: 0;
                    top: -12px;
                    width: 20px;
                    height: 20px;
                }
            }
            .ant-tabs-tab + .ant-tabs-tab {
                padding: 0;
            }
            .static-header-left {
                width: 100%;
                margin-right: 0;
                .ant-radio-button-wrapper {
                    min-width: 124px;
                }
            }
        }
        .static-regions-wrap {
            flex: auto;
            padding-top: 0;
        }
    }
`

export const RightWrap = styled.div`
    width: 420px;
    margin-left: 10px;
    @media (max-width: 1408px) {
        width: 0;
        margin-left: 0;
    }
`

export const RegionWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    .region-item {
        width: 260px;
        padding: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        /* background: #FAFBFF; */
        border-radius: 4px;
        border: 1px solid #EBEEF5;
        box-sizing: border-box;
        cursor: pointer;
        .region-item-top {
            width: 100%;
            display: flex;
            align-items: center;
            .region-item-flag {
                flex-shrink: 0;
                width: 24px;
                height: 24px;
            }
            .region-item-name {
                flex: 1;
                font-size: 16px;
                color: #202226;
                margin: 0 6px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .region-item-price {
                flex-shrink: 0;
                color: #fa941b;
            }
        }
        .region-item-input {
            width: 100%;
            height: 50px;
            padding: 0 12px;
            border-radius: 4px;
            border: 1px solid #EBEEF5;
            display: flex;
            align-items: center;
            margin-top: 12px;
            .region-item-input-btn {
                width: 30px;
                height: 30px;
                padding: 0;
                font-size: 12px;
                text-align: center;
                line-height: 24px;
            }
            .ant-input-number {
                flex: 1;
            }
            .ant-input-number-input {
                text-align: center;
            }
            .ant-input-number-lg input {
                height: 30px;
            }
        }
        &:hover, &.active {
            padding: 19px;
            background: #FFFFFF;
            box-shadow: 0px 1px 15px 1px rgba(115,151,251,0.35);
            border: 2px solid #4073FF;
        }
    }
    @media (max-width: 768px) {
        .region-item {
            width: 48%;
            padding: 12px;
            margin-right: 4%;
            margin-bottom: 12px;
            &:nth-child(even) {
                margin-right: 0;
            }
            .region-item-top {
                .region-item-flag {
                    width: 20px;
                    height: 20px;
                }
                .region-item-name {
                    font-size: 12px;
                    margin: 0 4px;
                }
                .region-item-price {
                    font-size: 12px;
                }
            }
            .region-item-input {
                height: 28px;
                padding: 0 8px;
                margin-top: 8px;
                .region-item-input-btn {
                    width: 20px;
                    height: 20px;
                    line-height: 14px;
                }
                .ant-input-number-lg input {
                    height: 20px;
                }
            }
            &:hover, &.active {
                padding: 11px;
            }
        }
    }
`

export const RegionSummaryWrap = styled.div`
    max-height: 250px;
    padding-right: 4px;
    overflow-y: auto;
    .summary-item {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .summary-left {
            display: flex;
            align-items: center;
            .summary-flag {
                flex-shrink: 0;
                width: 32px;
                height: 32px;
            }
            .summary-info {
                flex: 1;
                margin-left: 4px;
                .summary-region {
                    font-size: 14px;
                    color: #333333;
                }
                .summary-price {
                    font-size: 14px;
                    color: #7B808C;
                }
            }
        }
        .summary-input {
            flex-shrink: 0;
            .ant-input-number {
                width: 46px;
            }
        }
    }
    @media (max-width: 768px) {
        max-height: none;
        .summary-item {
            margin-bottom: 16px;
            .summary-left {
                display: flex;
                align-items: center;
                .summary-flag {
                    width: 24px;
                    height: 24px;
                }
            }
            .summary-input {
                .ant-input-number {
                    width: 36px;
                }
            }
        }
    }
`