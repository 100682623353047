import React, { useEffect, useState, useImperativeHandle } from "react";
import { Button, Modal, message } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { PayPollingInterval } from "@/utils/options";
import { checkPayStatus } from "@/api/request";

let timer = null;
let checkInterval = null;

function CheckPayModal(props) {
    const { onSuccess } = props;

    const [open, setOpen] = useState(false);
    const [checking, setChecking] = useState(false);

    const clearTimer = () => {
        if (timer) {
            clearTimeout(timer);
        }
        if (checkInterval) {
            clearInterval(checkInterval);
        }
    }

    useEffect(() => {
        return () => {
            clearTimer();
        }
        // eslint-disable-next-line
    }, [])

    useImperativeHandle(props.onRef, () => {
        return {
            show: (data) => {
                if (!data) {
                    message.error("参数不足");
                    return;
                }
                if (!data.orderNo) {
                    message.error("订单号不能为空");
                    return;
                }
                if (!data.expire) {
                    message.error("订单有效时长不能为空");
                    return;
                }
                beginCheck(data.orderNo, data.expire);
            }
        }
    })

    const hideModal = () => {
        clearTimer();
        setOpen(false);
    }

    const beginCheck = (orderNo, expire) => {
        setOpen(true);
        setChecking(true);

        checkInterval = setInterval(function() {
            checkStatus(orderNo);
        }, PayPollingInterval)

        timer = setTimeout(() => {
            // 订单倒计时结束，当前订单未支付
            clearTimer();
            setChecking(false);
        }, expire)
    }

    const checkStatus = (orderNo) => {
        checkPayStatus(orderNo).then(response => {
            if (response.code === 200) {
                if (response.data) {
                    // 支付成功
                    hideModal();
                    onSuccess();
                }
            } else {
                clearTimer();
            }
        }).catch(() => {
            clearTimer();
        })
    }

    const doPaySuccess = () => {
        hideModal();
        onSuccess();
    }

    return <Modal
        title="支付"
        open={open}
        footer={null}
        width={560}
        maskClosable={false}
        onCancel={hideModal}
        className="custom-modal-container checkPay-modal-container"
    >
        <div className="checkPay-modal-content">
            {
                checking ? <>
                    <div className="checkPay-modal-main">
                        <div className="checkPay-modal-title">
                            <CheckCircleOutlined className="checkPay-modal-icon" />
                            <span>请在新打开页面进行支付。</span>
                        </div>
                        <div className="checkPay-modal-desc">支付完成后，根据您的情况点击下面按钮。</div>
                    </div>
                    <div className="checkPay-modal-footer">
                        <Button onClick={hideModal}>支付失败</Button>
                        <Button type="primary" onClick={doPaySuccess}>支付成功</Button>
                    </div>
                </> : <>
                    <div className="checkNoPay-modal-main">
                        <div className="checkNoPay-modal-title">
                            <CloseCircleOutlined className="checkNoPay-modal-icon" />
                            <span>未支付</span>
                        </div>
                        <div className="checkNoPay-modal-desc">您的订单已超时，请返回页面重新发起支付。</div>
                    </div>
                    <div className="checkPay-modal-footer">
                        <Button type="primary" danger onClick={hideModal}>返回</Button>
                    </div>
                </>
            }
        </div>
    </Modal>
};

export default React.memo(CheckPayModal);