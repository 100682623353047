import React, { useEffect, useState, useCallback, useRef } from "react";
import { Button, Table, message } from "antd";
import { connect } from "react-redux";
import { userAddress } from "@/store/modules/user/actionCreators";
import { updateUserAddress } from "@/api/request";
import UpdateAddress from "./components/UpdateAddress";
import DeleteAddrModal from "./components/DeleteAddrModal";

function AccountAddress(props) {
    const { device, addressList, userAddressDispatch } = props;
    const addressListJS = addressList ? addressList.toJS() : [];
    const tableRef = useRef(null);

    useEffect(() => {
        userAddressDispatch();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.scrollTo(0, 0);
        }
    }, [addressList])

    /* 收货信息 -- start */
    const [addrLoading, setAddrLoading] = useState(false);
    const columns = [
        {
            title: "收货人",
            dataIndex: "name",
            key: "name",
            align: "center",
            width: 150,
            ellipsis: true,
        },
        {
            title: "所在地区",
            dataIndex: "province",
            key: "province",
            align: "center",
            width: 200,
            ellipsis: true,
            render: (_, { province, city, area }) => {
                return <span>{province}{city}{area}</span>
            }
        },
        {
            title: "详细地址",
            dataIndex: "detail",
            key: "detail",
            align: "center",
            width: 200,
            ellipsis: true,
        },
        {
            title: "手机号码",
            dataIndex: "phone",
            key: "phone",
            align: "center",
            width: 140,
            ellipsis: true,
        },
        {
            title: "操作",
            dataIndex: "actions",
            key: "actions",
            align: "center",
            width: 200,
            render: (_, row) => {
                return <div className="table-addr-actions">
                    <Button type="link" onClick={() => changeAddr(row)}>修改</Button>
                    <Button type="link" disabled={row.isDefault} onClick={() => deleteAddr(row)}>删除</Button>
                    { row.isDefault ? <div className="table-addr-default">默认</div> : <Button type="link" onClick={() => changeDefaultAddr(row)}>设为默认</Button> }
                </div>
            }
        },
    ]
    const [addrOpen, setAddrOpen] = useState(false);
    const [addrData, setAddrData] = useState(null);
    const handleChangeAddrOpen = useCallback((open, refresh) => {
        setAddrOpen(open);
        if (refresh) {
            userAddressDispatch();
        }
        // eslint-disable-next-line
    }, [])
    const changeAddr = (row) => {
        setAddrData({...row});
        setAddrOpen(true);
    }
    const [delAddrOpen, setDelAddrOpen] = useState(false);
    const [delAddrData, setDelAddrData] = useState(null);
    const handleChangeDelAddrOpen = useCallback((open, refresh) => {
        setDelAddrOpen(open);
        if (refresh) {
            userAddressDispatch();
        }
        // eslint-disable-next-line
    }, [])
    const deleteAddr = (row) => {
        setDelAddrData({ ...row });
        setDelAddrOpen(true);
    }
    const changeDefaultAddr = (row) => {
        setAddrLoading(true);
        updateUserAddress({ id: row.id, isDefault: true, area: row.area }).then(res => {
            setAddrLoading(false);
            if (res.code === 200) {
                message.success("设置成功");
                userAddressDispatch();
            }
        }).catch(() => {
            setAddrLoading(false);
        })
    }
    /* 收货信息 -- end */

    return <>
        {
            device !== "mobile" ? <div className="table-main-wrap">
                <Table
                    ref={tableRef}
                    columns={columns}
                    dataSource={addressListJS}
                    rowKey="id"
                    loading={addrLoading}
                    pagination={{ position: ["none", "none"] }}
                    scroll={{
                        y: 400
                    }}
                />
            </div> : <div className="addr-mobile-wrap">
                {
                    addressListJS.map((item, index) => {
                        return <div key={item.id} className="addr-mobile-item">
                            <div className="addr-mobile-item-header">
                                <div>收货信息{index+1}</div>
                                { item.isDefault ? <div className="addr-mobile-item-default">默认</div> : null }
                            </div>
                            <div className="addr-mobile-item-label">收件人</div>
                            <div className="addr-mobile-item-value">{item.name}</div>
                            <div className="addr-mobile-item-label">所在地区</div>
                            <div className="addr-mobile-item-value">{item.province}{item.city}{item.area}</div>
                            <div className="addr-mobile-item-label">详细地址</div>
                            <div className="addr-mobile-item-value">{item.detail}</div>
                            <div className="addr-mobile-item-label">手机号</div>
                            <div className="addr-mobile-item-value">{item.phone}</div>
                            <div className="addr-mobile-item-footer">
                                <Button type="link" onClick={() => changeAddr(item)}>修改</Button>
                                <Button type="text" disabled={item.isDefault} onClick={() => deleteAddr(item)}>删除</Button>
                                { !item.isDefault ? <Button type="link" onClick={() => changeDefaultAddr(item)}>设为默认</Button> :null }
                            </div>
                        </div>
                    })
                }
            </div>
        }
        <UpdateAddress open={addrOpen} data={addrData} onChangeOpen={handleChangeAddrOpen} />
        <DeleteAddrModal open={delAddrOpen} data={delAddrData} onChangeOpen={handleChangeDelAddrOpen} />
    </>
}
// 映射 Redux 全局的 state 到组件的 props 上
const mapStateToProps = (state) => ({
    device: state.getIn(["app", "device"]),
    addressList: state.getIn(["user", "addressList"]),
});

// 映射 dispatch 到 props 上
const mapDispatchToProps = (dispatch) => {
    return {
        userAddressDispatch: () => dispatch(userAddress()),
    }
};

// 将 ui 组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AccountAddress));