import * as actionTypes from "./constants";
import { fromJS } from "immutable";
import { getToken, setToken, removeToken } from "@/utils/auth";

const defaultState = fromJS({
    token: getToken(),
    phone: "",
    username: "",
    email: "",
    userId: "",
    balance: 0,
    checkState: 1, // 企业认证状态
    sdwanState: false, // 购买线路状态
    createTime: "",
    addressList: [],
    defaultAddress: null
})

const reducer = ((state = defaultState, action) => {
    switch(action.type) {
        case actionTypes.SET_TOKEN:
            if (!action.data) {
                removeToken();
            } else {
                setToken(action.data);
            }
            return state.set("token", action.data);
        case actionTypes.SET_USER_PHONE:
            return state.set("phone", action.data);
        case actionTypes.SET_USER_NAME:
            return state.set("username", action.data);
        case actionTypes.SET_USER_EMAIL:
            return state.set("email", action.data);
        case actionTypes.SET_USER_ID:
            return state.set("userId", action.data);
        case actionTypes.SET_USER_BALANCE:
            return state.set("balance", action.data);
        case actionTypes.SET_USER_CHECK_STATE:
            return state.set("checkState", action.data);
        case actionTypes.SET_USER_SDWAN_STATE:
            return state.set("sdwanState", action.data);
        case actionTypes.SET_USER_CREATE_TIME:
            return state.set("createTime", action.data);
        case actionTypes.SET_USER_ADDRESS_LIST:
            return state.set("addressList", action.data);
        case actionTypes.SET_USER_DEFAULT_ADDRESS:
            return state.set("defaultAddress", action.data);
        default:
            return state;
    }
})

export default reducer;