function mapToList(mData) {
    const list = [];
    mData.forEach(item => {
        list.push(item);
    })
    return list;
}

export const SuccessColor = "#52c41a";
export const SuccessBackgroundColor = "#f6ffed";
export const ProcessingColor = "#1890ff";
export const ProcessingBackgroundColor = "#e6f7ff";
export const WarningColor = "#faad14";
export const WarningBackgroundColor = "#fffbe6";
export const ErrorColor = "#ff4d4f";
export const ErrorBackgroundColor = "#fff2f0";
export const DefaultColor = "#d9d9d9";
export const DefaultBackgroundColor = "#fafafa";

// 宽带规格
export const MaxBandwidth = 100;
// 单次购买线路数量
export const MinSdwanQuantity = 1;
export const MaxSdwanQuantity = 100;
// 宽带升级-终端数量
export const MinTerminals = 1;
export const MaxTerminals = 100;
// 价格
export const DefaultPrice = "-";
// 默认带宽步长
export const DefaultBandwidthStep = 10;

// 支付状态轮询间隔时间
export const PayPollingInterval = 5000;

// 通用启用/禁用状态
export const EnabledStatus = 1;
export const DisabledStatus = 2;

// 线路类型
export const SdwanLiveType = 1;
export const SdwanOperationsType = 2;

const sdwanTypeMap = new Map();
sdwanTypeMap.set(SdwanLiveType, {
    label: "直播",
    value: SdwanLiveType,
    color: SuccessColor,
    background: SuccessBackgroundColor,
    bandwidthStep: 5,
    bandwidthTip: "为确保带宽配置的灵活与高效，直播线路调整带宽规格时需按5的倍数增减。"
})
sdwanTypeMap.set(SdwanOperationsType, {
    label: "运营",
    value: SdwanOperationsType,
    color: ProcessingColor,
    background: ProcessingBackgroundColor,
    bandwidthStep: 10,
    bandwidthTip: "为确保带宽配置的灵活与高效，运营线路调整带宽规格时需按10的倍数增减。"
})
export const SdwanTypeMap = sdwanTypeMap;
export const SdwanTypeList = mapToList(sdwanTypeMap);

// 线路规格
export const MajorSdwanStandard = 1 // 专业跨境专线
export const NormalSdwanStandard = 2 // 普通跨境专线

const sdwanStandardMap = new Map();
sdwanStandardMap.set(MajorSdwanStandard, {
    label: "专业跨境专线",
    value: MajorSdwanStandard
})
sdwanStandardMap.set(NormalSdwanStandard, {
    label: "普通跨境专线",
    value: NormalSdwanStandard
})
export const SdwanStandardMap = sdwanStandardMap;
export const SdwanStandardList = mapToList(sdwanStandardMap);

// 线路状态
export const SdwanNormalStatus = 1;
export const SdwanExpiredStatus = 2;

const sdwanStatusMap = new Map();
sdwanStatusMap.set(SdwanNormalStatus, {
    label: "正常",
    value: SdwanNormalStatus,
    color: SuccessColor,
    background: SuccessBackgroundColor
})
sdwanStatusMap.set(SdwanExpiredStatus, {
    label: "过期",
    value: SdwanExpiredStatus,
    color: WarningColor,
    background: WarningBackgroundColor
})
export const SdwanStatusMap = sdwanStatusMap;
export const SdwanStatusList = mapToList(sdwanStatusMap);

// IP类型
export const ResidentialProxies = 1;
export const StaticResidentialProxies = 6;
export const DedicatedDatacenterProxies = 5;

const proxyMap = new Map();
proxyMap.set(StaticResidentialProxies, {
    label: "静态住宅",
    value: StaticResidentialProxies
})
export const ProxiesTypeMap = proxyMap;
export const ProxiesTypeList = mapToList(proxyMap);

// 静态住宅管理-IP类型
export const NormalStaticIPProxies = 0;
export const NativeStaticIPProxies = 1;

const staticIPTypeMap = new Map();
staticIPTypeMap.set(NormalStaticIPProxies, {
    label: "住宅IP",
    value: NormalStaticIPProxies
})
staticIPTypeMap.set(NativeStaticIPProxies, {
    label: "原生IP",
    value: NativeStaticIPProxies
})
export const StaticIPTypeMap = staticIPTypeMap;
export const StaticIPTypeList = mapToList(staticIPTypeMap);

// 加速盒购买类型
export const PurchaseDepositType = 1;
export const PurchaseDirectlyType = 2;

const purchaseMap = new Map();
purchaseMap.set(PurchaseDirectlyType, {
    label: "购买",
    value: PurchaseDirectlyType
})
purchaseMap.set(PurchaseDepositType, {
    label: "押金",
    value: PurchaseDepositType
})
export const PurchaseTypeMap = purchaseMap;
export const PurchaseTypeList = mapToList(purchaseMap);

// 支付方式
export const BalancePayID = 1;
export const AliPayID = 2;
export const WechatPayID = 3;
export const CompanyPayID = 4;
export const OtherPayID = 9;

const payMap = new Map();
payMap.set(BalancePayID, {
    label: "余额",
    value: BalancePayID
})
payMap.set(AliPayID, {
    label: "支付宝",
    value: AliPayID
})
payMap.set(WechatPayID, {
    label: "微信",
    value: WechatPayID
})
payMap.set(CompanyPayID, {
    label: "对公支付",
    value: CompanyPayID
})
payMap.set(OtherPayID, {
    label: "定制",
    value: OtherPayID
})
export const PayTypeMap = payMap;
export const PayTypeList = mapToList(payMap);

// 移动端orPC端
export const PcAccessType = 1; // pc
export const MobileAccessType = 2; // h5

// 订单类型
export const OrderBuySdwanType = 1; // 线路购买
export const OrderRenewSdwanType = 2; // 线路续费
export const OrderUpgradeSdwanType = 3; // 带宽升级
export const OrderRechargeType = 4; // 余额充值
export const OrderUseWalletType = 5; // 余额消费
export const OrderBuyStaticIPType = 6; // 购买IP
export const OrderRenewStaticIPType = 7; // IP续费

const orderTypeMap = new Map();
orderTypeMap.set(OrderBuySdwanType, {
    label: "线路购买",
    value: OrderBuySdwanType
})
orderTypeMap.set(OrderRenewSdwanType, {
    label: "线路续费",
    value: OrderRenewSdwanType
})
orderTypeMap.set(OrderUpgradeSdwanType, {
    label: "带宽升级",
    value: OrderUpgradeSdwanType
})
orderTypeMap.set(OrderRechargeType, {
    label: "余额充值",
    value: OrderRechargeType
})
orderTypeMap.set(OrderUseWalletType, {
    label: "余额消费",
    value: OrderUseWalletType
})
orderTypeMap.set(OrderBuyStaticIPType, {
    label: "购买IP",
    value: OrderBuyStaticIPType
})
orderTypeMap.set(OrderRenewStaticIPType, {
    label: "IP续费",
    value: OrderRenewStaticIPType
})
export const OrderTypeMap = orderTypeMap;
// 隐藏余额消费
const orderTypeMap2 = new Map(orderTypeMap);
orderTypeMap2.delete(OrderUseWalletType);
export const OrderTypeList = mapToList(orderTypeMap2);

// 支付状态
export const ToBePaidStatus = 0; // 待支付
export const PaySuccessfulStatus = 1; // 支付成功
export const PayFailedStatus = 2; // 支付失败
export const RefundedStatus = 3; // 已退款

const payStatusMap = new Map();
payStatusMap.set(ToBePaidStatus, {
    label: "待支付",
    value: ToBePaidStatus
})
payStatusMap.set(PaySuccessfulStatus, {
    label: "支付成功",
    value: PaySuccessfulStatus
})
payStatusMap.set(PayFailedStatus, {
    label: "支付失败",
    value: PayFailedStatus
})
payStatusMap.set(RefundedStatus, {
    label: "已退款",
    value: RefundedStatus
})
export const PayStatusMap = payStatusMap;
export const payStatusList = mapToList(payStatusMap);

// 发货状态
export const AllocatedNoPaidStatus = 0; // 待支付
export const AllocatedGoingStatus = 1; // 发货中
export const AllocatedSuccessStatus = 2; // 发货成功
export const AllocatedFailedStatus = 3; // 发货失败

const allocatedMap = new Map();
allocatedMap.set(AllocatedNoPaidStatus, {
    label: "待支付",
    value: AllocatedNoPaidStatus
})
allocatedMap.set(AllocatedGoingStatus, {
    label: "发货中",
    value: AllocatedGoingStatus
})
allocatedMap.set(AllocatedSuccessStatus, {
    label: "发货成功",
    value: AllocatedSuccessStatus
})
allocatedMap.set(AllocatedFailedStatus, {
    label: "发货失败",
    value: AllocatedFailedStatus
})
export const AllocatedStatusMap = allocatedMap;
export const AllocatedStatusList = mapToList(allocatedMap);

// 订单状态: 综合支付状态和发货状态
export const getOrderStatus = (payState, deliverState) => {
    if (payState !== PaySuccessfulStatus) {
        return {
            label: "待支付",
            color: WarningColor
        }
    } else if (payState === PaySuccessfulStatus && deliverState === AllocatedSuccessStatus) {
        return {
            label: "已发货",
            color: SuccessColor
        }
    } else {
        return {
            label: "发货中",
            color: ProcessingColor
        }
    }
}

// 【对公支付】审批状态
export const InreviewApprovalStatus = 1
export const FailedApprovalStatus = 2
export const PassedApprovalStatus = 3
export const NotSubmitApprovalStatus = 4

const payApprovalStatusMap = new Map();
payApprovalStatusMap.set(InreviewApprovalStatus, {
    label: "审核中",
    value: InreviewApprovalStatus,
    color: ProcessingColor
})
payApprovalStatusMap.set(FailedApprovalStatus, {
    label: "审核未通过",
    value: FailedApprovalStatus,
    color: ErrorColor
})
payApprovalStatusMap.set(PassedApprovalStatus, {
    label: "审核通过",
    value: PassedApprovalStatus,
    color: SuccessColor
})
payApprovalStatusMap.set(NotSubmitApprovalStatus, {
    label: "待处理",
    value: NotSubmitApprovalStatus,
    color: WarningColor
})
export const PayApprovalStatusMap = payApprovalStatusMap;
export const PayApprovalStatusList = mapToList(payApprovalStatusMap);

// 设备状态
export const DeviceNormalStatus = 1;
export const DeviceOfflineStatus = 2;

const deviceStatusMap = new Map();
deviceStatusMap.set(DeviceNormalStatus, {
    label: "正常",
    value: DeviceNormalStatus,
    color: SuccessColor
})
deviceStatusMap.set(DeviceOfflineStatus, {
    label: "离线",
    value: DeviceOfflineStatus,
    color: DefaultColor
})
export const DeviceStatusMap = deviceStatusMap;
export const DeviceStatusList = mapToList(DeviceStatusMap);

// 设备代理模式
export const DeviceDefaultAgencyMode = 0;
export const DeviceShuntingAgencyMode = 1;
export const DeviceGlobalAgencyMode = 2;
export const DeviceLocalNetworkMode = 3;

const deviceAgencyModeMap = new Map();
// deviceAgencyModeMap.set(DeviceDefaultAgencyMode, {
//     label: "默认",
//     value: DeviceDefaultAgencyMode
// })
deviceAgencyModeMap.set(DeviceShuntingAgencyMode, {
    label: "分流模式",
    value: DeviceShuntingAgencyMode,
    tip: "国内网站走国内网络，除国内其他网站走当前线路IP",
    color: "#FFB03F"
})
deviceAgencyModeMap.set(DeviceGlobalAgencyMode, {
    label: "全局模式",
    value: DeviceGlobalAgencyMode,
    tip: "所有的网络请求都以当前线路IP访问",
    color: "#467CFD"
})
// deviceAgencyModeMap.set(DeviceLocalNetworkMode, {
//     label: "本地模式",
//     value: DeviceLocalNetworkMode,
//     tip: "所有的流量都直接通过本地网络访问，不使用线路IP代理服务",
//     color: "#31B99A"
// })
export const DeviceAgencyModeMap = deviceAgencyModeMap;
export const DeviceAgencyModeList = mapToList(deviceAgencyModeMap);

// 设备--批量[取消]忽略
export const Black1white0Type = 0; // 黑-能上网 白-不能上网 取消忽略
export const Black0white0Type = 1; // 黑-不能上网 白-不能上网 忽略/移出白名单
export const Black1white1Type = 2; // 黑-能上网 白-能上网 加入白名单

export const AllTerminalShow = 99; // 全部
export const OnlyIgnoredShow = 1; // 只显示已忽略设备
export const OnlyNotIgnoredShow = 2; // 只显示未忽略设备
export const OnlyNotWhiteShow = 3; // 只显示非白名单设备
export const OnlyWhiteShow = 4; // 只显示白名单设备

export const BlackDeviceTypeList = [{
    label: "显示全部设备",
    value: AllTerminalShow
}, {
    label: "只显示已忽略设备",
    value: OnlyIgnoredShow
}, {
    label: "只显示未忽略设备", // 默认展示
    value: OnlyNotIgnoredShow
}]

export const WhiteDeviceTypeList = [{
    label: "显示全部设备", // 默认展示
    value: AllTerminalShow
}, {
    label: "只显示非白名单设备",
    value: OnlyNotWhiteShow
}, {
    label: "只显示白名单设备",
    value: OnlyWhiteShow
}]

export const OtherIgnoreType = 0;
export const AllIgnoredType = 1; // 全是已忽略设备
export const AllNotIgnoredType = 2; // 全是未忽略设备
export const AllNotWhiteListType = 3; // 全是未加入白名单设备
export const AllWhiteListType = 4; // 全是已加入白名单设备

const ignoreTypeMap = new Map();
ignoreTypeMap.set(OtherIgnoreType, {
    label: "忽略设备/加入白名单",
    tip1: "",
    tip2: "",
    value: OtherIgnoreType
})
ignoreTypeMap.set(AllIgnoredType, {
    label: "取消忽略",
    tip1: "将取消忽略，如需查看所有设备请勾选列表上方的显示全部设备",
    tip2: "取消忽略后设备将可以继续分配出口IP使用，确认是否取消忽略",
    value: AllIgnoredType
})
ignoreTypeMap.set(AllNotIgnoredType, {
    label: "忽略设备",
    tip1: "将被忽略，如需查看所有设备请勾选列表上方的显示全部设备",
    tip2: "忽略设备后将会断开当前网络，请确认是否忽略",
    value: AllNotIgnoredType
})
ignoreTypeMap.set(AllNotWhiteListType, {
    label: "加入白名单",
    tip1: "将被加入白名单，设备将允许访问网络",
    tip2: "",
    value: AllNotWhiteListType
})
ignoreTypeMap.set(AllWhiteListType, {
    label: "移出白名单",
    tip1: "将被移出白名单，设备将无法访问网络",
    tip2: "",
    value: AllWhiteListType
})
export const IgnoreTypeMap = ignoreTypeMap;
export const IgnoreTypeList = mapToList(ignoreTypeMap);

// 企业认证状态
export const EnterpriseNotAuthStatus = 1; // 未认证
export const EnterpriseUnderReviewStatus = 2; // 审核中
export const EnterpriseFailedStatus = 3; // 审核未通过
export const EnterpriseAuthedStatus = 4; // 已认证

export const EnterpriseStatusOptions = {
    [EnterpriseNotAuthStatus]: {
        label: "未认证",
        label2: "进行中",
        value: EnterpriseNotAuthStatus,
        color: "warning",
        color2: "processing",
        stepItems: [{
            title: "信息提交",
            description: "还未完成企业认证"
        }, {
            title: "审核信息",
            description: "暂未提交信息审核"
        }, {
            title: "审核结果",
            description: "暂未审核"
        }]
    },
    [EnterpriseUnderReviewStatus]: {
        label: "审核中",
        label2: "进行中",
        value: EnterpriseUnderReviewStatus,
        color: "processing",
        color2: "processing",
        stepItems: [{
            title: "信息提交",
            description: "已提交信息"
        }, {
            title: "审核信息",
            description: "正在审核信息中..."
        }, {
            title: "审核结果",
            description: "暂未审核"
        }]
    },
    [EnterpriseFailedStatus]: {
        label: "审核未通过",
        label2: "进行中",
        value: EnterpriseFailedStatus,
        color: "error",
        color2: "processing",
        stepItems: [{
            title: "信息提交",
            description: "已提交信息"
        }, {
            title: "审核信息",
            description: "已审核"
        }, {
            title: "审核结果",
            description: "审核不通过"
        }]
    },
    [EnterpriseAuthedStatus]: {
        label: "已认证",
        label2: "已完成",
        value: EnterpriseAuthedStatus,
        color: "success",
        color2: "success",
        stepItems: [{
            title: "信息提交",
            description: "已提交信息"
        }, {
            title: "审核信息",
            description: "已审核"
        }, {
            title: "审核结果",
            description: "审核通过"
        }]
    }
}

// 新闻公告类型
export const NewsAnnouncementType = 1; // 公告
export const NewsNoticeType = 2; // 通知
export const NewsActivityType = 3; // 活动

export const NewsTypeOptions = {
    [NewsAnnouncementType]: {
        label: "公告",
        value: NewsAnnouncementType,
        color: "#FF7203",
        background: "#FFF2E8"
    },
    [NewsNoticeType]: {
        label: "通知",
        value: NewsNoticeType,
        color: "#4583FF",
        background: "#F3F7FF"
    },
    [NewsActivityType]: {
        label: "活动",
        value: NewsActivityType,
        color: "#FF03C1",
        background: "#FFF0FB"
    },
}

// 加速盒状态
export const BoxNormalStatus = 1;
export const BoxOfflineStatus = 2;

const boxStatusMap = new Map();
boxStatusMap.set(BoxNormalStatus, {
    label: "正常",
    value: BoxNormalStatus,
    color: SuccessColor
})
boxStatusMap.set(BoxOfflineStatus, {
    label: "离线",
    value: BoxOfflineStatus,
    color: DefaultColor
})
export const BoxStatusMap = boxStatusMap;
export const BoxStatusList = mapToList(boxStatusMap);

// 出口IP状态
export const IpNormalStatus = 1;
export const IpExpiredStatus = 2;

const ipStatusMap = new Map();
ipStatusMap.set(IpNormalStatus, {
    label: "正常",
    value: IpNormalStatus,
    color: SuccessColor,
    background: SuccessBackgroundColor
})
ipStatusMap.set(IpExpiredStatus, {
    label: "过期",
    value: IpExpiredStatus,
    color: WarningColor,
    background: WarningBackgroundColor
})
export const IpStatusMap = ipStatusMap;
export const IpStatusList = mapToList(ipStatusMap);

// 出口IP类型
export const IpExternalType = 1;
export const IpExternalTypeName = "外部IP";
export const IpStaticType = 0;
export const IpStaticTypeName = "自购IP";

const ipTypeMap = new Map();
ipTypeMap.set(IpExternalType, {
    label: IpExternalTypeName,
    value: IpExternalType
})
ipTypeMap.set(IpStaticType, {
    label: IpStaticTypeName,
    value: IpStaticType
})
export const IpTypeMap = ipTypeMap;
export const IpTypeList = mapToList(ipTypeMap);

// IP分配状态
export const IpAllocatedStatus = 1;
export const IpNotAllocatedStatus = 2;

const ipAllocateStatusMap = new Map();
ipAllocateStatusMap.set(IpAllocatedStatus, {
    label: "分配",
    value: IpAllocatedStatus
})
ipAllocateStatusMap.set(IpNotAllocatedStatus, {
    label: "不分配",
    value: IpNotAllocatedStatus
})
export const IpAllocateStatusMap = ipAllocateStatusMap;
export const IpAllocateStatusList = mapToList(ipAllocateStatusMap);

// 线路黑/白名单模式
export const SdwanBlackModel = 0; // 黑名单
export const SdwanWhiteModel = 1; // 白名单

const sdwanModelMap = new Map();
sdwanModelMap.set(SdwanBlackModel, {
    label: "黑名单模式",
    value: SdwanBlackModel,
    tip: "开启黑名单后，【设备管理】中已忽略设备无法上网，未忽略的设备可以访问网络",
    color: "#409EFF"
})
sdwanModelMap.set(SdwanWhiteModel, {
    label: "白名单模式",
    value: SdwanWhiteModel,
    tip: "开启白名单后，【设备管理】中只有点击加入白名单的设备可以访问网络，其余未加入白名单设备无法上网",
    color: "#52c41a"
})
export const SdwanModelMap = sdwanModelMap;
export const SdwanModelList = mapToList(sdwanModelMap);
