import React, { useState, useEffect, useRef, useCallback } from "react";
import { Tabs, Button, Form, Input, /*Checkbox, */Statistic, message, Spin } from "antd";
// import { BulbOutlined } from "@ant-design/icons";
import { Container, MainWrap, FormWrap } from "./style";
import { connect } from "react-redux";
import { sendVerifyCode } from "@/api/request";
import { userLogin } from "@/store/modules/user/actionCreators";
import bgPic from "@/assets/png/login/bg.png";
import logoPic from "@/assets/png/login/logo.png";
import bgMPic from "@/assets/png/login/bg_m.png";
import { ReactComponent as UserIcon } from "@/assets/svg/login/user.svg";
import { ReactComponent as PasswordIcon } from "@/assets/svg/login/password.svg";
import { ReactComponent as CodeIcon } from "@/assets/svg/login/code.svg";
import ReactSimpleVerify from "@/components/ReactSimpleVerify";
import servicePic from "@/assets/png/service.png";

const { Countdown } = Statistic;
const countTime = 60 * 1000; // 倒计时60s

function Login(props) {
    const { history } = props;

    const { device, token, userLoginDispatch } = props;

    const [current, setCurrent] = useState("password");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!!token) {
            history.push("/");
        }
        // eslint-disable-next-line
    }, [])

    /* 密码登录 -- start */
    const [form] = Form.useForm();
    const onFinish = (values) => {
        const { phone, password } = values;
        toLogin({
            loginType: 1,
            phone,
            password,
            verifyCode: null
        })
    }
    /* 密码登录 -- end */

    /* 短信登录 -- start */
    const [cForm] = Form.useForm();
    /* 安全验证 -- start */
    const verifyRef = useRef(null);
    const [successVerify, setSuccessVerify] = useState(false);
    const handleVerifySuccess = useCallback(() => {
        setSuccessVerify(true);
    }, [])
    /* 安全验证 -- end */

    /* 短信验证码 -- start */
    const [verifyCodePending, setPhoneCodePending] = useState(true); // true--可获取验证码 false--倒计时中
    const [verifyCodeLoading, setPhoneCodeLoading] = useState(false);
    const refreshPhoneCode = () => {
        const phone = cForm.getFieldValue("phone");
        if (!phone) {
            message.destroy();
            message.warning("请输入您的手机号码");
            return;
        }
        if (device !== "mobile" && !successVerify) {
            message.destroy();
            message.warning("请完成安全验证");
            return;
        }
        setPhoneCodeLoading(true);
        const formData = new FormData();
        formData.append("phone", phone);
        sendVerifyCode(formData).then(response => {
            setPhoneCodeLoading(false);
            if (response.code === 200) {
                message.success("发送成功");
                setPhoneCodePending(false);
            }
        }).catch(() => {
            setPhoneCodeLoading(false);
        })
    }
    const onFinishCountdown = () => {
        setPhoneCodePending(true);
    }
    /* 短信验证码 -- end */
    const onCodeFinish = (values) => {
        const { phone, verifyCode } = values;
        toLogin({
            loginType: 2,
            phone,
            password: null,
            verifyCode,
        })
    }
    /* 短信登录 -- end */

    const toLogin = (userInfo) => {
        setLoading(true);
        userLoginDispatch(userInfo).then(response => {
            setLoading(false);
            message.success("登录成功");
            history.push("/");
        }).catch(() => {
            setLoading(false);
            reset();
        })
    }

    const reset = () => {
        setPhoneCodePending(true);
        setPhoneCodeLoading(false);
        // // 重置验证
        // if (verifyRef) {
        //     verifyRef.current.reset();
        //     setSuccessVerify(false);
        // }
    }

    const tabItems = [{
        label: "密码登录",
        key: "password",
        children: <FormWrap>
            <Form
                form={form}
                className="login-form"
                initialValues={{
                    phone: "",
                    password: "",
                    remember: false
                }}
                onFinish={onFinish}
                layout="vertical"
                size="large"
            >
                <Form.Item
                    name="phone"
                    label=""
                    rules={[
                        {
                            required: true,
                            message: "请输入11位手机号码",
                        },
                        () => ({
                            validator(_, value) {
                                if (!value || (/^1[3-9]\d{9}$/.test(value))) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("请输入11位手机号码"));
                            },
                        }),
                    ]}
                >
                    <Input prefix={<UserIcon className="site-form-item-icon" />} autoComplete="on" placeholder="请输入您的手机号码" />
                </Form.Item>
                <Form.Item
                    name="password"
                    label=""
                    rules={[
                        {
                            required: true,
                            message: "请输入密码",
                        },
                    ]}
                >
                    <Input.Password prefix={<PasswordIcon className="site-form-item-icon" />} autoComplete="on" placeholder="请输入密码" />
                </Form.Item>

                <div className="login-remember">
                    {/* <Form.Item
                        name="remember"
                        valuePropName="checked"
                    >
                        <Checkbox>记住密码</Checkbox>
                    </Form.Item> */}
                    <Button type="text" onClick={() => history.push("/findpassword")}>忘记密码？</Button>
                </div>

                <Form.Item className="form-submit-button">
                    <Button type="primary" htmlType="submit">登录</Button>
                </Form.Item>
            </Form>
        </FormWrap>
    }, {
        label: "短信登录",
        key: "code",
        children: <FormWrap>
            <Form
                form={cForm}
                className="login-form"
                initialValues={{
                    phone: "",
                    verifyCode: ""
                }}
                onFinish={onCodeFinish}
                layout="vertical"
                size="large"
            >
                <Form.Item
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: "请输入11位手机号码",
                        },
                        () => ({
                            validator(_, value) {
                                if (!value || (/^1[3-9]\d{9}$/.test(value))) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("请输入11位手机号码"));
                            },
                        }),
                    ]}
                >
                    <Input prefix={<UserIcon className="site-form-item-icon" />} autoComplete="off" placeholder="请输入您的手机号码" />
                </Form.Item>

                {
                    device !== "mobile" ? <div className="form-verify-box">
                        <ReactSimpleVerify ref={verifyRef} success={handleVerifySuccess} />
                    </div> : null
                }

                <Form.Item className="form-captcha-item">
                    <div className="form-captcha-wrap">
                        <Form.Item
                            name="verifyCode"
                            rules={[
                                {
                                    required: true,
                                    message: "请输入短信验证码",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value || (/^\d{6}$/.test(value))) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error("请输入短信验证码"));
                                    },
                                }),
                            ]}
                        >
                            <Input prefix={<CodeIcon className="site-form-item-icon" />} autoComplete="off" placeholder="请输入短信验证码" />
                        </Form.Item>
                        {
                            verifyCodePending ?
                            <Button type="link" className="phone-code-btn" loading={verifyCodeLoading} onClick={refreshPhoneCode}>获取验证码</Button>
                            : <div className="phone-count-down">
                                <Countdown onFinish={onFinishCountdown} value={Date.now() + countTime} format="ss" />
                                s
                            </div>
                        }
                    </div>
                </Form.Item>

                <Form.Item className="form-submit-button">
                    <Button type="primary" htmlType="submit">登录</Button>
                </Form.Item>
            </Form>
        </FormWrap>
    }]

    /* 联系大客户经理 -- start */
    const [isService, setIsService] = useState(false);
    // eslint-disable-next-line
    const showService = () => {
        setIsService(true);
    }
    const showLogin = () => {
        setIsService(false);
    }
    /* 联系大客户经理 -- end */

    return (
        <Container bg={device !== "mobile" ? bgPic : bgMPic}>
            { device !== "mobile" ? <img className="logo" src={logoPic} alt="logo" /> : null }
            {
                !isService ? <MainWrap>
                    <div className="login-title">欢迎登录星鹿</div>
                    <Spin spinning={loading}>
                        <Tabs
                            items={tabItems}
                            activeKey={current}
                            onChange={(key) => setCurrent(key)}
                            size="large"
                        />
                    </Spin>
                    {/* <div className="login-service-tip">
                        <BulbOutlined className="login-service-tip-icon" />
                        XINGLOO不开放注册功能，请联系代理商开户
                    </div>
                    <div className="login-service-wrap">
                        <span>无法联系代理商？</span>
                        <Button type="link" onClick={showService}>联系大客户经理</Button>
                    </div> */}
                    <div className="login-bottom">
                        <span>还没有账号？</span>
                        <Button type="link" onClick={() => history.push("/register")}>免费注册</Button>
                    </div>
                </MainWrap> : <MainWrap>
                    <div className="login-title">联系大客户经理</div>
                    <div className="login-qrcode-wrap">
                        <img className="login-qrcode-img" src={servicePic} alt="pic" />
                    </div>
                    <div className="login-qrcode-tip">扫一扫上面的二维码图案，添加大客户经理</div>
                    <div className="login-bottom">
                        <span>已有账号？</span>
                        <Button type="link" onClick={showLogin}>立即登录</Button>
                    </div>
                </MainWrap>
            }
        </Container>
    )
};
// 映射 Redux 全局的 state 到组件的 props 上
const mapStateToProps = (state) => ({
    device: state.getIn(["app", "device"]),
    token: state.getIn(["user", "token"]),
});

// 映射 dispatch 到 props 上
const mapDispatchToProps = (dispatch) => {
    return {
        userLoginDispatch: (data) => dispatch(userLogin(data)),
    }
};

// 将 ui 组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Login));
