import React, { useEffect } from "react";
import { Modal } from "antd";

function NewsDetailModal(props) {
    const { open = false, data, onChangeOpen } = props;

    useEffect(() => {
        if (open) {
            if (!data) {
                hideModal();
            }
        }
        // eslint-disable-next-line
    }, [open, data])

    const hideModal = () => {
        onChangeOpen(false);
    }

    return <Modal
        title="新闻公告"
        open={open}
        footer={null}
        width={860}
        maskClosable={false}
        onCancel={hideModal}
        className="custom-modal-container news-modal-container"
    >
        {
            data ? <div className="news-modal-main">
                <div className="news-modal-title">{data.title}</div>
                <div className="news-modal-date">{data.releaseTime}</div>
                <div className="ql-editor news-modal-content" dangerouslySetInnerHTML={{ __html: data.content }}></div>
            </div> : null
        }
    </Modal>
};

export default React.memo(NewsDetailModal);