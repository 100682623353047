import React, { useEffect, useState } from "react";
import { Modal, Statistic, Button } from "antd";
import { Container, HeaderWrap, CountWrap, CodeWrap, WarningWrap, CloseBtn } from "./style";
import QRCode from "qrcode.react";
import { ReactComponent as IconPayment } from "@/assets/svg/payment.svg";
import { PayPollingInterval } from "@/utils/options";
import { checkPayStatus } from "@/api/request";

const { Countdown } = Statistic;

let timer = null;
let checkInterval = null;

function QrCodeModal(props) {
    const {
        device,
        open = false,
        qrCode = {
            title: "",
            orderNo: "",
            codeUrl: "",
            expire: 0,
        },
        onChangeOpen,
        onCancelPay,
    } = props;

    const [expire, setExpire] = useState(0);

    const clearTimer = () => {
        if (timer) {
            clearTimeout(timer);
        }
        if (checkInterval) {
            clearInterval(checkInterval);
        }
    }

    useEffect(() => {
        return () => {
            clearTimer();
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (open && qrCode) {
            beginCheck();
            setExpire(Date.now() + qrCode.expire);
        } else {
            setExpire(0);
            hideModal();
        }
        // eslint-disable-next-line
    }, [open, qrCode])

    const hideModal = () => {
        clearTimer();
        onChangeOpen(false);
    }

    const beginCheck = () => {
        checkInterval = setInterval(function() {
            checkStatus();
        }, PayPollingInterval)

        timer = setTimeout(() => {
            // 订单倒计时结束，当前订单未支付
            gotoCancelPay();
        }, qrCode.expire)
    }

    const checkStatus = () => {
        checkPayStatus(qrCode.orderNo).then(response => {
            if (response.code === 200) {
                if (response.data) {
                    // 支付成功
                    if (open && qrCode) {
                        clearTimer();
                        onChangeOpen(false, true);
                    }
                }
            } else {
                clearTimer();
            }
        }).catch(() => {
            clearTimer();
        })
    }

    const onFinishCountDown = () => {
        gotoCancelPay();
    }

    const doCancel = () => {
        hideModal();
    }

    const doComplete = () => {
        clearTimer();
        onChangeOpen(false, true);
    }

    const gotoCancelPay = () => {
        if (open && qrCode) {
            onCancelPay({
                orderNo: qrCode.orderNo,
                title: qrCode.title,
            });
        }
        hideModal();
    }
    return (
        <>
            <Modal
                title=""
                closable={false}
                maskClosable={false}
                open={open}
                footer={null}
                width={870}
                destroyOnClose={true}
            >
                <Container>
                    <HeaderWrap>
                        <IconPayment className="icon-svg" />
                        <div className="title">
                            <div className="order-title">{ qrCode ? qrCode.title : "-" }</div>
                            <div className="order-no">订单号：{ qrCode ? qrCode.orderNo : "-" }</div>
                        </div>
                    </HeaderWrap>
                    <CountWrap>
                        <div className="title">
                            <div className="pay-title">订单提交成功，请尽快完成付款！</div>
                            <div className="pay-tip">请在倒计时结束前完成支付，否则订单会被自动取消。</div>
                        </div>
                        <div className="count-wrap">
                            {
                                expire ? <Countdown
                                    value={expire}
                                    onFinish={onFinishCountDown}
                                    format="mm:ss"
                                /> : null
                            }
                        </div>
                    </CountWrap>
                    <CodeWrap>
                        {
                            qrCode && qrCode.codeUrl ? <QRCode
                                id="vi_code_url"
                                value={qrCode.codeUrl}
                                size={device !== "mobile" ? 200 : 130}
                                fgColor="#000000"
                            /> : null
                        }
                    </CodeWrap>
                    <WarningWrap>
                        打开手机微信，扫一扫继续付款
                    </WarningWrap>
                    <CloseBtn>
                        <Button size="large" onClick={doCancel}>取消支付</Button>
                        <Button type="primary" size="large" onClick={doComplete}>完成支付</Button>
                    </CloseBtn>
                </Container>
            </Modal>
        </>
    )
}

export default React.memo(QrCodeModal);
