import { AllRegions as RegionList } from "@/utils/regions/allRegions";

const DontSplitNameList = ["Guinea-Bissau".toLowerCase(), "Timor-Leste".toLowerCase()];

const RegionNameObj = {};
const RegionNameTWObj = {};
const RegionNameUSObj = {};
for (let i = 0; i < RegionList.length; i++) {
    const name = RegionList[i].name;
    const name_TW = RegionList[i].name_TW;
    const name_US = (RegionList[i].name_US).toLowerCase();
    RegionNameObj[name] = RegionList[i];
    RegionNameTWObj[name_TW] = RegionList[i];
    RegionNameUSObj[name_US] = RegionList[i];
    if (name.includes("中国-")) {
        RegionNameObj[(name.split("中国-"))[1]] = RegionList[i];
    }
    if (name_TW.includes("中國-")) {
        RegionNameTWObj[(name_TW.split("中國-"))[1]] = RegionList[i];
    }
    if (name_US.includes("china-")) {
        RegionNameUSObj[(name_US.split("china-"))[1]] = RegionList[i];
    }
}

function importAll(r) {
    const imageMap = {};
    r.keys().forEach((key) => {
        const imageName = key.replace("./", "").replace(".svg", "");
        imageMap[imageName] = r(key);
    });
    return imageMap;
}

const imagesMap = importAll(require.context("./images", false, /\.svg$/));

const GlobalSrc = imagesMap["global"].default;

export const getFlagImageSrc = (name) => {
    if (!name || typeof name !== "string") return GlobalSrc;
    name = name.trim();
    name = name.toLowerCase();
    if (imagesMap[name]) {
        return imagesMap[name].default;
    } else {
        let code = "";
        const countryName = DontSplitNameList.includes(name) ? name : (name.split("-"))[0];
        if (typeof RegionNameObj[countryName] !== "undefined") {
            code = RegionNameObj[countryName].code;
        } else if (typeof RegionNameTWObj[countryName] !== "undefined") {
            code = RegionNameTWObj[countryName].code;
        } else if (typeof RegionNameUSObj[countryName] !== "undefined") {
            code = RegionNameUSObj[countryName].code;
        }
        if (code) {
            code = code.toLowerCase();
            if (imagesMap[code]) {
                return imagesMap[code].default;
            }
        }
    }
    return GlobalSrc;
}
