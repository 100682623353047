import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    min-width: 712px;
    min-height: 100vh;
    padding: 100px 0;
    background-image: url(${props => props.bg});
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #277FFD;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .logo {
        position: absolute;
        left: 84px;
        top: 69px;
        width: 460px;
        height: auto;
    }
    @media (max-width: 768px) {
        min-width: auto;
        padding: 27.5vw 16px 20px 16px;
        background-position: top center;
        background-size: 100% auto;
        background-color: #EBEEF2;
        align-items: flex-start;
        justify-content: center;
    }
`

export const MainWrap = styled.div`
    width: 530px;
    height: 700px;
    padding: 30px 54px;
    background: #FFFFFF;
    box-shadow: 0px 4px 29px 3px rgba(15,65,188,0.41);
    border-radius: 10px;
    margin-right: 13%;
    z-index: 1;
    .login-title {
        width: 100%;
        text-align: center;
        font-size: 32px;
        color: #4073FF;
        line-height: 124px;
    }
    .login-qrcode-wrap {
        width: 300px;
        height: 300px;
        margin: 0 auto;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        .login-qrcode-img {
            width: 98%;
        }
    }
    .login-qrcode-tip {
        width: 100%;
        padding: 10px 20px;
        margin: 0 auto;
        background-color: #F9FAFE;
        border-radius: 8px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 80px;
    }
    .login-service-tip {
        width: 100%;
        padding: 10px 20px;
        background-color: #F9FAFE;
        border-radius: 8px;
        font-size: 16px;
        color: #4D5059;
        text-align: center;
        margin-top: 20px;
        .login-service-tip-icon {
            font-size: 18px;
            color: #faad14;
            margin-right: 4px;
        }
    }
    .login-service-wrap {
        margin-top: 6px;
        font-size: 16px;
        color: #606666;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-btn-link {
            height: fit-content;
        }
    }
    .login-bottom {
        margin-top: 20px;
        font-size: 16px;
        color: #4D5059;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-btn-link {
            height: fit-content;
        }
    }
    .ant-tabs-top > .ant-tabs-nav {
        margin: 0 0 40px 0;
        &::before {
            border-bottom: 2px solid #EBEEF5;
        }
    }
    .ant-tabs-nav-list {
        width: 100%;
        .ant-tabs-tab {
            width: 50%;
            & + .ant-tabs-tab {
                margin: 0;
            }
            .ant-tabs-tab-btn {
                width: 100%;
                font-size: 16px;
                text-align: center;
            }
            &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: #4073FF;
                }
            }
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        height: 511px;
        padding: 32px 20px;
        box-shadow: none;
        border-radius: 16px;
        margin-right: 0;
        .login-title {
            font-family: "msyhbd";
            font-size: 22px;
            color: #212529;
            line-height: 32px;
        }
        .login-qrcode-wrap {
            width: 178px;
            height: 178px;
            box-shadow: none;
            border-radius: 0;
            margin-top: 40px;
            .login-qrcode-img {
                width: 100%;
            }
        }
        .login-qrcode-tip {
            padding: 0;
            background-color: #FFFFFF;
            border-radius: 0;
            margin-bottom: 20px;
            font-size: 12px;
            color: #212529;
        }
        .login-service-tip {
            padding: 0;
            background-color: #FFFFFF;
            border-radius: 0;
            font-size: 12px;
            color: #4B5563;
            margin-top: 32px;
            .login-service-tip-icon {
                display: none;
            }
        }
        .login-service-wrap {
            font-size: 12px;
            color: #4B5563;
        }
        .login-bottom {
            margin-top: 24px;
            font-size: 12px;
            color: #4B5563;
        }
        .ant-tabs-top > .ant-tabs-nav {
            margin: 0 0 24px 0;
            &::before {
                border-bottom: 4px solid #FFFFFF;
            }
            & .ant-tabs-ink-bar {
                height: 4px;
                border-radius: 2px;
            }
        }
        .ant-tabs-nav-list {
            justify-content: space-around;
            margin-top: 16px;
            .ant-tabs-tab {
                width: 70px;
                padding: 8px 0 !important;
                .ant-tabs-tab-btn {
                    color: #B1B1B1;
                }
            }
        }
    }
`

export const FormWrap = styled.div`
    .login-form {
        .ant-form-item {
            margin-bottom: 30px;
        }
        .form-captcha-item {
            margin-bottom: 0;
        }
        .ant-input-affix-wrapper {
            height: 46px;
            padding: 6.5px 20px;
        }
        .site-form-item-icon {
            width: 18px;
            height: auto;
            fill: #ACB1BF;
        }
        .ant-input-prefix {
            margin-right: 30px;
        }
        .ant-input {
            font-size: 16px;
            color: #333;
        }
        .login-remember {
            /* margin-bottom: 30px; */
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            /* justify-content: space-between; */
            justify-content: flex-end;
            .ant-form-item {
                width: fit-content;
                margin-bottom: 0;
            }
            .ant-checkbox-wrapper {
                font-size: 16px;
                color: #7B808C;
            }
            .ant-form-item-control-input, .ant-btn-link, .ant-btn-text {
                height: 26px;
                min-height: fit-content;
            }
            .ant-btn-text {
                font-size: 16px;
                color: #7B808C;
            }
        }
        .form-captcha-wrap {
            position: relative;
            .ant-input {
                width: calc(100% - 150px);
            }
            .phone-code-btn {
                position: absolute;
                top: 4px;
                right: 20px;
                font-size: 16px;
                color: #006BFF;
                z-index: 1;
            }
            .phone-count-down {
                width: 80px;
                height: 40px;
                position: absolute;
                top: 4px;
                right: 20px;
                font-size: 22px;
                color: #006BFF;
                display: flex;
                justify-content: center;
                align-items: baseline;
                z-index: 1;
                .ant-statistic-content {
                    color: #006BFF;
                }
            }
        }
        .form-verify-box {
            width: 100%;
            height: 46px;
            border-radius: 4px;
            border: 1px solid #C8CDD9;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            /* &:hover {
                border-color: #4073FF;
            } */
        }
        .form-submit-button {
            position: relative;
            margin-bottom: 0;
            .ant-btn {
                width: 100%;
                height: 56px;
                font-size: 18px;
                color: #FFFFFF;
            }
        }
    }
    @media (max-width: 768px) {
        .login-form {
            .ant-form-item {
                margin-bottom: 24px;
            }
            .ant-input-affix-wrapper {
                height: 46px;
                padding: 6.5px 12px;
            }
            .ant-input-prefix {
                margin-right: 20px;
            }
            .ant-input {
                font-size: 14px;
            }
            .login-remember {
                .ant-btn-text {
                    font-size: 14px;
                    color: #212529;
                }
            }
            .form-captcha-wrap {
                position: relative;
                .ant-input {
                    width: calc(100% - 120px);
                }
                .phone-code-btn {
                    right: 12px;
                    font-size: 14px;
                }
                .ant-statistic-content {
                    font-size: 20px;
                }
                .phone-count-down {
                    height: 32px;
                    top: 8px;
                    right: 12px;
                    font-size: 18px;
                    display: flex;
                    justify-content: flex-end;
                }
            }
            .form-submit-button {
                position: relative;
                margin-bottom: 0;
                .ant-btn {
                    height: 44px;
                    border-radius: 24px;
                    font-size: 16px;
                }
            }
        }
    }
`
