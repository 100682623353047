import React from "react";
import { Modal, Button } from "antd";
import { HeaderWrap, MainWrap, CloseBtn } from "./style";
import { ReactComponent as IconPayment } from "@/assets/svg/payment.svg";
import { ReactComponent as IconNoPayment } from "@/assets/svg/noPay.svg";

function CancelPayModal(props) {
    const {
        open = false,
        cancelData = {
            title: "",
            orderNo: ""
        },
        onChangeOpen
    } = props;

    const hideModal = () => {
        onChangeOpen(false);
    }
    return (
        <>
            <Modal
                title=""
                closable={false}
                maskClosable={false}
                open={open}
                footer={null}
                width={870}
                className="qr-code-modal"
            >
                <HeaderWrap>
                    <IconPayment className="icon-svg" />
                    <div className="title">
                        <div className="order-title">{ cancelData ? cancelData.title : "-" }</div>
                        <div className="order-no">订单号：{ cancelData ? cancelData.orderNo : "-" }</div>
                    </div>
                </HeaderWrap>
                <MainWrap>
                    <IconNoPayment className="icon-svg" />
                    <div className="title">未支付</div>
                    <div className="warm-tip">您的订单已超时，请返回页面重新发起支付。</div>
                </MainWrap>
                <CloseBtn>
                    <Button type="primary" size="large" danger onClick={hideModal}>返 回</Button>
                </CloseBtn>
            </Modal>
        </>
    )
}

export default React.memo(CancelPayModal);
