import React, { useEffect, useRef, useState, useCallback } from "react";
import { Form, Radio, Input, InputNumber, Button, Select, Tooltip, Spin, Drawer, message } from "antd";
import { PlusOutlined, MinusOutlined, ExclamationCircleOutlined, DoubleLeftOutlined, LeftOutlined } from "@ant-design/icons";
import { Container, LeftWrap, LineWrap, LineItem, LineMWrap, RightWrap } from "./style";
import livePic from "@/assets/png/sdwan/live.png";
import operationsPic from "@/assets/png/sdwan/operations.png";
import { ReactComponent as EditIcon } from "@/assets/svg/table/edit.svg";
import {
    MaxBandwidth, MinSdwanQuantity, MaxSdwanQuantity,
    SdwanLiveType, SdwanTypeMap, SdwanStandardMap,
    ProxiesTypeMap, ProxiesTypeList,
    PurchaseTypeMap, PurchaseTypeList,
    BalancePayID,
    AliPayID,
    WechatPayID,
    SdwanTypeList,
    EnabledStatus,
    CompanyPayID,
    PurchaseDepositType
} from "@/utils/options";
import { DefaultBandwidthStep, DefaultPrice } from "@/utils/options";
import { PcAccessType, MobileAccessType } from "@/utils/options";
import { connect } from "react-redux";
import { userAddress } from "@/store/modules/user/actionCreators";
import { isWechatH5Pay, getClosestNumber } from "@/utils/index";
import { getSdwanItemList, getSdwanRenewItemList, getSdwanComputePrice, sdwanPayBuy } from "@/api/sdwan";
import { getUnionRegionAndPriceList } from "@/api/staticIP";
import useDebounceFn from "@/hooks/useDebounceFn";
import PayTypes from "@/components/PayTypes";
import QrCodeModal from "@/components/QrCodeModal";
import CancelPayModal from "@/components/CancelPayModal";
import CheckPayModal from "@/components/CheckPayModal";
import { keep2decimals } from "@/utils/index";
import AccountAddressModal from "@/components/AccountAddressModal";
import SimpleTipModal from "@/components/SimpleTipModal";
import axios from "axios";

const NoAutoRenewItemID = 9999;

function PurchaseLine(props) {
    const { history } = props;
    const { userAddressDispatch } = props;
    const [defaultAddress, setDefaultAddress] = useState(null);
    const [defaultAddressStep, setDefaultAddressStep] = useState(0);

    const [lineList, setLineList] = useState([]);
    const [lineListStep, setLineListStep] = useState(0);
    const [current, setCurrent] = useState(null);
    const [businessList, setBusinessList] = useState([]);
    const [businessType, setBusinessType] = useState(null);
    const [ipType, setIpType] = useState(null);
    const [bandwidth, setBandwidth] = useState(DefaultBandwidthStep);
    const [regionList, setRegionList] = useState([]);
    const [region, setRegion] = useState(null);
    const [boxType, setBoxType] = useState(null);
    const [durationList, setDurationList] = useState([]);
    const [duration, setDuration] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [renewOptions, setRenewOptions] = useState(null);
    const [renewItemId, setRenewItemId] = useState(NoAutoRenewItemID);
    const [payType, setPayType] = useState(null);
    const payRef = useRef(null);
    const [qrCodeVisible, setQrCodeVisible] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [cancelPayVisible, setCancelPayVisible] = useState(false);
    const [cancelData, setCancelData] = useState(null);
    const [companyPayOpen, setCompanyPayOpen] = useState(false);
    const [warningTip, setWarningTip] = useState(null);
    const checkPayRef = useRef(null);

    const [bandwidthPrice, setBandwidthPrice] = useState(DefaultPrice);
    const [boxPrice, setBoxPrice] = useState(DefaultPrice);
    const [totalPrice, setTotalPrice] = useState(DefaultPrice);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getItemList();
        getRegionList();
        initData();
        setDefaultAddressStep(1);
        userAddressDispatch().then((data) => {
            setDefaultAddressStep(2);
            setDefaultAddress(data && data.defaultAddress ? data.defaultAddress : null)
        }).catch(() => {
            setDefaultAddressStep(2);
        });
        getRenewOptions();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (current) {
            const newVal = getClosestNumber(current.bandwidthStep, bandwidth);
            if (newVal !== bandwidth) {
                setBandwidth(newVal);
            }
            const list = current.priceList || [];
            setDurationList([...list]);
            if (list.length > 0) {
                setDuration(list[0].id);
            }
        } else {
            setDurationList([]);
            setDuration(null);
        }
        setRenewItemId(NoAutoRenewItemID);
        doOpenOrderDrawer();
        // eslint-disable-next-line
    }, [current])

    useEffect(() => {
        if (current) {
            const purposeList = current.purposeList || [];
            const list = [];
            for (let i = 0; i < purposeList.length; i++) {
                if (purposeList[i].ipType === ipType && purposeList[i].state === EnabledStatus) {
                    list.push({...purposeList[i]});
                }
            }
            setBusinessList([...list]);
            if (list.length > 0) {
                setBusinessType(list[0]);
            }
        }
    }, [current, ipType])

    useEffect(() => {
        if (businessType) {
            setBandwidth(businessType.bandwidth);
        }
        // eslint-disable-next-line
    }, [businessType])

    useEffect(() => {
        if (!current || !duration || !bandwidth || !boxType || !quantity || !region || !payType) {
            return;
        }
        getOrderDetail();
        // eslint-disable-next-line
    }, [current, duration, bandwidth, boxType, quantity, region, payType])

    const getItemList = () => {
        setLineListStep(1);
        getSdwanItemList().then(response => {
            setLineListStep(2);
            if (response.code === 200) {
                const list = response.data || [];
                const lList = list.map(item => {
                    return {
                        ...item,
                        bandwidthStep: SdwanTypeMap.has(item.sdwanType) ? (SdwanTypeMap.get(item.sdwanType)).bandwidthStep : DefaultBandwidthStep,
                        imgSrc: item.sdwanType === SdwanLiveType ? livePic : operationsPic
                    }
                })
                setLineList(lList);
                if (lList.length > 0) {
                    setCurrent(lList[0]);
                }
            }
        }).catch(() => {
            setLineListStep(2);
        })
    }

    const getRegionList = () => {
        getUnionRegionAndPriceList().then(response => {
            if (response.code === 200) {
                const list = response.data || [];
                setRegionList(list);
                if (list.length > 0) {
                    setRegion({ key: list[0].regionId, value: list[0].regionId, label: list[0].region });
                }
            }
        }).catch(() => {})
    }

    const initData = () => {
        setIpType(ProxiesTypeList[0].value);
        setBoxType(PurchaseTypeList[0].value);
    }

    const resetPrices = () => {
        setBandwidthPrice(DefaultPrice);
        setBoxPrice(DefaultPrice);
        setTotalPrice(DefaultPrice);
    }

    const getOrderDetail = useDebounceFn(() => {
        resetPrices();
        const params = {
            sdwanItemId: current.id,
            sdwanPriceItemId: duration,
            businessType: businessType.id,
            ipType: ipType,
            regionId: region ? region.value : null,
            bandwidth: bandwidth,
            buyType: boxType,
            sdwanNum: quantity,
            autoRenewal: renewItemId !== NoAutoRenewItemID ? true : false,
            renewItemId: renewItemId !== NoAutoRenewItemID ? renewItemId : 0,
            deliverAddress: defaultAddress ? defaultAddress.id : null,
            payType: payType
        }
        getSdwanComputePrice(params).then(response => {
            if (response.code === 200 && response.data) {
                setBandwidthPrice(response.data.bandwidthPrice || 0);
                setBoxPrice(response.data.boxPrice || 0);
                setTotalPrice(response.data.totalPrice || 0);
            }
        }).catch(() => {})
    }, 100)

    const getRenewOptions = () => {
        for (let i = 0; i < SdwanTypeList.length; i++) {
            const currSdwanType = SdwanTypeList[i].value;
            getSdwanRenewItemList({ sdwanType: currSdwanType, bandwidth: 1 }).then(response => {
                if (response.code === 200) {
                    setRenewOptions(o => {
                        if (!o) {
                            o = {};
                        }
                        o[currSdwanType] = response.data || [];
                        o[currSdwanType].push({ id: NoAutoRenewItemID });
                        return {...o};
                    });
                }
            }).catch(() => {})
        }
    }

    useEffect(() => {
        if (lineListStep === 2) {
            if (!current) {
                setWarningTip("请先选择套餐");
            } else {
                if (!businessType) {
                    setWarningTip("请先选择业务用途");
                } else if (!ipType) {
                    setWarningTip("请先选择IP类型");
                } else if (!region) {
                    setWarningTip("请先选择地区");
                } else if (defaultAddressStep === 2 && !defaultAddress) {
                    setWarningTip("未设置默认收货地址，请前往设置");
                } else if (boxType === PurchaseDepositType && payType === BalancePayID) {
                    setWarningTip("押金交易不支持钱包余额支付");
                } else {
                    setWarningTip("");
                }
            }
        } else {
            setWarningTip("");
        }
    }, [current, lineListStep, businessType, ipType, region, defaultAddress, defaultAddressStep, boxType, payType])

    const gotoPay = async () => {
        setLoading(true);
        const accessType = isWechatH5Pay() ? MobileAccessType : PcAccessType;
        const params = {
            sdwanItemId: current.id,
            sdwanPriceItemId: duration,
            businessType: businessType.id,
            ipType: ipType,
            regionId: region ? region.value : null,
            bandwidth: bandwidth,
            buyType: boxType,
            sdwanNum: quantity,
            autoRenewal: renewItemId !== NoAutoRenewItemID ? true : false,
            renewItemId: renewItemId !== NoAutoRenewItemID ? renewItemId : 0,
            deliverAddress: defaultAddress ? defaultAddress.id : null,
            payType: payType,
            accessType: accessType
        }
        if (accessType === MobileAccessType && payType === WechatPayID) {
            let ipRes = await axios.get("https://ipinfo.io/ip");
            if (ipRes && ipRes.data) {
                params.wechatPayerClientIp = ipRes.data;
            } else {
                ipRes = await axios.get("https://api.ipify.org");
                params.wechatPayerClientIp = ipRes.data;
            }
        }
        sdwanPayBuy(params).then(response => {
            setLoading(false);
            if (response.code === 200) {
                if (response.data.paymentId === BalancePayID) {
                    if (payRef) {
                        payRef.current.updateBalance();
                    }
                    handlePaySuccess();
                } else if (response.data.paymentId === AliPayID) {
                    window.open(response.data.url, '_blank');
                    if (checkPayRef) {
                        checkPayRef.current.show({ orderNo: response.data.orderNo, expire: keep2decimals(response.data.timeoutExpress * 60 * 1000) });
                    }
                } else if (response.data.paymentId === WechatPayID) {
                    if (response.data.h5Url) {
                        const rUrl = window.location.origin + "/sdwan";
                        window.open(response.data.h5Url + `&redirect_url=${encodeURIComponent(rUrl)}`, '_blank');
                        if (checkPayRef) {
                            checkPayRef.current.show({ orderNo: response.data.orderNo, expire: keep2decimals(response.data.timeoutExpress * 60 * 1000) });
                        }
                    } else {
                        showQrCode(response.data);
                    }
                } else if (response.data.paymentId === CompanyPayID) {
                    setCompanyPayOpen(true);
                }
            }
        }).catch(() => {
            setLoading(false);
        })
    }

    const handlePaySuccess = () => {
        history.push("/sdwan");
    }

    const showMaxBandwidthTip = () => {
        message.destroy();
        message.warning("当前总带宽不得大于100Mbps");
    }

    const showQrCode = (data) => {
        setQrCode({
            title: `购买线路 - ${current.name} - ${totalPrice}元`,
            orderNo: data.orderNo || "",
            codeUrl: data.codeUrl || "",
            expire: keep2decimals(data.timeoutExpress * 60 * 1000)
        });
        setQrCodeVisible(true);
    }

    const handleChangeQrCodeVisible = useCallback((val, success) => {
        setQrCodeVisible(val);
        if (success) {
            handlePaySuccess();
        }
        // eslint-disable-next-line
    }, [])

    const handleShowCancelModal = (data) => {
        setCancelData({
            orderNo: data.orderNo || "",
            title: data.title || "",
        });
        setCancelPayVisible(true);
    }

    const handleChangeCancelVisible = useCallback((val) => {
        setCancelPayVisible(val);
    }, [])

    const handleChangeCompanyPayOpen = (open, isOk) => {
        setCompanyPayOpen(open);
        if (isOk) {
            history.push("/order");
        }
    }

    const subBandwidth = () => {
        setBandwidth(val => {
            const step = current ? current.bandwidthStep : DefaultBandwidthStep;
            val -= step;
            if (val < step) {
                val = step;
            }
            return val;
        })
    }
    const addBandwidth = () => {
        setBandwidth(val => {
            const step = current ? current.bandwidthStep : DefaultBandwidthStep;
            val += step;
            if (val > MaxBandwidth) {
                showMaxBandwidthTip();
                val = MaxBandwidth;
            }
            return val;
        })
    }
    const changeBandwidth = (value) => {
        setBandwidth(val => {
            const step = current ? current.bandwidthStep : DefaultBandwidthStep;
            val = getClosestNumber(step, value);
            if (val < step) {
                val = step;
            }
            if (val > MaxBandwidth) {
                showMaxBandwidthTip();
                val = MaxBandwidth;
            }
            return val;
        })
    }

    const subQuantity = () => {
        setQuantity(val => {
            val--;
            if (val < MinSdwanQuantity) {
                val = MinSdwanQuantity;
            }
            return val;
        })
    }
    const addQuantity = () => {
        setQuantity(val => {
            val++;
            if (val > MaxSdwanQuantity) {
                val = MaxSdwanQuantity;
            }
            return val;
        })
    }
    const changeQuantity = (value) => {
        setQuantity(val => {
            val = value;
            if (val < MinSdwanQuantity) {
                val = MinSdwanQuantity;
            }
            if (val > MaxSdwanQuantity) {
                val = MaxSdwanQuantity;
            }
            return val;
        })
    }

    const getDefaultAddr = () => {
        if (defaultAddress) {
            return <div className="receipt-info-main">
                {`${defaultAddress.name||''} ${defaultAddress.phone||''} ${defaultAddress.province||''}${defaultAddress.city||''}${defaultAddress.area||''}${defaultAddress.detail||''}`}
            </div>
        } else {
            if (defaultAddressStep === 2) {
                return <div className="receipt-info-main">未设置</div>
            } else {
                return <div className="receipt-info-main">-</div>
            }
        }
    }

    const showAddr = () => {
        setAddrOpen(true)
    }

    const [addrOpen, setAddrOpen] = useState(false);
    const handleChangeAddrOpen = useCallback((open, refresh) => {
        setAddrOpen(open);
        if (refresh) {
            setDefaultAddressStep(1);
            userAddressDispatch().then((data) => {
                setDefaultAddressStep(2);
                setDefaultAddress(data && data.defaultAddress ? data.defaultAddress : null)
            }).catch(() => {
                setDefaultAddressStep(2);
            });
        }
        // eslint-disable-next-line
    }, [])

    const { device } = props;
    const [openOrderDrawer, setOpenOrderDrawer] = useState(true);
    const [showOpenBtn, setShowOpenBtn] = useState(true);
    const [showCloseBtn, setCloseOpenBtn] = useState(true);
    useEffect(() => {
        if (device === "fullhd") {
            setOpenOrderDrawer(true);
            setShowOpenBtn(false);
            setCloseOpenBtn(false);
        } else if (device === "mobile") {
            setOpenOrderDrawer(false);
            setShowOpenBtn(false);
            setCloseOpenBtn(false);
        } else {
            setShowOpenBtn(true);
            setCloseOpenBtn(true);
        }
    }, [device])
    useEffect(() => {
        if (device === "mobile") {
            const parentElem = document.querySelector(".antd-content-wrap");
            if (parentElem) {
                parentElem.scrollTo({ top: 0 });
            }
        }
        // eslint-disable-next-line
    }, [openOrderDrawer])
    const doOpenOrderDrawer = () => {
        if (device !== "fullhd" && device !== "mobile" && !openOrderDrawer) {
            setOpenOrderDrawer(true);
        }
    }

    return <>
        <Container>
            <Spin spinning={loading}>
                <LeftWrap>
                    <div className="page-title">线路购买</div>
                    {
                        device !== "mobile" ? <LineWrap>
                            {
                                lineList.map(item => {
                                    return <LineItem
                                        className={`line-item${ current && current.id === item.id ? " active" : "" }`}
                                        key={item.id}
                                        onClick={() => setCurrent(item)}
                                    >
                                        <div className="line-main">
                                            <div className="line-info">
                                                <div className="line-name">{item.name}</div>
                                                <div className="line-price">{keep2decimals(item.price*item.bandwidthStep)}元/月<span className="line-price-tip">起</span></div>
                                                <div className="line-description">{item.description}</div>
                                            </div>
                                            <img className="line-icon" src={item.imgSrc} alt="pic" />
                                        </div>
                                        <div className="line-param">
                                            <div className="line-param-label">建议并发终端数</div>
                                            <div className="line-param-value">{item.concurrent}个</div>
                                        </div>
                                        <div className="line-param">
                                            <div className="line-param-label">IP规格</div>
                                            <div className="line-param-value">{item.ipCount}{item.ipStandard}</div>
                                        </div>
                                        <div className="line-param">
                                            <div className="line-param-label">线路规格</div>
                                            <div className="line-param-value">{ SdwanStandardMap.has(item.sdwanStandard) ? (SdwanStandardMap.get(item.sdwanStandard)).label : item.sdwanStandard }</div>
                                        </div>
                                    </LineItem>
                                })
                            }
                        </LineWrap> : <LineMWrap>
                            <div className="line-tab-list">
                                {
                                    lineList.map(item => {
                                        return <div
                                            className={`line-tab-item${ current && current.id === item.id ? " active" : "" }`}
                                            key={item.id}
                                            onClick={() => setCurrent(item)}
                                        >
                                            {item.name}
                                            <div className="line-tab-line"></div>
                                        </div>
                                    })
                                }
                            </div>
                            {
                                current ? <div className="line-curr-container">
                                    <div className="line-curr-main">
                                        <img className="line-curr-icon" src={current.imgSrc} alt="pic" />
                                        <div className="line-curr-info">
                                            <div className="line-curr-name">{current.name}</div>
                                            <div className="line-curr-price">{keep2decimals(current.price*current.bandwidthStep)}元/月<span className="line-curr-price-tip">起</span></div>
                                        </div>
                                    </div>
                                    <div className="line-curr-description">{current.description}</div>
                                    <div className="line-curr-param">
                                        <div className="line-curr-param-label">建议并发终端数</div>
                                        <div className="line-curr-param-value">{current.concurrent}个</div>
                                    </div>
                                    <div className="line-curr-param">
                                        <div className="line-curr-param-label">IP规格</div>
                                        <div className="line-curr-param-value">{current.ipCount}{current.ipStandard}</div>
                                    </div>
                                    <div className="line-curr-param">
                                        <div className="line-curr-param-label">线路规格</div>
                                        <div className="line-curr-param-value">{ SdwanStandardMap.has(current.sdwanStandard) ? (SdwanStandardMap.get(current.sdwanStandard)).label : current.sdwanStandard }</div>
                                    </div>
                                </div> : null
                            }
                        </LineMWrap>
                    }
                    <Form
                        className="pay-form"
                        layout="horizontal"
                        size="large"
                    >
                        <Form.Item label="业务用途" className="custom-radio-group">
                            <Radio.Group value={businessType} onChange={(e) => setBusinessType(e.target.value)} optionType="button" buttonStyle="solid">
                                {
                                    businessList.map(item => {
                                        return <Radio.Button value={item} key={item.id}>{item.name}</Radio.Button>
                                    })
                                }
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="IP 类型">
                            <Radio.Group value={ipType} onChange={(e) => setIpType(e.target.value)} optionType="button" buttonStyle="solid">
                                {
                                    ProxiesTypeList.map(item => {
                                        return <Radio.Button value={item.value} key={item.value}>{item.label}</Radio.Button>
                                    })
                                }
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="带宽规格" tooltip={current && SdwanTypeMap.has(current.sdwanType) ? (SdwanTypeMap.get(current.sdwanType)).bandwidthTip : ""}>
                            <Input.Group compact className="custom-input-number">
                                <Button type="text" className="in-left-btn" onClick={subBandwidth}><MinusOutlined /></Button>
                                <InputNumber
                                    value={bandwidth}
                                    onChange={(val) => changeBandwidth(val)}
                                    min={current ? current.bandwidthStep : DefaultBandwidthStep}
                                    // max={MaxBandwidth}
                                    parser={text => /^\d+$/.test(text) ? text : 1}
                                    controls={false}
                                />
                                <Button type="text" className="in-right-btn" onClick={addBandwidth}><PlusOutlined /></Button>
                                <span className="in-suffix">Mbps</span>
                            </Input.Group>
                        </Form.Item>
                        <Form.Item label="地区选择">
                            <Select
                                showSearch
                                placeholder="请选择"
                                optionFilterProp="children"
                                value={region}
                                labelInValue
                                onChange={(val) => setRegion(val)}
                                filterOption={(input, option) =>
                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {
                                    regionList.map(item => {
                                        return <Select.Option value={item.regionId} key={item.regionId} label={item.region}>{item.region}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="加速盒交易方式" tooltip="一条线路对应一个加速盒，选择押金交易需在到期当月联系客服寄回盒子后退还押金">
                            <Radio.Group value={boxType} onChange={(e) => setBoxType(e.target.value)} optionType="button" buttonStyle="solid">
                                {
                                    PurchaseTypeList.map(item => {
                                        return <Radio.Button value={item.value} key={item.value}>{item.label}</Radio.Button>
                                    })
                                }
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                    {
                        showOpenBtn && !openOrderDrawer ? <div className="order-drawer-open-btn" onClick={() => setOpenOrderDrawer(true)}>
                            <DoubleLeftOutlined className="order-drawer-open-icon" />展开
                        </div> : null
                    }
                    { device === "mobile" ? <Button type="primary" size="large" onClick={() => setOpenOrderDrawer(true)} className="pay-submit-btn">提交订单</Button> : null }
                </LeftWrap>
                <RightWrap></RightWrap>
                <Drawer
                    placement="right"
                    closable={false}
                    open={openOrderDrawer}
                    getContainer={false}
                    mask={false}
                    width={ device !== "mobile" ? 420 : "100vw" }
                    className="order-submit-drawer"
                >
                    <div className="order-submit-container">
                        <div className="right-title">
                            { device === "mobile" ? <LeftOutlined className="order-submit-back" onClick={() => setOpenOrderDrawer(false)} /> : null }
                            订单确认
                        </div>
                        <div className="order-list-container">
                            <div className="order-title">{ current ? current.name : "-" }</div>
                            <div className="order-item">
                                <div className="order-label">国家/地区</div>
                                <div className="order-value">{ region ? region.label : "-" }</div>
                            </div>
                            <div className="order-item">
                                <div className="order-label">带宽规格</div>
                                <div className="order-value-group">
                                    <div className="order-value">{bandwidth}Mbps</div>
                                    <div className="order-price">￥{bandwidthPrice}</div>
                                </div>
                            </div>
                            <div className="order-item">
                                <div className="order-label">出口IP</div>
                                <div className="order-value">{ current && ipType ? `${current.ipCount}个独享${(ProxiesTypeMap.get(ipType)).label}` : "-" }</div>
                            </div>
                            <div className="order-item">
                                <div className="order-label">加速盒选择</div>
                                <div className="order-value-group">
                                    <div className="order-value">{ boxType ? (PurchaseTypeMap.get(boxType)).label : "-" }</div>
                                    { boxPrice ? <div className="order-price">￥{boxPrice}</div> : null }
                                </div>
                            </div>
                            <div className="order-item">
                                <div className="order-label">线路时长</div>
                                <div className="order-value-group">
                                    <Select value={duration} onChange={(val) => setDuration(val)} size="middle">
                                        {
                                            durationList.map(item => {
                                                return <Select.Option value={item.id} key={item.id} label={`${item.duration}天`}>{item.duration}天</Select.Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className="order-item">
                                <div className="order-label">线路数量</div>
                                <Input.Group compact className="custom-input-number">
                                    <Button type="text" size="middle" className="in-left-btn" onClick={subQuantity}><MinusOutlined /></Button>
                                    <InputNumber
                                        value={quantity}
                                        onChange={(val) => changeQuantity(val)}
                                        min={MinSdwanQuantity}
                                        max={MaxSdwanQuantity}
                                        parser={text => /^\d+$/.test(text) ? text : 1}
                                        controls={false}
                                        size="middle"
                                    />
                                    <Button type="text" size="middle" className="in-right-btn" onClick={addQuantity}><PlusOutlined /></Button>
                                </Input.Group>
                            </div>
                        </div>
                        <div className="order-total-wrap">
                            {
                                current && renewOptions && renewOptions[current.sdwanType] ? <div className="order-renew-select">
                                    <Select
                                        value={renewItemId}
                                        onChange={(val) => setRenewItemId(val)}
                                        size="middle"
                                        bordered={false}
                                    >
                                        {
                                            renewOptions[current.sdwanType].map(item => {
                                                return <Select.Option value={item.id} key={item.id}>
                                                    {
                                                        item.id === NoAutoRenewItemID ? "关闭自动续费" : <div className="order-renew-select-option">
                                                            <span>自动续费</span>
                                                            <span>{item.duration}天</span>
                                                        </div>
                                                    }
                                                </Select.Option>
                                            })
                                        }
                                    </Select>
                                    <Tooltip title="请注意，开启自动续费后，系统将在当前套餐到期前一天自动进行续费，若当时余额不足无法续费，自动续费开关将会自动关闭。因此请确保您的星鹿账户余额充足，如果由于余额不足导致续费失败后造成任何问题，星鹿不承担任何责任。">
                                        <ExclamationCircleOutlined style={{ color: "#F56C6C", cursor: "pointer" }} />
                                    </Tooltip>
                                </div> : <div></div>
                            }
                            <div className="order-total">
                                总金额
                                <span className="order-total-price">{totalPrice}元</span>
                            </div>
                        </div>
                        <div className="receipt-info-wrap">
                            <div className="receipt-info-header">
                                <span>
                                    收货信息
                                    <Tooltip title="请确保您的收件信息准确无误，如因您的原因导致收件问题，请自行承担所产生的损失。">
                                        <ExclamationCircleOutlined className="receipt-info-tips" />
                                    </Tooltip>
                                </span>
                                <Button type="link" onClick={showAddr}><EditIcon className="receipt-info-edit" />前往{ defaultAddress ? "修改" : "设置" }</Button>
                            </div>
                            {getDefaultAddr()}
                        </div>
                        <PayTypes onRef={payRef} amount={totalPrice} warningTip={warningTip} onChange={(val) => setPayType(val)} onPay={gotoPay} />
                        {
                            showCloseBtn ? <div className="order-drawer-close-btn" onClick={() => setOpenOrderDrawer(false)}>
                                <DoubleLeftOutlined className="order-drawer-close-icon" />收起
                            </div> : null
                        }
                    </div>
                </Drawer>
            </Spin>
        </Container>
        <QrCodeModal
            device={device}
            open={qrCodeVisible}
            qrCode={qrCode}
            onCancelPay={handleShowCancelModal}
            onChangeOpen={handleChangeQrCodeVisible}
        />
        <CancelPayModal
            open={cancelPayVisible}
            cancelData={cancelData}
            onChangeOpen={handleChangeCancelVisible}
        />
        <CheckPayModal onRef={checkPayRef} onSuccess={handlePaySuccess} />
        <AccountAddressModal open={addrOpen} onChangeOpen={handleChangeAddrOpen} />
        <SimpleTipModal
            open={companyPayOpen}
            title="对公支付"
            content="您已经使用对公支付的方式创建了订单，请点击下方按钮前往【订单列表】，点击订单操作的【对公支付】，查看星鹿对公账号支付并上传支付凭证。"
            okText="前往"
            onChangeOpen={handleChangeCompanyPayOpen}
        />
    </>
}

// 映射 Redux 全局的 state 到组件的 props 上
const mapStateToProps = (state) => ({
    device: state.getIn(["app", "device"]),
});

// 映射 dispatch 到 props 上
const mapDispatchToProps = (dispatch) => {
    return {
        userAddressDispatch: () => dispatch(userAddress()),
    }
};

// 将 ui 组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PurchaseLine));
