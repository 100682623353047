import React, { useEffect, useState } from "react";
import { Button, Modal, Spin, Form, Input, Cascader, Checkbox, message } from "antd";
import RegionOptions from "@/utils/regions";
import { createUserAddress, updateUserAddress } from "@/api/request";

function UpdateAddress(props) {
    const [form] = Form.useForm();
    
    const { open = false, data, onChangeOpen } = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            form.resetFields();
            if (data) {
                const areas = [];
                if (data.province) {
                    areas.push(data.province);
                    if (data.city) {
                        areas.push(data.city);
                        if (data.area) {
                            areas.push(data.area);
                        }
                    }
                }
                form.setFieldsValue({
                    id: data.id,
                    name: data.name,
                    phone: data.phone,
                    areas: areas,
                    detail: data.detail,
                    isDefault: data.isDefault
                })
            }
        }
        // eslint-disable-next-line
    }, [open])

    const onFinish = (values) => {
        const { name, phone, areas, detail, isDefault } = values;
        const params = {
            name,
            phone,
            province: areas[0],
            city: areas[1],
            area: areas[2],
            detail
        }
        if (data) {
            params.id = data.id;
            params.isDefault = data.isDefault;
        } else {
            params.isDefault = isDefault;
        }
        setLoading(true);
        const p = data ? updateUserAddress(params) : createUserAddress(params);
        p.then(res => {
            setLoading(false);
            if (res.code === 200) {
                message.success(`${data?"修改":"添加"}成功`);
                onChangeOpen(false, true);
            }
        }).catch(() => {
            setLoading(false);
        })
    }

    const hideModal = () => {
        onChangeOpen(false);
    }

    return <Modal
        title={`${data?"修改":"添加"}收货信息`}
        open={open}
        footer={null}
        width={560}
        maskClosable={false}
        closable={!loading}
        onCancel={hideModal}
        className="custom-modal-container changeAddr-modal-container"
    >
        <div className="changeAddr-modal-content">
            <Spin spinning={loading}>
                <Form
                    form={form}
                    className="changeAddr-modal-form"
                    initialValues={{
                        name: "",
                        phone: "",
                        areas: [],
                        detail: "",
                        isDefault: false
                    }}
                    onFinish={onFinish}
                    layout="horizontal"
                >
                    <Form.Item
                        name="name"
                        label="收件人"
                        rules={[
                            {
                                required: true,
                                message: "请填写收件人，不超过25个字",
                            },
                            {
                                min: 1,
                                max: 25,
                                message: "请填写收件人，不超过25个字",
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="收件人名字" />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="手机号"
                        rules={[
                            {
                                required: true,
                                message: "请输入11位手机号码",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value || (/^1[3-9]\d{9}$/.test(value))) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error("请输入11位手机号码"));
                                },
                            }),
                        ]}
                    >
                        <Input autoComplete="off" placeholder="手机号" />
                    </Form.Item>
                    <Form.Item
                        name="areas"
                        label="所在地区"
                        rules={[
                            {
                                required: true,
                                message: "请选择所在地区",
                            },
                        ]}
                    >
                        <Cascader options={RegionOptions} placeholder="省、市、区" />
                    </Form.Item>
                    <Form.Item
                        name="detail"
                        label="详细地址"
                        rules={[
                            {
                                required: true,
                                message: "长度需要2-120之间，不能包含特殊符号",
                            },
                            {
                                min: 2,
                                max: 120,
                                message: "长度需要2-120之间，不能包含特殊符号",
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="小区、写字楼、门牌号等" />
                    </Form.Item>
                    {
                        !data ? <Form.Item
                            name="isDefault"
                            valuePropName="checked"
                        >
                            <Checkbox>设为默认收货地址</Checkbox>
                        </Form.Item> : null
                    }

                    <Form.Item className="changeAddr-modal-footer">
                        <Button type="primary" htmlType="submit">确定</Button>
                        <Button onClick={hideModal}>取消</Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    </Modal>
};

export default React.memo(UpdateAddress);