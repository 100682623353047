import axios from "axios";

const isNewVersion = () => {
    const url = `//${window.location.host}/version.json?t=${new Date().getTime()}`;
    axios.get(url).then(res => {
        if (res.data) {
            const xingloo_u_v = res.data.version;
            const localVueVersion = localStorage.getItem("xingloo_u_v");
            if (localVueVersion !== xingloo_u_v) {
                localStorage.setItem("xingloo_u_v", xingloo_u_v);
                window.location.reload();
                return;
            } else {
                localStorage.setItem("xingloo_u_v", xingloo_u_v);
            }
        }
    })
}

export default isNewVersion;