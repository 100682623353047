import { useEffect } from "react";
import useLatest from "./useLatest";

const useUnmount = (fn) => {
    const fnRef = useLatest(fn); // why?
    useEffect(() => () => {
        if (fnRef && fnRef.current) {
            fnRef.current();
        }
        // eslint-disable-next-line
    }, [])
}

export default useUnmount;