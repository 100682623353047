import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    padding: 100px 0;
    background-image: url(${props => props.bg});
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #277FFD;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .logo {
        position: absolute;
        left: 84px;
        top: 69px;
        width: 460px;
        height: auto;
    }
    @media (max-width: 768px) {
        min-width: auto;
        padding: 27.5vw 16px 20px 16px;
        background-position: top center;
        background-size: 100% auto;
        background-color: #EBEEF2;
        align-items: flex-start;
        justify-content: center;
    }

`

export const MainWrap = styled.div`
    width: 530px;
    height: 730px;
    padding: 30px 54px;
    background: #FFFFFF;
    box-shadow: 0px 4px 29px 3px rgba(15,65,188,0.41);
    border-radius: 10px;
    margin-right: 13%;
    z-index: 1;
    .register-title {
        width: 100%;
        text-align: center;
        font-size: 32px;
        color: #4073FF;
        line-height: 110px;
    }
    .register-bottom {
        margin-top: 20px;
        font-size: 16px;
        color: #4D5059;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-btn-link {
            height: fit-content;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        height: 560px;
        padding: 32px 20px;
        box-shadow: none;
        border-radius: 16px;
        margin-right: 0;
        .register-title {
            font-family: "msyhbd";
            font-size: 22px;
            color: #212529;
            line-height: 32px;
            margin-bottom: 30px;
        }
        .register-bottom {
            margin-top: 24px;
            font-size: 12px;
            color: #4B5563;
        }
    }
`

export const FormWrap = styled.div`
    .register-form {
        .ant-form-item {
            margin-bottom: 30px;
        }
        .form-captcha-item {
            margin-bottom: 0;
        }
        .ant-input-affix-wrapper {
            height: 46px;
            padding: 6.5px 20px;
        }
        .site-form-item-icon {
            width: 18px;
            height: auto;
            fill: #ACB1BF;
        }
        .ant-input-prefix {
            margin-right: 30px;
        }
        .ant-input {
            font-size: 16px;
            color: #333;
        }
        .form-captcha-wrap {
            position: relative;
            .ant-input {
                width: calc(100% - 150px);
            }
            .phone-code-btn {
                position: absolute;
                top: 4px;
                right: 20px;
                font-size: 16px;
                color: #006BFF;
                z-index: 1;
            }
            .phone-count-down {
                width: 80px;
                height: 40px;
                position: absolute;
                top: 4px;
                right: 20px;
                font-size: 22px;
                color: #006BFF;
                display: flex;
                justify-content: center;
                align-items: baseline;
                z-index: 1;
                .ant-statistic-content {
                    color: #006BFF;
                }
            }
        }
        .form-verify-box {
            width: 100%;
            height: 46px;
            border-radius: 4px;
            border: 1px solid #C8CDD9;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            /* &:hover {
                border-color: #4073FF;
            } */
        }
        .register-agreement {
            .ant-checkbox-wrapper {
                font-size: 16px;
                color: #7B808C;
            }
            .ant-form-item-control-input, .ant-btn-link {
                height: 26px;
                min-height: fit-content;
            }
        }
        .form-submit-button {
            position: relative;
            margin-bottom: 0;
            .ant-btn {
                width: 100%;
                height: 56px;
                font-size: 18px;
                color: #FFFFFF;
            }
        }
    }
    @media (max-width: 768px) {
        .register-form {
            .ant-form-item {
                margin-bottom: 24px;
            }
            .form-captcha-item {
                margin-bottom: 0;
            }
            .ant-input-affix-wrapper {
                height: 46px;
                padding: 6.5px 12px;
            }
            .ant-input-prefix {
                margin-right: 20px;
            }
            .ant-input {
                font-size: 14px;
            }
            .form-captcha-wrap {
                position: relative;
                .ant-input {
                    width: calc(100% - 120px);
                }
                .phone-code-btn {
                    right: 12px;
                    font-size: 14px;
                }
                .ant-statistic-content {
                    font-size: 20px;
                }
                .phone-count-down {
                    height: 32px;
                    top: 8px;
                    right: 12px;
                    font-size: 18px;
                    display: flex;
                    justify-content: flex-end;
                }
            }
            .register-agreement {
                .ant-checkbox-wrapper {
                    font-size: 14px;
                }
                .ant-checkbox + span {
                    padding-right: 4px;
                    padding-left: 4px;
                }
                .ant-form-item-control-input, .ant-btn-link {
                    height: 20px;
                }
            }
            .form-submit-button {
                position: relative;
                margin-bottom: 0;
                margin-top: 28px;
                .ant-btn {
                    height: 44px;
                    border-radius: 24px;
                    font-size: 16px;
                }
            }
        }
    }
`
