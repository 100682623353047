import React, { useEffect, useState, useImperativeHandle } from "react";
import { connect } from "react-redux";
import { userBalance } from "@/store/modules/user/actionCreators";
import { Button, message } from "antd";
import { BalancePayID, CompanyPayID, AliPayID, WechatPayID, DefaultPrice, PayTypeMap } from "@/utils/options";
import { ReactComponent as BalancepayIcon } from "@/assets/svg/pay/balancepay.svg";
import { ReactComponent as CompanypayIcon } from "@/assets/svg/pay/companypay.svg";
import { ReactComponent as AlipayIcon } from "@/assets/svg/pay/alipay.svg";
import { ReactComponent as WepayIcon } from "@/assets/svg/pay/wepay.svg";
import useDebounceFn from "@/hooks/useDebounceFn";
import { keep2decimals } from "@/utils";

function PayTypes(props) {
    const { amount, warningTip, onChange = {}, onPay = {} } = props;

    const { paymentList, balance, userBalanceDispatch } = props;

    const [hasBalancePay, setHasBalancePay] = useState(false);
    const [hasCompanyPay, setHasCompanyPay] = useState(false);
    const [payTypeList, setPayTypeList] = useState([]);
    const [payTypeOptions, setPayTypeOptions] = useState({});
    const [current, setCurrent] = useState(null);
    const [tips, setTips] = useState(null);

    useEffect(() => {
        const paymentListJS = paymentList ? paymentList.toJS() : [];
        let hBalancePay = false;
        let hCompanyPay = false;
        const pList = [];
        const pObj = {};
        for (let i = 0; i < paymentListJS.length; i++) {
            pObj[paymentListJS[i].id] = { ...paymentListJS[i] };
            if (paymentListJS[i].id === BalancePayID) {
                hBalancePay = true;
            } else if (paymentListJS[i].id === CompanyPayID) {
                hCompanyPay = true;
            } else if (PayTypeMap.has(paymentListJS[i].id)) {
                pList.push(paymentListJS[i]);
            }
        }
        setHasBalancePay(hBalancePay);
        setHasCompanyPay(hCompanyPay);
        setPayTypeList(pList);
        setPayTypeOptions(pObj);
        if (hBalancePay) {
            userBalanceDispatch();
            setCurrent(BalancePayID);
        } else if (pList.length > 0) {
            setCurrent(pList[0].id);
        }
        // eslint-disable-next-line
    }, [paymentList])

    useEffect(() => {
        onChange(current);
        // eslint-disable-next-line
    }, [current])

    useEffect(() => {
        setTips(warningTip);
        // eslint-disable-next-line
    }, [warningTip])

    useEffect(() => {
        if (!warningTip && payTypeOptions[current] && payTypeOptions[current].limitMax > 0  && amount * 100 > payTypeOptions[current].limitMax ) {
            setTips(`支付金额上限为${keep2decimals(payTypeOptions[current].limitMax/100)}元，如超出限额，请联系客服处理`);
        } else if (!warningTip && current === BalancePayID && amount !== DefaultPrice && amount > 0 && balance < amount) {
            setTips("余额不足，请前往充值");
        } else {
            setTips(warningTip);
        }
    }, [payTypeOptions, current, balance, amount, warningTip])

    const gotoPay = useDebounceFn(() => {
        if (tips) {
            message.destroy();
            message.warning(tips);
            return;
        }
        onPay();
    }, 100)

    useImperativeHandle(props.onRef, () => {
        return {
            updateBalance: () => {
                userBalanceDispatch();
            }
        }
    })

    const getPayIcon = (id) => {
        switch(id) {
            case AliPayID:
                return <AlipayIcon className="pay-svg" />
            case WechatPayID:
                return <WepayIcon className="pay-svg" />
            default:
                break;
        }
        return null;
    }

    const getPayName = (id) => {
        switch(id) {
            case AliPayID:
                return "支付宝支付";
            case WechatPayID:
                return "微信支付";
            default:
                break;
        }
        return null;
    }

    return <>
        <div className="order-pay-list">
            {
                hasBalancePay ? <div className={`order-pay-item${hasBalancePay && !hasCompanyPay ? " order-pay-item-single" : ""}${current===BalancePayID?" active":""}`} onClick={() => setCurrent(BalancePayID)}>
                    <BalancepayIcon className="pay-svg" />
                    <span>余额支付(¥{balance})</span>
                </div> : null
            }
            {
                hasCompanyPay ? <div className={`order-pay-item${!hasBalancePay && hasCompanyPay ? " order-pay-item-single" : ""}${current===CompanyPayID?" active":""}`} onClick={() => setCurrent(CompanyPayID)}>
                    <CompanypayIcon className="pay-svg" />
                    <span>对公支付</span>
                </div> : null
            }
        </div>
        <div className="order-pay-list">
            {
                payTypeList.map(item => {
                    return <div
                        className={`order-pay-item${payTypeList.length < 2 ? " order-pay-item-single" : ""}${current===item.id?" active":""}`}
                        key={item.id}
                        onClick={() => setCurrent(item.id)}
                    >
                        {getPayIcon(item.id)}
                        <span>{getPayName(item.id)}</span>
                    </div>
                })
            }
        </div>
        <Button
            type="primary"
            className="order-submit"
            onClick={gotoPay}
        >确认购买</Button>
        { tips ? <div className="order-submit-tip">* {tips}</div> : null }
    </>
}

// 映射 Redux 全局的 state 到组件的 props 上
const mapStateToProps = (state) => ({
    paymentList: state.getIn(["app", "paymentList"]),
    balance: state.getIn(["user", "balance"]),
});

// 映射 dispatch 到 props 上
const mapDispatchToProps = (dispatch) => {
    return {
        userBalanceDispatch: () => dispatch(userBalance()),
    }
};

// 将 ui 组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PayTypes));
