import React, { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { deleteUserAddress } from "@/api/request";

function DeleteAddrModal(props) {
    const { open = false, data, onChangeOpen } = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open && !data) {
            hideModal();
        }
        // eslint-disable-next-line
    }, [open, data])

    const hideModal = () => {
        onChangeOpen(false);
    }

    const submit = () => {
        setLoading(true);
        deleteUserAddress({id: data.id}).then(res => {
            setLoading(false);
            if (res.code === 200) {
                message.success("删除成功");
                onChangeOpen(false, true);
            }
        }).catch(() => {
            setLoading(false);
        })
    }

    return <Modal
        title="删除收货信息"
        open={open}
        footer={null}
        width={560}
        maskClosable={false}
        closable={!loading}
        onCancel={hideModal}
        className="custom-modal-container ignore-modal-container"
    >
        <div className="ignore-modal-content">
            <div className="ignore-modal-main">
                <div className="ignore-modal-title">
                    <ExclamationCircleOutlined className="ignore-modal-icon" />
                    <span>收货信息 <span className="ignore-modal-name">{data ? `${data.name||''} ${data.phone||''} ${data.province||''}${data.city||''}${data.area||''}${data.detail||''}` : ""}</span> 将从列表删除，请确认是否删除！</span>
                </div>
            </div>
            <div className="ignore-modal-footer">
                <Button type="primary" danger loading={loading} onClick={submit}>确认删除</Button>
                <Button onClick={hideModal}>取消</Button>
            </div>
        </div>
    </Modal>
};

export default React.memo(DeleteAddrModal);