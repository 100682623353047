import styled from 'styled-components';

export const HeaderWrap = styled.div`
    width: 100%;
    min-height: 114px;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .icon-svg {
        flex-shrink: 0;
        width: 66px;
        height: auto;
        margin-right: 20px;
    }
    .title {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .order-title {
            width: 100%;
            font-size: 22px;
            font-family: "msyhbd";
            word-wrap: break-word;
            word-break: break-all;
        }
        .order-no {
            width: 100%;
            font-size: 14px;
            word-wrap: break-word;
            word-break: break-all;
            margin-top: 2px;
        }
    }
    @media (max-width: 768px) {
        min-height: 50px;
        padding: 0;
        .icon-svg {
            width: 48px;
            margin-right: 8px;
        }
        .title {
            .order-title {
                font-size: 16px;
            }
            .order-no {
                font-size: 12px;
                margin-top: 2px;
            }
        }
    }
`

export const MainWrap = styled.div`
    width: 100%;
    padding: 60px 0;
    box-sizing: border-box;
    border-top: 1px solid #E4E7ED;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon-svg {
        width: 88px;
        height: auto;
        fill: #aeb0b3;
    }
    .title {
        font-size: 24px;
        color: #303133;
        font-family: "msyhbd";
        line-height: 3;
    }
    .warm-tip {
        width: 80%;
        font-size: 14px;
        color: #606266;
        line-height: 1.5;
        text-align: center;
        word-wrap: break-word;
        word-break: break-word;
    }
    @media (max-width: 768px) {
        padding: 24px 0;
        border-top: none;
        .icon-svg {
            width: 46px;
        }
        .title {
            font-size: 18px;
        }
        .warm-tip {
            width: 100%;
            font-size: 12px;
        }
    }
`

export const CloseBtn = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
    .ant-btn {
        min-width: 150px;
    }
    @media (max-width: 768px) {
        margin: 30px 0;
        .ant-btn {
            height: 36px;
            font-size: 14px;
        }
    }
`
