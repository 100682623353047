import React, { useLayoutEffect, useState } from "react";

import { Provider } from "react-redux";
import store from "./store/index";
import { toggleDevice } from "@/store/modules/app/actionCreators";

import { BrowserRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import routes from "./routes/index";
import RouteWatcher from "./baseUI/RouteWatcher";

import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";

import "./App.less";

import '@/assets/quill/quill.core.css';
import '@/assets/quill/quill.snow.css';
import '@/assets/quill/quill.bubble.css';

import "./flags";

const FULLHD_WIDTH = 1408;
const MOBILE_WIDTH = 768;

function App() {
  const [componentSize, setComponentSize] = useState("default");

  useLayoutEffect(() => {
    function resizeHandler() {
      const rect = document.body.getBoundingClientRect();
      let device = "fullhd";
      let size = "default";
      if (rect.width - 1 < FULLHD_WIDTH) {
        device = "desktop";
        size = "default";
      }
      if (rect.width - 1 < MOBILE_WIDTH) {
        device = "mobile";
        size = "default";
      }
      store.dispatch(toggleDevice(device));
      setComponentSize(size);
    }
    window.addEventListener("resize", resizeHandler);
    resizeHandler();
    // ios禁止页面橡皮筋效果
    window.addEventListener("touchmove", {}, { passive: false });
    return () => {
      window.removeEventListener("resize", resizeHandler);
      window.removeEventListener("touchmove", {});
    }
    // eslint-disable-next-line
  }, [])

  return (
    <ConfigProvider locale={zhCN} componentSize={componentSize}>
      <Provider store={store}>
        <BrowserRouter>
          {renderRoutes(routes)}
          <RouteWatcher />
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
