import { Drawer, Menu } from "antd";
import React, { useState, useEffect } from "react";
import logoPic from "@/assets/png/logo.png";
import { ProductManualPath } from "@/utils/config";

const App = (props) => {
    const { history, pathname, visible, items } = props;
    const { onChange } = props;
    
    const [current, setCurrent] = useState("");
    const [openKeys, setOpenKeys] = useState([]);

    useEffect(() => {
        setCurrent(pathname);
        onChange(false);
        // eslint-disable-next-line
    }, [pathname])
    
    useEffect(() => {
        if (current) {
            const arr = current.split('/');
            if (arr.length > 2) {
                const currKey = `/${arr[1]}`;
                setOpenKeys((openKeys) => {
                    if (openKeys.indexOf(currKey) === -1) {
                        return [...openKeys, currKey];
                    } else {
                        return openKeys;
                    }
                })
            }
        }
    }, [current])

    const onOpenChange = (keys) => {
        setOpenKeys(keys);
    }

    const onClick = (e) => {
        if (e.key === "ProductManual") {
            // 产品使用手册
            window.open(ProductManualPath, "_blank");
        } else {
            setCurrent(e.key);
            history.push(e.key);
        }
    };

    const onClose = () => {
        onChange(false);
    }
    return (
        <>
            <Drawer
                title={<div className="right-drawer-title"><img src={logoPic} className="right-drawer-logo" alt="logo" /></div>}
                className="right-drawer-wrap"
                placement="left"
                closable={true}
                onClose={onClose}
                open={visible}
                width="100%"
            >
                <Menu
                    className="right-drawer-menu"
                    mode="inline"
                    items={items}
                    selectedKeys={[current]}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    onClick={onClick}
                />
            </Drawer>
        </>
    );
};
export default App;