import React from "react";
import { Button, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

function SimpleTipModal(props) {
    const {
        open = false,
        title = "提示",
        warningIcon = false,
        content = "确定要进行该操作吗？",
        description = "",
        okText = "确定",
        cancelable = false,
        cancelText = "取消",
        onChangeOpen
    } = props;

    const hideModal = () => {
        onChangeOpen(false);
    }

    const onOk = () => {
        onChangeOpen(false, true);
    }

    const onCancel = () => {
        onChangeOpen(false);
    }

    return <Modal
        title={title}
        open={open}
        footer={null}
        width={560}
        maskClosable={false}
        closable={false}
        onCancel={hideModal}
        className="custom-modal-container simpleTip-modal-container"
    >
        <div className="simpleTip-modal-content">
            { warningIcon ? <ExclamationCircleOutlined className="simpleTip-modal-icon" /> : null }
            <div className="simpleTip-modal-main">
                <div className="simpleTip-modal-tip">{content}</div>
                { description ? <div className="simpleTip-modal-desc">{description}</div> : null }
                <div className={`simpleTip-modal-footer${cancelable ? " hasCancelBtn" : ""}`}>
                    <Button type="primary" onClick={onOk}>{okText}</Button>
                    { cancelable ? <Button onClick={onCancel}>{cancelText}</Button> : null }
                </div>
            </div>
        </div>
    </Modal>
};

export default React.memo(SimpleTipModal);