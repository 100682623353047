// 重写renderRoutes，添加“路由守卫”
import React from "react";
import { Switch, Route, Redirect } from "react-router";
import store from "@/store/index";

function renderRoutes(routes, extraProps = {}, switchProps = {}) {
    return routes ? (
        <Switch {...switchProps}>
            {routes.map((route, i) => (
                <Route
                    key={route.key || i}
                    path={route.path}
                    exact={route.exact}
                    strict={route.strict}
                    render={props => {
                        const state = store.getState();
                        const token = state.getIn(["user", "token"]);
                        if (!route.auth || token) {
                            return route.render ? (
                                route.render({ ...props, ...extraProps, route: route })
                            ) : (
                                <route.component {...props} {...extraProps} route={route} />
                            )
                        } else {
                            return <Redirect to={{ pathname: "/login" }}/>;
                        }
                    }}
                />
            ))}
        </Switch>
    ) : null;
}

export default renderRoutes;