import * as actionTypes from './constants';
import { fromJS } from 'immutable';

const defaultState = fromJS({
    device: 'fullhd',
    paymentList: [],
    sdwanOptions: []
})

const reducer = ((state = defaultState, action) => {
    switch(action.type) {
        case actionTypes.SET_DEVICE:
            return state.set('device', action.data);
        case actionTypes.SET_PAYMENT_LIST:
            return state.set('paymentList', action.data);
        case actionTypes.SET_SDWAN_OPTIONS:
            return state.set('sdwanOptions', action.data);
        default:
            return state;
    }
})

export default reducer;