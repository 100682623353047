import React, { useState, useCallback } from "react";
import { Button, Modal } from "antd";
import { connect } from "react-redux";
import { userAddress } from "@/store/modules/user/actionCreators";
import AccountAddress from "@/components/AccountAddress";
import UpdateAddress from "@/components/AccountAddress/components/UpdateAddress";

function AccountAddressModal(props) {
    const { open = false, onChangeOpen } = props;

    const { device, userAddressDispatch } = props;

    const hideModal = () => {
        onChangeOpen(false, true);
    }

    /* 添加收货信息 -- start */
    const [addrOpen, setAddrOpen] = useState(false);
    const [addrData, setAddrData] = useState(null);
    const handleChangeAddrOpen = useCallback((open, refresh) => {
        setAddrOpen(open);
        if (refresh) {
            userAddressDispatch();
        }
        // eslint-disable-next-line
    }, [])
    const addAddr = () => {
        setAddrData(null);
        setAddrOpen(true);
    }
    /* 添加收货信息 -- end */

    return <Modal
        title="收货信息"
        open={open}
        footer={null}
        width={1060}
        maskClosable={false}
        onCancel={hideModal}
        className="custom-modal-container address-modal-container"
    >
        <div className="address-modal-content">
            { device !== "mobile" ? <div className="address-modal-btn1"><Button type="primary" onClick={addAddr}>添加信息</Button></div> : null }
            <AccountAddress />
            { device === "mobile" ? <div className="address-modal-btn2"><Button type="primary" onClick={addAddr}>添加信息</Button></div> : null }
        </div>
        <UpdateAddress open={addrOpen} data={addrData} onChangeOpen={handleChangeAddrOpen} />
    </Modal>
};
// 映射 Redux 全局的 state 到组件的 props 上
const mapStateToProps = (state) => ({
    device: state.getIn(["app", "device"]),
});

// 映射 dispatch 到 props 上
const mapDispatchToProps = (dispatch) => {
    return {
        userAddressDispatch: () => dispatch(userAddress()),
    }
};

// 将 ui 组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AccountAddressModal));