import React, { useEffect, useState, useCallback } from "react";
import renderRoutes from "@/routes/renderRoutes";
import { mainClientRoutes, BreadcrumbNameMap } from "@/routes/index";
import { Layout, Menu, Breadcrumb, Button, Dropdown, Modal, Divider, Tag, message } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, RightOutlined, DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useLocation, Link } from "react-router-dom";
import logoPic from "@/assets/png/logo.png";
import logoMPic from "@/assets/png/logo_m.png";
import { ReactComponent as Collapsed2Icon } from "@/assets/svg/header/collapsed2.svg";
import { ReactComponent as BalancepayIcon } from "@/assets/svg/pay/balancepay.svg";
import { ReactComponent as UserIcon } from "@/assets/svg/header/user.svg";
import CertificatePic from "@/assets/png/user/certificate.png";
import UserInfoPic from "@/assets/png/user/userInfo.png";
import OrderListPic from "@/assets/png/user/orderList.png";
import LogoutPic from "@/assets/png/user/logout.png";
import { connect } from "react-redux";
import { getPayment, resetDataByToken } from "@/store/modules/app/actionCreators";
import { userInfo, userBalance, userLogout } from "@/store/modules/user/actionCreators";
import { EnterpriseStatusOptions } from "@/utils/options";
import { ProductFAQPath, ProductManualPath } from "@/utils/config";
import DrawerPanel from "@/components/DrawerPanel";
import { ReactComponent as HelpIcon } from "@/assets/svg/menu/help.svg";

const { Header, Content, Sider } = Layout;

function Home(props) {
    const { route, history } = props;

    const { device } = props;
    const { token, username, balance, checkState } = props;
    const { getPaymentDispatch, resetDataByTokenDispatch, userInfoDispatch, userBalanceDispatch, userLogoutDispatch } = props;

    const [current, setCurrent] = useState("");
    const { pathname } = useLocation();
    const [openKeys, setOpenKeys] = useState([]);
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        if (device !== "fullhd" && !collapsed) {
            setCollapsed(true);
        }
        // eslint-disable-next-line
    }, [device])

    useEffect(() => {
        resetDataByTokenDispatch();
        // eslint-disable-next-line
    }, [token])

    // 面包屑
    const pathSnippets = pathname.split("/").filter((i) => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
        return (
            <Breadcrumb.Item key={url}>
                <Link to={url}>{ BreadcrumbNameMap ? BreadcrumbNameMap[url] : ""}</Link>
            </Breadcrumb.Item>
        )
    })
    const breadcrumbItems = [ ...extraBreadcrumbItems ];

    // 顶部-用户
    const [openDrop, setOpenDrop] = useState(false);
    const doLogout = () => {
        setOpenDrop(false);
        Modal.confirm({
            title: "是否确认退出登录？",
            icon: <ExclamationCircleOutlined />,
            okText: "立即退出",
            cancelText: "取消",
            onOk() {
                userLogoutDispatch().then(() => {
                    message.success("退出成功");
                    history.push("/login");
                }).catch(() => {
                })
            },
            onCancel() {
                message.warning("取消退出");
            },
        });
    }

    // 移动端-菜单
    const [drawerVisible, setDrawerVisible] = useState(false);
    const handleChangeDrawer = useCallback((val) => {
        setDrawerVisible(val);
    }, [])

    const routerItems = mainClientRoutes.map(item => {
        const menuItem = {
            label: item.meta.title,
            key: item.path,
            icon: item.meta.icon
        }
        if (item.routes && item.routes.length > 0) {
            const children = [];
            for (let i = 0; i < item.routes.length; i++) {
                let subRoute = item.routes[i];
                if (subRoute.meta && !subRoute.hidden) {
                    let subMenuItem = {
                        label: subRoute.meta.title,
                        key: subRoute.path,
                        danger: subRoute.meta.notOnline ? 1 : 0
                    }
                    if (subRoute.routes && subRoute.routes.length > 0) {
                        const subChildren = [];
                        for (let j = 0; j < subRoute.routes.length; j++) {
                            if (subRoute.routes[j].meta && !subRoute.routes[j].hidden) {
                                subChildren.push({
                                    label: subRoute.routes[j].meta.title,
                                    key: subRoute.routes[j].path,
                                    danger: subRoute.routes[j].meta.notOnline ? 1 : 0
                                })
                            }
                        }
                        if (subChildren.length > 0) {
                            subMenuItem.children = [].concat(subChildren);
                        }
                    }
                    children.push(subMenuItem);
                }
            }
            if (children.length > 0) {
                menuItem.children = [].concat(children);
            }
        }
        return { ...menuItem }
    })

    routerItems.push({
        label: "帮助文档",
        key: "Help",
        icon: <HelpIcon className="icon-svg" />,
        children: [{
            label: "常见问题",
            key: "ProductFAQ"
        }, {
            label: "使用手册",
            key: "ProductManual"
        }]
    })

    useEffect(() => {
        getPaymentDispatch();
        userInfoDispatch();
        userBalanceDispatch();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setCurrent(pathname);
        // 回到顶部
        const parentElem = document.querySelector(".antd-content-wrap");
        if (parentElem) {
            parentElem.scrollTo(0, 0);
        }
        // 隐藏下拉框
        setOpenDrop(false);
        // eslint-disable-next-line
    }, [pathname])

    useEffect(() => {
        if (current && !collapsed) {
            const arr = current.split("/");
            if (arr.length > 2) {
                const currKeys = [];
                currKeys.push(`/${arr[1]}`);
                if (arr.length > 3) {
                    currKeys.push(`/${arr[1]}/${arr[2]}`);
                }
                setOpenKeys((openKeys) => {
                    const addKeys = [];
                    for (let i = 0; i < currKeys.length; i++) {
                        if (openKeys.indexOf(currKeys[i]) === -1) {
                            addKeys.push(currKeys[i]);
                        }
                    }
                    if (addKeys.length > 0) {
                        return [...openKeys, ...addKeys];
                    } else {
                        return openKeys;
                    }
                })
            }
        } else {
            setOpenKeys([]);
        }
    }, [current, collapsed])

    const onOpenChange = (keys) => {
        setOpenKeys(keys);
    }

    const onClick = (e) => {
        if (e.key === "ProductFAQ") {
            // 常见问题
            window.open(ProductFAQPath, "_blank");
        } else if (e.key === "ProductManual") {
            // 产品使用手册
            window.open(ProductManualPath, "_blank");
        } else {
            setCurrent(e.key);
            history.push(e.key);
        }
    }

    return <>
        <Layout
            style={{
                minHeight: "auto",
                backgroundColor: "#fff",
            }}
        >
            {
                device !== 'mobile' ? <Sider
                    width="220px"
                    className={`antd-sider-wrap${collapsed ? " antd-sider-wrap-collapsed" : ""}`}
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                >
                    <div className="antd-sider-logo"><img src={collapsed ? logoMPic : logoPic} alt="logo" /></div>
                    <Menu
                        className="antd-sider-menu"
                        mode="inline"
                        items={routerItems}
                        selectedKeys={[current]}
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        onClick={onClick}
                    />
                </Sider> : null
            }
            <Layout>
                <Header className="antd-header-wrap">
                    {
                        device !== 'mobile' ? <div className="antd-header-left">
                            {
                                React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: "antd-header-collapsed",
                                    onClick: () => setCollapsed(!collapsed),
                                })
                            }
                            <Breadcrumb>{breadcrumbItems}</Breadcrumb>
                        </div> : <div className="antd-header-left">
                            <div className="antd-header-mbtn" onClick={() => setDrawerVisible(true)}>
                                <Collapsed2Icon className="icon-svg" />
                            </div>
                        </div>
                    }
                    <div className="antd-header-right">
                        <div className="antd-header-balance">
                            <BalancepayIcon className="antd-header-balance-icon" />
                            <span className="antd-header-balance-label">账户余额：</span>
                            <span className="antd-header-balance-value">{balance}</span>
                            <Button type="primary" className="antd-header-balance-btn" onClick={() => history.push("/recharge")}>充值</Button>
                        </div>
                        <div className="antd-header-user">
                            <Dropdown
                                trigger="hover"
                                placement="bottom"
                                open={openDrop}
                                onOpenChange={(open) => setOpenDrop(open)}
                                dropdownRender={() => (
                                    <div className="drop-user-content">
                                        <div className="drop-user-title">欢迎~</div>
                                        <div className="drop-user-name">{username}</div>
                                        <Divider />
                                        <div className="drop-user-item">
                                            <img src={CertificatePic} alt="icon" className="drop-user-item-icon" />
                                            <div className="drop-user-item-name">
                                                <span>企业认证</span>
                                                { EnterpriseStatusOptions[checkState] ? <Tag className="drop-user-item-tag account-state-tag" color={EnterpriseStatusOptions[checkState].color}>{EnterpriseStatusOptions[checkState].label}</Tag> : null }
                                            </div>
                                        </div>
                                        <div className="drop-user-item" onClick={() => history.push("/account")}>
                                            <img src={UserInfoPic} alt="icon" className="drop-user-item-icon" />
                                            <div className="drop-user-item-name">账户信息</div>
                                            <RightOutlined className="drop-user-item-right" />
                                        </div>
                                        <div className="drop-user-item" onClick={() => history.push("/order")}>
                                            <img src={OrderListPic} alt="icon" className="drop-user-item-icon" />
                                            <div className="drop-user-item-name">订单列表</div>
                                            <RightOutlined className="drop-user-item-right" />
                                        </div>
                                        <Divider />
                                        <div className="drop-user-item" onClick={doLogout}>
                                            <img src={LogoutPic} alt="icon" className="drop-user-item-icon" />
                                            <div className="drop-user-item-name">退出系统</div>
                                            <RightOutlined className="drop-user-item-right" />
                                        </div>
                                    </div>
                                )}
                            >
                                {
                                    device !== "mobile" ? <div className="antd-header-user-btn">
                                        <UserIcon className="antd-header-user-icon" />
                                        <div className="antd-header-user-name">{username}</div>
                                        <DownOutlined className="antd-header-user-dIcon" />
                                    </div> : <div className="antd-header-user2-btn">
                                        <UserIcon className="antd-header-user2-icon" />
                                    </div>
                                }
                            </Dropdown>
                        </div>
                    </div>
                </Header>
                <Content className="antd-content-wrap">
                    {renderRoutes(route.routes)}
                </Content>
            </Layout>
        </Layout>
        <DrawerPanel
            history={history}
            pathname={pathname}
            visible={drawerVisible}
            items={routerItems}
            onChange={handleChangeDrawer}
        />
    </>
}

// 映射 Redux 全局的 state 到组件的 props 上
const mapStateToProps = (state) => ({
    device: state.getIn(["app", "device"]),
    token: state.getIn(["user", "token"]),
    username: state.getIn(["user", "username"]),
    balance: state.getIn(["user", "balance"]),
    checkState: state.getIn(["user", "checkState"]),
});

// 映射 dispatch 到 props 上
const mapDispatchToProps = (dispatch) => {
    return {
        getPaymentDispatch: () => dispatch(getPayment()),
        resetDataByTokenDispatch: () => dispatch(resetDataByToken()),
        userInfoDispatch: () => dispatch(userInfo()),
        userBalanceDispatch: () => dispatch(userBalance()),
        userLogoutDispatch: () => dispatch(userLogout()),
    }
};

// 将 ui 组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Home));
