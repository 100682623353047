import * as actionTypes from './constants';
import { fromJS } from 'immutable';
import { getPaymentList } from "@/api/request";
import { getSdwanOptions } from "@/api/sdwan";

const changeData = (type, data) => ({
    type: type,
    data: fromJS(data)
})

export const toggleDevice = (device) => {
    return dispatch => {
        dispatch(changeData(actionTypes.SET_DEVICE, device))
    }
}

export const getPayment = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            getPaymentList().then(response => {
                if (response.code === 200) {
                    dispatch(changeData(actionTypes.SET_PAYMENT_LIST, response.data || []));
                    
                    resolve(response.data);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    }
}

export const getSdwanData = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            getSdwanOptions().then(response => {
                if (response.code === 200) {
                    dispatch(changeData(actionTypes.SET_SDWAN_OPTIONS, response.data || []));
                    
                    resolve(response.data);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    }
}

export const resetDataByToken = () => {
    return dispatch => {
        dispatch(changeData(actionTypes.SET_SDWAN_OPTIONS, []))
    }
}
