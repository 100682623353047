import request from "./config";

// 获取线路套餐列表
export function getSdwanItemList(params) {
    return request({
        url: "/front/sdwan/itemList",
        method: "get",
        params
    })
}
// 计算线路购买订单价格
export function getSdwanComputePrice(data) {
    return request({
        url: "/front/sdwan/computePrice",
        method: "post",
        data
    })
}
// 购买线路
export function sdwanPayBuy(data) {
    return request({
        url: "/front/sdwan/createOrder",
        method: "post",
        data
    })
}
// 获取线路列表
export function getSdwanList(data) {
    return request({
        url: "/front/sdwan/pageList",
        method: "post",
        data
    })
}
// 获取线路下拉列表
export function getSdwanOptions(data) {
    return request({
        url: "/front/sdwan/select/list",
        method: "post",
        data
    })
}
// 修改线路名称
export function updateSdwanRemark(data) {
    return request({
        url: "/front/sdwan/remark",
        method: "post",
        data
    })
}
// 开启/关闭 自动续费
export function updateSdwanAutoRenewal(data) {
    return request({
        url: "/front/sdwan/autoRenewal",
        method: "post",
        data
    })
}
// 开启/关闭 专线
export function updateSdwanSpecialLine(data) {
    return request({
        url: "/front/sdwan/activate_pure",
        method: "post",
        data
    })
}
// 切换 黑白名单
export function updateSdwanModel(data) {
    return request({
        url: "/front/sdwan/switchModel",
        method: "post",
        data
    })
}
// 激活线路
export function sdwanActivate(data) {
    return request({
        url: "/front/sdwan/activate",
        method: "post",
        timeout: 1800000, // 30分钟
        data
    })
}
// 获取线路详情
export function getSdwanDetail(params) {
    return request({
        url: "/front/sdwan/sdwanDetail",
        method: "get",
        params
    })
}
// 获取带宽升级套餐列表
export function getSdwanUpgradeItemList(params) {
    return request({
        url: "/front/sdwan/upgradeList",
        method: "get",
        params
    })
}
// 计算带宽升级价格
export function getSdwanComputeUpgradePrice(data) {
    return request({
        url: "/front/sdwan/computeUpgradePrice",
        method: "post",
        data
    })
}
// 带宽升级
export function sdwanPayUpgrade(data) {
    return request({
        url: "/front/sdwan/bandwidthUpgrade",
        method: "post",
        data
    })
}
// 获取线路续费套餐列表
export function getSdwanRenewItemList(params) {
    return request({
        url: "/front/sdwan/renewList",
        method: "get",
        params
    })
}
// 计算线路续费价格
export function getSdwanComputeRenewPrice(data) {
    return request({
        url: "/front/sdwan/computeRenewPrice",
        method: "post",
        data
    })
}
// 线路续费
export function sdwanPayRenew(data) {
    return request({
        url: "/front/sdwan/sdwanRenew",
        method: "post",
        data
    })
}
// 获取盒子列表
export function getBoxList(data) {
    return request({
        url: "/front/box/pageList",
        method: "post",
        data
    })
}
// 修改盒子备注
export function updateBoxRemark(data) {
    return request({
        url: "/front/box/remark",
        method: "post",
        data
    })
}
// 获取盒子详情（包括wifi配置）
export function getBoxDetail(params) {
    return request({
        url: "/front/box/detail",
        method: "get",
        params
    })
}
// 盒子检查新版本
export function checkBoxVersion(params) {
    return request({
        url: "/front/box/checkVersion",
        method: "get",
        params
    })
}
// 更新盒子版本
export function updateBoxVersion(params) {
    return request({
        url: "/front/box/updateVersion",
        method: "get",
        params
    })
}
// wifi配置下拉列表
export function getWirelesOptions(params) {
    return request({
        url: "/front/box/getConfigList",
        method: "get",
        params
    })
}
// 修改wifi配置
export function updateWireless(data) {
    return request({
        url: "/front/box/modifyWireless",
        method: "post",
        data
    })
}
