import { createBrowserHistory } from "history";
import Cookies from "js-cookie";
import store from "@/store/index";
import { resetToken } from "@/store/modules/user/actionCreators";

var TokenKey = "xlep";

export function getToken() {
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token);
}

export function removeToken() {
    return Cookies.remove(TokenKey);
}

export function gotoLogin() {
    // 清除token
    store.dispatch(resetToken());
    // 跳转到登录页
    const history = createBrowserHistory({ forceRefresh: true });
    const { pathname } = history.location;
    if (pathname !== "/login") {
        history.push(`/login`);
    }
}
